import React, { useState } from 'react';
import { func, shape, bool } from 'prop-types';
import { PLTextField } from '../';

const getPlaceholder = (switchToDateFormat, isForDecline) => {
  const placeHolder = isForDecline && !switchToDateFormat ? 'Date of Birth' : 'MM/DD/YYYY';
  return placeHolder;
};

const PLDOBInput = ({ onBlur, onChange, validationErrors, isForDecline, ...extraProps }) => {
  const [dobPlaceholder, setDobPlaceholder] = useState(getPlaceholder(false, isForDecline));

  return (
    <PLTextField
      name="dob"
      type="tel"
      component="Date"
      label={isForDecline ? '' : 'Date of Birth'}
      inputProps={{
        // Ensure that aria-label is always present if no label
        'aria-label': extraProps.label || 'Date of Birth',
      }}
      onBlur={ev => {
        setDobPlaceholder(getPlaceholder(false, isForDecline));
        onBlur(ev);
      }}
      onChange={onChange}
      onFocus={() => {
        setDobPlaceholder(getPlaceholder(true, isForDecline));
      }}
      placeholder={dobPlaceholder}
      errors={validationErrors.monthBirth}
      autoComplete="bday-month"
      {...extraProps}
    />
  );
};

PLDOBInput.propTypes = {
  onBlur: func.isRequired,
  onChange: func.isRequired,
  validationErrors: shape().isRequired,
  isForDecline: bool,
};

PLDOBInput.defaultProps = {
  isForDecline: false,
};

export default PLDOBInput;

