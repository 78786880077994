import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const EasyLoanCalloutItem = ({ icon, iconAltText, stepTitle, stepText }) => (
  <div className="EasyLoanCalloutItem">
    <div className="EasyLoanCalloutItem-image">
      <img src={icon} alt={iconAltText} />
    </div>
    <div className="EasyLoanCalloutItem-title u-colorDenim500 u-title2Text">{stepTitle}</div>
    <div className="EasyLoanCalloutItem-text u-colorDenim500 u-bodyText">{stepText}</div>
  </div>
);

EasyLoanCalloutItem.propTypes = {
  icon: PropTypes.string.isRequired,
  iconAltText: PropTypes.string.isRequired,
  stepTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  stepText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default EasyLoanCalloutItem;

