import React from 'react';

import './WithWhiteBackground.scss';

const ROOT_CLASS = 'WhiteTooltip';

const WithWhiteBackground =
  TooltipComponent =>
  ({ children, className, overlayClassName, ...props }) => {
    return (
      <TooltipComponent
        className={`${ROOT_CLASS} ${className}`}
        overlayClassName={`${ROOT_CLASS}-overlay ${overlayClassName}`}
        {...props}
      >
        {children}
      </TooltipComponent>
    );
  };

export default WithWhiteBackground;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/common-tooltips/CommonTooltips.scss');