import { useEffect } from 'react';

/**
 * usePlusTargetArrow
 * @param {object} props  props object
 * @param {object} props.target tooltip element ref
 * @param {boolean} props.tooltipComponent flag to indicates if the tooltip is hidden or visible
 * @param {string=} props.tooltipOverlayClass tooltip overlay class name to update only target tooltip arrow
 *
 * @example
 * usePlusTargetArrow({ target: someRef, tooltipComponent: showTooltipFlag });
 */
const usePlusTargetArrow = ({ target, tooltipComponent, tooltipOverlayClass }) => {
  let timeOutFunctionResize;
  const updateArrowPosition = () => {
    clearTimeout(timeOutFunctionResize);
    timeOutFunctionResize = setTimeout(() => {
      const tooltipContainerElem = tooltipOverlayClass ? document.querySelector(`.${tooltipOverlayClass}`) : document;
      if (tooltipContainerElem) {
        const arrow = tooltipContainerElem.querySelector('.rc-tooltip-arrow');
        const tooltip = tooltipContainerElem.querySelector('.rc-tooltip-content');
        const { left: tooltipX } = tooltip.getBoundingClientRect();
        const { left: targetX } = target.current.getBoundingClientRect();
        arrow.style.left = `${targetX + target.current.offsetWidth / 2 - tooltipX}px`;
      }
    }, 300);
  };
  useEffect(() => {
    if (tooltipComponent) {
      updateArrowPosition();
      window.addEventListener('resize', updateArrowPosition);
    }

    return () => tooltipComponent && window.removeEventListener('resize', updateArrowPosition);
  }, [tooltipComponent]);

  return { updateArrowPosition };
};

export default usePlusTargetArrow;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/common-tooltips/CommonTooltips.scss');