import React, { useContext } from 'react';
import { func, string, bool, node, shape } from 'prop-types';
import classNames from 'classnames';
import { FormControlLabel, Checkbox as PLCheckbox } from 'propulsion-rc-v2';
import { PageIdContext } from '../PageIdProvider/PageIdProvider';

const Checkbox = ({
  name,
  checked,
  labelClass,
  className,
  disabled,
  onChange,
  children,
  inputRef,
  inputProps,
  pageId = useContext(PageIdContext),
  ...extraProps
}) => {
  const id = pageId ? `${pageId}-${name}` : name;

  const extendInputProps = {
    'data-id': id,
    'data-analytics': id,
    'data-qa-id': id,
    'data-nid-target': id,
    ...inputProps,
  };

  return (
    <div className="Checkbox">
      <FormControlLabel
        className={classNames('Checkbox-label', labelClass)}
        data-qa-id={`${id}-label`}
        control={
          <PLCheckbox
            className={className}
            id={name}
            name={name}
            inputProps={extendInputProps}
            ref={inputRef}
            checked={checked}
            disabled={disabled}
            onMouseDown={e => e.preventDefault()}
            onChange={e => {
              e.target.focus();
              onChange(e);
            }}
            {...extraProps}
          />
        }
        label={children}
      />
    </div>
  );
};

Checkbox.propTypes = {
  name: string.isRequired,
  value: string,
  className: string,
  labelClass: string,
  checked: bool,
  disabled: bool,
  onChange: func.isRequired,
  children: node.isRequired,
  inputRef: shape({}),
  pageId: string,
  inputProps: shape({}),
};

Checkbox.defaultProps = {
  labelClass: '',
  className: '',
  checked: false,
  disabled: false,
  value: '',
  inputRef: undefined,
  pageId: undefined,
  inputProps: {},
};

export default Checkbox;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');