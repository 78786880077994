import React, { useContext } from 'react';
import { string, shape, node, oneOfType } from 'prop-types';
import { RadioButton as PropulsionRadioButton, FormControlLabel } from 'propulsion-rc-v2';
import { PageIdContext } from '../PageIdProvider';

const RadioButton = ({
  pageId = useContext(PageIdContext),
  inputProps,
  labelProps,
  value,
  label,
  name: propName,
  ...extraProps
}) => {
  const valueKey = value.toString().toLowerCase().replace('_', '-');
  const name = propName || valueKey;
  const id = pageId ? `${pageId}-${name}` : valueKey;
  const idLabel = `${id}-label`;
  const extendedInputProps = {
    'data-id': id,
    'data-analytics': id,
    'data-qa-id': id,
    'data-nid-target': id,
    ...inputProps,
  };

  return (
    <FormControlLabel
      id={idLabel}
      name={idLabel}
      control={<PropulsionRadioButton id={id} name={name} inputProps={extendedInputProps} {...extraProps} />}
      value={value}
      label={label}
      {...labelProps}
    />
  );
};

RadioButton.propTypes = {
  ...RadioButton.propTypes,
  inputProps: shape({}),
  labelProps: shape({}),
  name: string,
  value: node.isRequired,
  label: oneOfType([string, node]).isRequired,
  pageId: string,
};

RadioButton.defaultProps = {
  pageId: undefined,
  inputProps: {},
  labelProps: {},
  name: undefined,
};

export default RadioButton;

