import { cloneElement } from 'react';

const TooltipContent = ({ children, ...props }) =>
  cloneElement(children, {
    ...props,
    role: 'button',
    tabIndex: 0,
    onKeyPress: e => {
      if (e.charCode === 13 || e.charCode === 32) {
        e.preventDefault();
        e.target.click();
      }
    },
  });

export default TooltipContent;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/common-tooltips/CommonTooltips.scss');