import { useRef, useEffect } from 'react';

const useScrollToElement = () => {
  const scrollToElem = useRef(null);

  useEffect(() => {
    if ('scrollRestoration' in history) {
      try {
        history.scrollRestoration = 'manual';
      } catch (e) {
        /* do nothing */
      }
    }
    // scroll and hide header page in mobile
    if (window.innerWidth <= 767 && scrollToElem.current) scrollToElem.current.scrollIntoView();
  }, []);

  return scrollToElem;
};

export default useScrollToElement;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');