import React from 'react';
import classNames from 'classnames';
import { Card as PropulsionCard } from 'propulsion-rc-v2';
import { bool, node, number, string } from 'prop-types';
import './Card.scss';

const Card = ({ className, rounded, circled, elevation, children }) => {
  return (
    <PropulsionCard
      className={classNames(className, {
        'u-rounded': rounded,
        'u-circled': circled,
        [`u-elevation-${elevation}`]: elevation,
      })}
    >
      {children}
    </PropulsionCard>
  );
};

Card.propTypes = {
  className: string,
  rounded: bool,
  circled: bool,
  elevation: number,
  children: node,
};

Card.defaultProps = {
  pageId: undefined,
};

export default Card;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');