import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './index.scss';

// displays a logo on the left side of the screen
const Header = ({ logoImgSrc, logoImgUrl, logoAltText, classes }) => (
  <header className={classnames('Header', classes)}>
    <div className="Header-content">
      <nav className="Header-logo">
        <a href={logoImgUrl}>
          <img src={logoImgSrc} alt={logoAltText} />
        </a>
      </nav>
    </div>
  </header>
);

Header.propTypes = {
  logoImgSrc: PropTypes.string.isRequired,
  logoAltText: PropTypes.string.isRequired,
  logoImgUrl: PropTypes.string,
  classes: PropTypes.string,
};

Header.defaultProps = {
  logoImgUrl: '/',
};

export default Header;

