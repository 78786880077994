// static version the the review widget
// this will be replaced later with a version that
// pulls live data

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';
import baazarvoiceTrustmark from './images/baazarvoiceTrustmark.png';
import starBar from './images/stars-4.8.svg';
import { BA_URL } from '../../constants/userResourceUrls';

import './index.scss';

const BAReviewWidget = ({ classes, haveSup }) => (
  <div
    className={classnames('BaReviewWidget u-textAlignCenter', classes)}
    onClick={() => heapTrackEvent('PL DM - click baazar voice link')}
  >
    <a className="u-textDecorationNone" href={BA_URL} target="_blank" rel="noopener noreferrer">
      <div>
        <img className="BaReviewWidget-logo" src={baazarvoiceTrustmark} alt="baazarvoice-trustmark" />
      </div>
      <div>
        <img className="BaReviewWidget-rating" src={starBar} alt="ratings-bar" />
      </div>
      <div className="BaReviewWidget-reviews u-microText u-fontTextBold u-colorDenim500">
        64,592 Reviews {haveSup && <sup className="u-fontTextRegular">3</sup>}
      </div>
    </a>
  </div>
);

BAReviewWidget.propTypes = {
  classes: PropTypes.string,
  haveSup: PropTypes.bool,
};

BAReviewWidget.defaultProps = {
  haveSup: false,
};

export default BAReviewWidget;

