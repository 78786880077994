import { isAlphaNumeric as isAlphaNumericValue } from './validators';
import { VALID_HOME_STATUS_EXPENSE, VALID_LIVING_STATUS } from '../../../../shared_modules/baui-constants';
import validator from 'ui-validator';

// export const validator = require('ui-validator');

export const validators = validator.validators;

const v = validator.validators;

export const skipIfMissing = validator.createValidator((options, value, key, attributes) => {
  if (value === undefined) return;
  return options.validateFn(value, key, attributes);
});

export const isAlphaNumeric = validator.createValidator(({ message }, value) => {
  if (!isAlphaNumericValue(value)) return message;
});

export const isTruthy = validator.createValidator(({ message }, value) => {
  if (value === undefined) return;
  if (value !== true) {
    return message;
  }
});

export const moreThanZero = validator.createValidator(({ message }, value, key, attributes) => {
  if (value === undefined || value === '') return;

  if (Number(value) <= 0) return message;
});

export function isJoint(attributes) {
  return attributes.hasOwnProperty('loanType') && attributes.loanType === 'joint';
}

export const validateExpenseField = (value, homeStatusValue, showRentField) => {
  if (showRentField && VALID_HOME_STATUS_EXPENSE.includes(homeStatusValue)) {
    if (!value) return v.required({ message: 'An amount is required.' })(value, homeStatusValue, showRentField);
    return v.dollarAmountRange({
      min: 0,
      max: 99999,
      overMaxMessage: 'The amount you have entered is above the maximum allowed.',
    })(value, homeStatusValue, showRentField);
  }
};

export const requiredExpense = (value, key, attrs) => {
  const { homeStatus, homeStatusSecondary, showRentField } = attrs;
  let homeStatusField = homeStatus;
  if (isJoint(attrs)) {
    homeStatusField = homeStatusSecondary ? homeStatusSecondary : homeStatus;
  }
  if (attrs.hasOwnProperty(key)) {
    return validateExpenseField(value, homeStatusField, showRentField);
  }
};

export const homeStatusRequired = (value, key, attrs) => {
  value = VALID_LIVING_STATUS[value];
  if (attrs.hasOwnProperty(key)) {
    return v.required({ message: 'A living situation is required' })(value, key, attrs);
  }
};

export default {
  isAlphaNumeric,
  skipIfMissing,
  isTruthy,
  validators,
  requiredExpense,
  homeStatusRequired,
};

