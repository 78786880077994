export default [
  {
    'enum-name': 'credit_card',
    label: 'Credit card refinancing',
    'loan-type-text': 'credit card refinance',
    active: true,
  },
  {
    'enum-name': 'debt_consolidation',
    label: 'Debt consolidation',
    'loan-type-text': 'debt consolidation',
    active: true,
  },
  {
    'enum-name': 'home_improvement',
    label: 'Home improvement',
    'loan-type-text': 'home improvement',
    active: true,
  },
  {
    'enum-name': 'major_purchase',
    label: 'Major purchase',
    'loan-type-text': 'major purchase',
    active: true,
  },
  {
    'enum-name': 'house',
    label: 'Home buying',
    'loan-type-text': 'home down payment',
    active: true,
  },
  {
    'enum-name': 'car',
    label: 'Car financing',
    'loan-type-text': 'car financing',
    active: true,
  },
  {
    'enum-name': 'renewable_energy',
    label: 'Green loan',
    'loan-type-text': 'green',
    active: true,
  },
  {
    'enum-name': 'small_business',
    label: 'Business',
    'loan-type-text': 'business',
    active: true,
  },
  {
    'enum-name': 'vacation',
    label: 'Vacation',
    'loan-type-text': 'vacation',
    active: true,
  },
  {
    'enum-name': 'moving',
    label: 'Moving and relocation',
    'loan-type-text': 'moving',
    active: true,
  },
  {
    'enum-name': 'medical',
    label: 'Medical expenses',
    'loan-type-text': 'medical expenses',
    active: true,
  },
  {
    'enum-name': 'educational',
    label: 'Educational',
    'loan-type-text': 'educational',
  },
  {
    'enum-name': 'wedding',
    label: 'Wedding',
    'loan-type-text': 'wedding',
  },
  {
    'enum-name': 'life_event',
    label: 'Life Event',
    'loan-type-text': 'Life Event',
  },
  {
    'enum-name': 'other',
    label: 'Other',
    'loan-type-text': 'personal',
    active: true,
  },
];

