import React from 'react';
import { oneOf, string } from 'prop-types';
import InformationBox from '../../../local-propulsion/components/InformationBox';
import { StringTemplate } from '../../../baui-utils/client/utils';

const NOTICE_TEXT = {
  title: {
    default: 'Obtaining Co-Borrower Consent',
    variant: 'Co-Borrower Consent email',
  },
  content: {
    default:
      'After you apply, an email will be sent to your co-borrower. They will need to verify their identity and review & sign loan documents via email before the loan can be issued. Both borrowers will be equally responsible for the loan.',
    variant:
      '{{coborrowerName}} will receive an email with instructions to verify their identity and review & sign loan documents. {{coborrowerName2}} will need to complete the emailed instructions before the loan can be issued. Both borrowers will be equally responsible for the loan.',
  },
};

const NOTICE_TEMPLATES = {
  // move to constant?
  default: {
    title: NOTICE_TEXT.title.default,
    content: NOTICE_TEXT.content.default,
  },
  consentEmail: {
    title: NOTICE_TEXT.title.variant,
    content: NOTICE_TEXT.content.variant,
  },
  consentVariant: {
    title: NOTICE_TEXT.title.default,
    content: NOTICE_TEXT.content.variant,
  },
};
/**
 * CoborrowerConsent component to display co-borrower information in a card.
 *
 * @component
 * @param {Object} props
 * @param {String=} props.title card title
 * @param {String=} props.content card content text
 * @param {String=} props.firstNameSecondary coborrower first name
 * @param {String=} props.variant coborrower consent variant
 * @param {String=} props.className custom class
 * @return  {JSX.Element}     React component
 * @example
 * return (<CoborrowerConsent />);
 * @example
 * return (<CoborrowerConsent variant="consentEmail"/>);
 * @example
 * return (<CoborrowerConsent title="custom title" content="custom content"/>);
 */
const CoborrowerConsent = ({ title, content, firstNameSecondary, variant, className }) => {
  if (variant) {
    const templateKeyToValReplacement = {
      [variant]: {
        title: {},
        content: {
          coborrowerName: firstNameSecondary || 'Your co-borrower',
          coborrowerName2: firstNameSecondary || 'They',
        },
      },
    };
    const selectedTemplate = NOTICE_TEMPLATES[variant];

    if (selectedTemplate) {
      const values = templateKeyToValReplacement[variant] || {};
      title = StringTemplate.replace(selectedTemplate.title, values.title); // no replacement, but for future proofing
      content = StringTemplate.replace(selectedTemplate.content, values.content);
    }
  }

  return (
    <InformationBox title={title} className={className}>
      <p>{content}</p>
    </InformationBox>
  );
};

CoborrowerConsent.propTypes = {
  title: string,
  content: string,
  firstNameSecondary: string,
  variant: oneOf(['consentEmail', 'consentVariant']),
  className: string,
};

CoborrowerConsent.defaultProps = {
  title: NOTICE_TEMPLATES.default.title,
  content: NOTICE_TEMPLATES.default.content,
  firstNameSecondary: null,
  variant: undefined,
  className: '',
};

export default CoborrowerConsent;

