import React from 'react';
import { shape, bool, string } from 'prop-types';

import './LegalTextCopy.scss';
// https://jsdoc.app/tags-property.html
/**
 * LegalText
 * Component to build all Legal Text variants in Funnel
 * consumed by:
 *  - PI1 Loan-Type
 *  - Join App Pending Status
 * @component
 * @version 1.0.0
 *
 * @param {Object} props
 * @param {string=} props.WrapperTag either li or p tested (default p)
 * @param {boolean=} props.showImportantInfoTitle shows important info disclaimer
 * @param {Object} props.showEditingDisclaimer to switch editing disclaimer at the end of the copy
 * @param {Object} props.jointItem is used by joint app redesign experiment
 * @param {boolean=} props.jointItem.show show joint app redesign experiment information bullet
 * @param {boolean=} props.jointItem.showAddonJointDisclaimer show joint app information when joint app is selected.
 * @param {string=} props.jointItem.classNames custom classnames for jointItem bullet
 *
 * @example
 * return (
 *   <LegalTextCopy
 *     WrapperTag="li"
 *     showImportantInfoTitle={false}
 *     jointItem={{
 *       show: true,
 *       classNames: 'LegalText-text--custom-class',
 *       showAddonJointDisclaimer: true,
 *     }}
 *    >
 * );
 *
 * @example
 * return (
 *   <LegalTextCopy
 *     showImportantInfoTitle
 *     jointItem={{
 *       show: false
 *     }}
 *    >
 * );
 */
const LegalTextCopy = ({ WrapperTag, showImportantInfoTitle, showEditingDisclaimer, jointItem }) => {
  const IMPORTANT_INFO_TITLE = (
    <div className="LegalTextCopy-title">
      <p className="LegalText-text--bold">
        <strong>IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT</strong>
      </p>
    </div>
  );
  const EDITING_DISCLAIMER = (
    <WrapperTag>
      *Editing your application type, loan amount, or loan purpose could change your approval status.
    </WrapperTag>
  );

  return (
    <>
      {showImportantInfoTitle && IMPORTANT_INFO_TITLE}
      {jointItem.showAddonJointDisclaimer && (
        <WrapperTag className={jointItem.classNames}>
          By choosing a joint application, the two of you agree that you are applying for credit together and will be
          equally responsible for the loan.
        </WrapperTag>
      )}
      <WrapperTag>
        To help the government fight the funding of terrorism and money laundering activities, Federal law requires all
        financial institutions to obtain, verify, and record information that identifies each person who opens an
        account.
      </WrapperTag>
      <WrapperTag>
        What this means for you: When you open an account, we will ask for your name, address, date of birth, and other
        information that will allow us to identify you. We may also ask to see your driver's license or other
        identifying documents.
      </WrapperTag>
      <WrapperTag>
        Your loan APR will depend upon your credit score and other key financing characteristics, including but not
        limited to the amount financed, loan term length, and your credit usage and history.
      </WrapperTag>
      <WrapperTag className="LegalText-text--bold">
        All legal documents and disclosures must be signed by each applicant individually.
      </WrapperTag>
      {showEditingDisclaimer && EDITING_DISCLAIMER}
    </>
  );
};

LegalTextCopy.propTypes = {
  WrapperTag: string,
  showImportantInfoTitle: bool,
  showEditingDisclaimer: bool,
  jointItem: shape({
    show: bool,
    showAddonJointDisclaimer: bool,
    classNames: string,
  }),
};

LegalTextCopy.defaultProps = {
  showImportantInfoTitle: false,
  WrapperTag: 'p',
  showEditingDisclaimer: false,
  jointItem: {
    show: false,
    showAddonJointDisclaimer: false,
    classNames: '',
  },
};

export default LegalTextCopy;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');