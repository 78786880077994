import React from 'react';
import { func, shape, string, bool } from 'prop-types';
import { InputAdornment } from 'propulsion-rc-v2';
import { TooltipWrapper } from '@shared_modules/common-tooltips/CommonTooltips';
import { PLTextField } from '..';
import './Last4SSNInput.scss';

const Tooltip = ({ children, tooltipProps }) => (
  <TooltipWrapper {...tooltipProps} placement="topRight">
    <div className="rc-text-input__tooltip__text">{children}</div>
  </TooltipWrapper>
);

Tooltip.propTypes = {
  tooltipProps: shape({}).isRequired,
  children: string.isRequired,
};

const Last4SSNInput = ({
  autoFocus,
  label,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  validationErrors,
  hideLabels,
  tooltipLauncher,
  tooltipLauncherText,
  adornmentEnabled,
  ...extraProps
}) => {
  const placeholder = hideLabels ? extraProps.placeholder || label : '';

  return (
    <PLTextField
      className="Last4SSNInput"
      type="tel"
      name="last4ssn"
      autoFocus={autoFocus}
      inputProps={{
        maxLength: 4,
        // Ensure that aria-label is always present if no label
        'aria-label': !label || hideLabels ? label || 'Last 4 Digits of your SSN' : undefined,
      }}
      autoComplete="off"
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      errors={validationErrors.last4ssn}
      label={hideLabels ? '' : label}
      placeholder={adornmentEnabled ? '####' : placeholder}
      componentProps={{
        startAdornment: adornmentEnabled && (
          <InputAdornment disablePointerEvents position="start">
            XXX - XX -
          </InputAdornment>
        ),
        endAdornment: tooltipLauncher ? (
          <InputAdornment disablePointerEvents={false} position="end">
            <Tooltip
              tooltipProps={{
                overlay: (
                  <span>
                    This helps us verify and
                    <br />
                    protect your identity.
                  </span>
                ),
                name: 'verify-why',
                overlayClassName: 'verify-user__tooltip-overlay',
                placement: 'bottomRight',
              }}
            >
              {tooltipLauncherText}
            </Tooltip>
          </InputAdornment>
        ) : null,
      }}
      {...extraProps}
    />
  );
};

Last4SSNInput.propTypes = {
  autoFocus: bool,
  label: string,
  hideLabels: bool,
  tooltipLauncher: bool,
  tooltipLauncherText: string,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  onKeyDown: func,
  onFocus: func,
  validationErrors: shape({}).isRequired,
  adornmentEnabled: bool,
};

Last4SSNInput.defaultProps = {
  autoFocus: true,
  hideLabels: false,
  label: 'Last 4 Digits of SSN',
  onFocus: () => {},
  onKeyDown: () => {},
  tooltipLauncher: true,
  tooltipLauncherText: 'Why?',
  adornmentEnabled: false,
};

export default Last4SSNInput;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');