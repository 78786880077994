import React from 'react';
import { bool, node, string } from 'prop-types';
import { Grid } from 'propulsion-rc-v2';
import { Note, WithBoldText } from '@shared_modules/baui-ui/components';
import classNames from 'classnames';

const NoteWithBoldText = WithBoldText(Note);
/**
 * InvestorNotice Component.
 *
 * @component
 *
 * @param {Object}     props
 * @param {boolean=}   props.show - display Investor Message Notice
 * @param {String=}    props.className - custom class name
 * @param {String=}    props.containerClassName - container wrapper class name
 * @param {JSX.Element} props.children content
 * @returns {JSX.Element} React element
 *
 * @example
 * return (<InvestorNotice />);
 *
 * @example
 * return (<InvestorNotice show />);
 *
 * @example
 * return (<InvestorNoticeTIL show className="customClass" />);
 */
const InvestorNotice = ({ show, className, containerClassName, children }) =>
  show && (
    <Grid container className={classNames('InvestorNotice', className)}>
      <Grid item xs={12}>
        <div className={classNames('InvestorNotice-container', containerClassName)}>
          <NoteWithBoldText variant="warning">{children}</NoteWithBoldText>
        </div>
      </Grid>
    </Grid>
  );

InvestorNotice.propTypes = {
  show: bool.isRequired,
  className: string,
  children: node.isRequired,
};

InvestorNotice.defaultProps = {
  className: '',
};

export default InvestorNotice;

