import React from 'react';
import { bool, func, shape } from 'prop-types';
import { Checkbox } from '../.';
import ErrorMessage from '../../../rc-inline-error-message';
import { Note } from '@shared_modules/baui-ui/components';
import { SENTILINK_AGREEMENTS } from './constants';

import './SentilinkAgreement.scss';

/**
 * Sentilink Agreement component.
 *
 * @component
 * @param {Object} props contains checked, disabled, handleCheckboxChange, validationErrors, pageType
 * @param {Boolean} props.checked checkbox state
 * @param {Boolean} props.disabled checkbox disabled state
 * @param {Function} props.handleCheckboxChange click handler to check/uncheck the checkbox
 * @param {Object} props.validationErrors error object
 * @returns {JSX.Element}
 */
const SentilinkAgreement = ({ checked, disabled, handleCheckboxChange, validationErrors }) => {
  const showErrors = !!Object.keys(validationErrors).length;

  const ROOT_CLASS = 'SentilinkAgreement';

  return (
    <div className={ROOT_CLASS}>
      <Note className={`${ROOT_CLASS}-banner`}>
        <span className={`${ROOT_CLASS}-banner-content`}>
          <b>{SENTILINK_AGREEMENTS.bannerText.title}</b>
          <p>{SENTILINK_AGREEMENTS.bannerText.contentText}</p>
        </span>
      </Note>
      <ErrorMessage
        validationErrors={validationErrors}
        dataQaIdPrefix={SENTILINK_AGREEMENTS.name}
        className={`${ROOT_CLASS}-error`}
      />
      <Checkbox
        labelClass={`${ROOT_CLASS}-label`}
        className={`${ROOT_CLASS}-checkbox`}
        name={`${SENTILINK_AGREEMENTS.name}-checkbox`}
        checked={checked}
        onChange={handleCheckboxChange}
        disabled={disabled}
        error={showErrors}
      >
        {SENTILINK_AGREEMENTS.checkboxText}
      </Checkbox>
    </div>
  );
};

SentilinkAgreement.propTypes = {
  checked: bool.isRequired,
  disabled: bool,
  handleCheckboxChange: func,
  validationErrors: shape({}),
};

SentilinkAgreement.defaultProps = {
  disabled: false,
  validationErrors: {},
  handleCheckboxChange: () => {},
};

export default SentilinkAgreement;

