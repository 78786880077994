export default [
  {
    value: 0,
    label: '< 1 year',
  },
  {
    value: 12,
    label: '1 year',
  },
  {
    value: 24,
    label: '2 years',
  },
  {
    value: 36,
    label: '3 years',
  },
  {
    value: 48,
    label: '4 years',
  },
  {
    value: 60,
    label: '5 years',
  },
  {
    value: 72,
    label: '6 years',
  },
  {
    value: 84,
    label: '7 years',
  },
  {
    value: 96,
    label: '8 years',
  },
  {
    value: 108,
    label: '9 years',
  },
  {
    value: 120,
    label: '10+ years',
  },
];

