import React, { useState } from 'react';
import axios from 'lc-http';
import classNames from 'classnames';
import { Input } from 'propulsion-rc-v2';
import { Button } from '@shared_modules/local-propulsion/components';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';

import {
  FB_URL,
  TWITTER_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  TERMS_OF_USE_URL,
  PRIVACY_POLICY_URL,
  NEWSLETTER_SUBSCRIBE_URL,
  CONTACT_US_URL,
} from './constants';
import { MEMBERS_REVIEW_URL } from '../../constants/userResourceUrls';
import './index.scss';

// import the images for the footer
import lcLogo from './images/lc-logo-white.svg';
import verisignLogo from './images/verisign.svg';
import ehlLogo from './images/ehl-logo.svg';
import fbLogo from './images/fb.svg';
import instagramLogo from './images/instagram.svg';
import linkedinLogo from './images/linkedin.svg';
import twitterLogo from './images/twitter.svg';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [emailSubmitStatus, setEmailSubmitStatus] = useState(null);

  // no custom input validations here because we are relying
  // html5 email validations until we have a good propulsion solution
  // for displaying errors here
  const onSubmitHandler = e => {
    e.preventDefault();

    if (error) {
      return setEmailSubmitStatus({
        submitted: false,
        message: error,
      });
    }

    axios
      .post(NEWSLETTER_SUBSCRIBE_URL, { email })
      .then(response => {
        if (response.data.err) {
          setEmailSubmitStatus({
            submitted: false,
            message: 'Something went wrong. Please try again.',
          });
          return;
        }
        // show success message then remove after 4 seconds
        setEmailSubmitStatus({
          submitted: true,
          message: 'Thanks! You joined the newsletter.',
        });
        heapTrackEvent('PL DM - click email subscribe');
        setTimeout(() => {
          setEmailSubmitStatus(null);
        }, 4000);
      })
      .catch(error => {
        setEmailSubmitStatus({
          submitted: false,
          message: 'Something went wrong. Please try again.',
        });
      });
  };

  const onChangeHandler = event => {
    const emailValue = event.target.value;
    setEmail(emailValue);

    if (!emailValue.match(/^[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,4}$/)) {
      setError('Email address is not correctly formatted');
      return;
    }

    setError('');
  };

  const onBlurHandler = () => {
    if (error) {
      return setEmailSubmitStatus({
        submitted: false,
        message: error,
      });
    }

    // reset state
    return setEmailSubmitStatus(null);
  };

  const infoSection = (
    <div className="Footer-infoSection Footer-sectionDivider u-bodyText">
      <div className="Footer-contentSection Footer-contentCol1">
        <div className="Footer-address">
          <img className="Footer-logo" src={lcLogo} alt="LendingClub logo" />
          <div className="Footer-address">
            <div>595 Market Street #200</div>
            <div>San Francisco, California 94105</div>
          </div>
          <div className="Footer-contactLink">
            <a
              className="u-colorWhite u-textDecorationUnderline"
              href={CONTACT_US_URL}
              target="_blank"
              onClick={() => heapTrackEvent('PL DM - click contact us link')}
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </div>
        </div>
        <div className="Footer-social">
          <a href={FB_URL} target="_blank" rel="noopener noreferrer">
            <img className="Footer-socialIcon" src={fbLogo} alt="Facebook" />
          </a>
          <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
            <img className="Footer-socialIcon" src={twitterLogo} alt="Twitter" />
          </a>
          <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
            <img className="Footer-socialIcon" src={instagramLogo} alt="Instagram" />
          </a>
          <a href={LINKEDIN_URL} target="_blank" rel="noopener noreferrer">
            <img className="Footer-socialIcon Footer-socialIcon--end" src={linkedinLogo} alt="LinkedIn" />
          </a>
        </div>
      </div>
      <div className="Footer-contentSection Footer-contentCol2">
        <label className="u-fontTextMedium" htmlFor="email">
          Sign up for our newsletter to get helpful tips and the latest updates.
        </label>
        <form className="Footer-emailForm" onSubmit={onSubmitHandler}>
          <div className="Footer-emailFormInputWrapper">
            <Input
              name="email"
              type="email"
              required
              fullWidth
              value={email}
              placeholder="Email address"
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
              data-qa-id="email-input"
              aria-label="Email"
              id="email"
            />
          </div>
          <div className="Footer-emailFormBtnWrapper">
            <Button name="subscribe-button" type="submit" className="Footer-formBtn">
              Subscribe
            </Button>
          </div>
          {emailSubmitStatus && (
            <div
              className={classNames(
                'Footer-emailMessage u-fontDisplayMedium u-textAlignCenter',
                { 'u-colorCardinal500 u-bgColorCardinal100': !emailSubmitStatus.submitted },
                { 'u-colorWhite u-bgColorSapphire500': emailSubmitStatus.submitted }
              )}
            >
              {emailSubmitStatus.message}
            </div>
          )}
        </form>
        <div className="Footer-emailFormText u-microText u-colorWhite">
          By submitting your email address, you agree to LendingClub&#39;s{' '}
          <a
            href={TERMS_OF_USE_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="u-textDecorationUnderline u-colorWhite"
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            href={PRIVACY_POLICY_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="u-textDecorationUnderline u-colorWhite"
          >
            Privacy Policy
          </a>
          .
        </div>
      </div>
    </div>
  );

  const trademarkSection = (
    <div className="Footer-trademarkSection Footer-sectionDivider">
      <div className="Footer-contentSection Footer-trademarkSection--left">
        <div className="Footer-trademarkImg">
          <img src={ehlLogo} alt="Ehl" />
        </div>
        <div className="Footer-trademarkImg Footer-trademarkImg--end">
          <img src={verisignLogo} alt="Verisign" />
        </div>
      </div>
    </div>
  );

  const disclaimerSection = (
    <>
      <ol className="Footer-disclaimerSection Footer--lcBankDisclaimerSection u-microText">
        <li>
          Checking a rate through us generates a soft credit inquiry on a person’s credit report, which is visible only
          to that person. A hard credit inquiry, which is visible to that person and others, and which may affect that
          person’s credit score, only appears on the person’s credit report if and when a loan is issued to the person.
          Credit eligibility is not guaranteed. APR and other credit terms depend upon credit score and other key
          financing characteristics, including but not limited to the amount financed, loan term length, and credit
          usage and history.
        </li>
        <li>
          Between January 1, 2023 and May 17, 2023, Personal Loans issued by LendingClub Bank were funded within 42
          hours after loan approval, on average. 35% of Personal Loans issued by LendingClub Bank during the same period
          were funded within 24 hours after loan approval. Loan approval, and the time it takes to issue a credit
          decision, are not guaranteed and individual results vary based on creditworthiness and other factors,
          including but not limited to investor demand.
        </li>
        <li>
          Reviews collected and authenticated by Bazaarvoice. All reviews can be found at{' '}
          <a
            href={MEMBERS_REVIEW_URL}
            className="u-colorWhite u-fontTextRegular u-textDecorationUnderline"
            target="_blank"
            rel="noreferrer"
          >
            Reviews.Lendingclub.com
          </a>
          .
        </li>
        <li>This is one member's experience. Individual results may vary.</li>
      </ol>
      <div className="Footer-disclaimerWrapper">
        <p className="u-microText Footer-disclaimerItem">
          Unless otherwise specified, all credit and deposit products are provided by LendingClub Bank, N.A., Member
          FDIC, Equal Housing Lender (“LendingClub Bank”), a wholly-owned subsidiary of LendingClub Corporation, NMLS ID
          167439. Credit products are subject to credit approval and may be subject to sufficient investor commitment.
          ​Deposit accounts are subject to approval. Only deposit products are FDIC insured.
        </p>
        <p className="u-microText Footer-disclaimerItem">
          “LendingClub” and the “LC” symbol are trademarks of LendingClub Bank.
        </p>
        <p className="u-microText Footer-disclaimerItem">© 2022 LendingClub Bank. All rights reserved.</p>
      </div>
    </>
  );

  return (
    <footer className="Footer u-bgColorDenim500 u-colorWhite">
      <div className="Footer-contentArea">
        {infoSection}
        {trademarkSection}
        {disclaimerSection}
      </div>
    </footer>
  );
};

export default Footer;

