/**
 * defaultFormPost
 * creates a hidden form element with one input field on the page that posts to the action
 * specified to redirect users to another page via a POST request (instead of
 * GET) after successful client side validation and submission of form data
 * use to avoid query strings in the URL and enhanced security
 * @param {Object} opts properties incl the name, value of input field and form action
 */

// TODO add csrf token to body, else blocked by csrf
export default function defaultFormPost(opts) {
  const { name, value, action } = opts;
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = action;
  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = name;
  input.value = value;
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
}

