import React from 'react';
import ReactModal from 'react-modal';
import { bool, func, node, oneOf, string } from 'prop-types';
import classnames from 'classnames';
import './Modal.scss';

/**
 * Accessibility concern:
 * react-modal needs to be pointed to the application element
 * to be able to hide content behind the modal.
 * react-modal will complain in the console if no app element is set.
 * For all the pages that uses base-layout, local-propulsion,
 * or propulsion layouts, and other pages this selectors should
 * be enough, but feel free to append your page application element.
 * @type {HTMLElement[]}
 */
const appElements = [document.getElementById('react-root'), document.querySelector('#maincontent > div')];
const firstDefinedElement = appElements.find(x => x);
if (firstDefinedElement) ReactModal.setAppElement(firstDefinedElement);

/**
 * Migration guide:
 *
 * Button support deprecated, if you had a button,
 * please include them as part of your _children_ prop.
 *
 * _overrideClasses_ and _accessibility_ props are deprecated
 * _classes_ prop changed to _className_
 *
 * new _overlayClassName_ prop to change the style fo the overlay
 * new _disableLayoutOverrides_ prop to disable positioning style overrides
 *   that will ease the migration to the new modal
 */

const CloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10.5919L17.3975 5.19441C17.6568 4.9352 18.077 4.9352 18.3362 5.19441L18.8056 5.66376C19.0648 5.92298 19.0648 6.34325 18.8056 6.60246L13.4081 12L18.8056 17.3975C19.0648 17.6568 19.0648 18.077 18.8056 18.3362L18.3362 18.8056C18.077 19.0648 17.6568 19.0648 17.3975 18.8056L12 13.4081L6.60246 18.8056C6.34325 19.0648 5.92298 19.0648 5.66376 18.8056L5.19441 18.3362C4.9352 18.077 4.9352 17.6568 5.19441 17.3975L10.5919 12L5.19441 6.60246C4.9352 6.34325 4.9352 5.92298 5.19441 5.66376L5.66376 5.19441C5.92298 4.9352 6.34325 4.9352 6.60246 5.19441L12 10.5919Z"
      className="CloseIcon-path"
    />
  </svg>
);

const Modal = ({
  'aria-describedby': describedby,
  'aria-label': ariaLabel,
  'aria-labelledby': labelledby,
  children,
  className,
  handleClose,
  id,
  overlayClassName,
  showCloseIcon,
  closeIconWhite,
  showModal,
  desktopVariant,
  mobileVariant,
  ...otherModalProps
}) => {
  const variant = baseClass => [`${baseClass}--desktop${desktopVariant}`, `${baseClass}--mobile${mobileVariant}`];
  const modalClassNames = classnames('Modal-Content', className, variant('Modal-Content'), {
    'Modal-Content--withCloseIcon': showCloseIcon,
  });
  const overlayClassNames = classnames('Modal-Overlay', overlayClassName, variant('Modal-Overlay'));

  return (
    <ReactModal
      aria={{
        labelledby,
        describedby,
      }}
      className={modalClassNames}
      contentLabel={ariaLabel}
      id={id}
      isOpen={showModal}
      onRequestClose={handleClose}
      overlayClassName={overlayClassNames}
      contentElement={(props, children) => (
        <div className="Modal-ContentWrapper">
          <div {...props}>{children}</div>
        </div>
      )}
      preventScroll
      {...otherModalProps}
    >
      {showCloseIcon ? (
        // This img tag is interactive
        <button
          className={classnames('CloseIcon', { 'CloseIcon--white': closeIconWhite })}
          onClick={handleClose}
          aria-label="Close"
        >
          <CloseIcon />
        </button>
      ) : null}
      {children}
    </ReactModal>
  );
};

const ariaRequiredPropCheck = (props, propName, componentName) => {
  if (!props['aria-label'] && !props['aria-labelledby']) {
    return new Error("One of props 'aria-label' or 'aria-labelledby' is required");
  }
};

Modal.propTypes = {
  /**
   * String indicating how the content container should be
   * announced to screenreaders, use if not aria-labelledby id is available.
   * Example: 'Session Expire Notice'
   */
  'aria-label': ariaRequiredPropCheck,
  /**
   * header element id inside the modal that provides label to the modal,
   * if there no such element, please use aria-label.
   * Example: 'mymodal-label-id'
   */
  'aria-labelledby': ariaRequiredPropCheck,
  /** description element id (optional) */
  'aria-describedby': string,
  /** The content of the modal */
  children: node,
  /** Classname of the content container */
  className: string,
  /**
   * Function that handles closing the modal, either by
   * the close icon, click on the background, ESC key.
   */
  handleClose: func,
  /** ID applied to the content container */
  id: string,
  /** Classname of the overlay container */
  overlayClassName: string,
  /** It will add a close button to the top right of the content container */
  showCloseIcon: bool,
  /** Close button is black by default */
  closeIconWhite: bool,
  /** Controlled component, please always provide this prop */
  showModal: bool.isRequired,
  desktopVariant: oneOf(['Plain', 'Overflow']).isRequired,
  mobileVariant: oneOf(['Plain', 'FullScreen', 'FullWidth']).isRequired,
};

Modal.defaultProps = {
  handleClose: () => {},
  showCloseIcon: false,
  showModal: false,
  className: undefined,
  id: undefined,
  overlayClassName: undefined,
  desktopVariant: 'Plain',
  mobileVariant: 'Plain',
};

export default Modal;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/rc-modal-v2/Modal.scss');