import React from 'react';
import { shape, string } from 'prop-types';

const ErrorMessage = ({ validationErrors, dataQaIdPrefix, className }) => {
  const keys = Object.keys(validationErrors);
  const numErrors = keys.length;

  if (numErrors < 1) return null;

  // TODO can we get rid of the ul styling for multiple errs on same field?
  // there are no mocks anyway
  const Tag = numErrors > 1 ? 'ul' : 'div';
  const ChildTag = numErrors > 1 ? 'li' : 'div';

  return (
    <div className={numErrors > 1 ? 'error--list' : null}>
      <Tag className={className}>
        {keys.map(key => (
          <ChildTag className="error--content" key={key}>
            <span
              data-qa-id={`${dataQaIdPrefix}-error-text`}
              dangerouslySetInnerHTML={{ __html: validationErrors[key] }}
            />
          </ChildTag>
        ))}
      </Tag>
    </div>
  );
};

ErrorMessage.propTypes = {
  validationErrors: shape(),
  className: string,
  dataQaIdPrefix: string,
};

ErrorMessage.defaultProps = {
  validationErrors: {},
  className: '',
  dataQaIdPrefix: '',
};

export default ErrorMessage;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/rc-inline-error-message/ErrorMessage.scss');