import { string } from 'prop-types';
import React from 'react';
import './ShortDisclaimer.scss';

const ShortDisclaimer = ({ className }) => {
  return (
    <p className={`ShortDisclaimer ${className}`}>
      * A hard credit inquiry, which may affect your credit score, will only appear on your credit report if and when a
      loan is issued to you.
    </p>
  );
};

ShortDisclaimer.propTypes = {
  className: string,
};

ShortDisclaimer.defaultProps = {
  className: '',
};

export default ShortDisclaimer;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');