const getStreetAddress = address => {
  if (!address || !address.street) return '';

  const streetNumber = address.streetNumber;
  const street = address.street.trim();

  if (streetNumber) {
    return street.startsWith(streetNumber) ? street : `${streetNumber} ${street}`;
  }

  return street;
};

export default getStreetAddress;

