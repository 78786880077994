import ERRORS from '../../../common-properties/errors';

export const LOAN_TYPES = {
  INDIVIDUAL: 'individual',
  JOINT: 'joint',
};

export const HEAP_TRACK = {
  EVENT_NAMES: {
    PL_APPLICATION_FRONTEND: 'PL_Application_Front_End',
  },
  EVENT_VALUES: {},
};

export default {
  messages: {
    error: ERRORS,
  },
  LOAN_TYPES,
  HEAP_TRACK,
};

export const INVALID_SENSITIVE_CONTENT_IN_NAME_FIELD =
  "It appears that you included information which might reveal your identity to potential investors. Please revise that it doesn't include your Social Security Number, email address, or other information that could identify where you live.";

export const EMPLOYEMENT_TYPES = ['contractor', 'employed', 'part_time', 'selfemployed'];

export const CUSTOM_REGULAR_EXP = '.*(\\d[\\*\\.\\(\\)\\s-]*){9,}.*|.*@.*';

