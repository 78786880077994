/* istanbul ignore file */
// the above statement tells the test
// runner to ignore this file for the purpose of calculating coverage
import React from 'react';
import ReactDOM from 'react-dom';
import { App as PropulsionApp } from 'propulsion-rc-v2';
import { PageProvider } from '../../shared_modules/local-propulsion/components';

import './direct-mail.scss';

import App from './client/App';

ReactDOM.render(
  <PageProvider appProvider={PropulsionApp} value="direct-mail">
    <App />
  </PageProvider>,
  document.getElementById('direct-mail')
);

