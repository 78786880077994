export const FLOW_TYPES = {
  ILOC: 'iloc',
  LBB: 'lbb',
  TOPUP: 'topup',
  TOPUPMB: 'topup_MB',
  DEFAULT: 'default',
};

export const PRODUCT_TYPES = {
  TOPUP: 'TOPUP',
  CLEANSWEEP: 'CLEANSWEEP',
  LBB_BCQ: 'LBB_BCQ',
};

export const SUB_PRODUCT = {
  TOPUP: 'TOPUP',
};

