import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './index.scss';

const Section = props => {
  const { children, classes } = props;
  return (
    <section className={classnames('Section', classes)}>
      <div className="Section-content">{children}</div>
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
};

export default Section;

