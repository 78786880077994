import React from 'react';
import { Grid } from 'propulsion-rc-v2';
import { shape, string } from 'prop-types';

const ModalTextContent = ({ texts, classNameTexts }) => {
  const { title, subtitle, secondarySubtitle, text } = texts;
  return (
    <>
      <Grid item xs={12}>
        <div className={`${classNameTexts}-title`}>{title}</div>
        <div className={`${classNameTexts}-subtitle`}>{subtitle}</div>
      </Grid>
      <Grid item xs={12}>
        <div className={`${classNameTexts}-textBold`}>{secondarySubtitle}</div>
        <div className={`${classNameTexts}-text`}>{text}</div>
      </Grid>
    </>
  );
};

ModalTextContent.prototypes = {
  texts: shape({
    title: string,
    subtitle: string,
    secondarySubtitle: string,
    text: string,
  }),
  classNameTexts: string,
};

export default ModalTextContent;

