// This fixes a bug where error messages had double dots.
const withFinalDot = str => (str.slice(-1) === '.' ? str : `${str}.`);

module.exports = (errors = {}) =>
  errors
    ? Object.keys(errors).reduce((str, validationError, index) => {
        const errorMessage = errors[validationError];

        if (!errorMessage) {
          return str;
        }

        if (index === 0) {
          return withFinalDot(`${errorMessage}`);
        }

        return withFinalDot(`${str} ${errors[validationError]}`);
      }, '')
    : '';

