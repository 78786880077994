import React, { useState, useEffect } from 'react';
import axios from 'lc-http';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';
import { isAlphaNumeric } from '@shared_modules/baui-utils/client/validation/validators';

import {
  DM_CODE_SUBMIT_URL,
  DM_CODE_MAX_LENGTH,
  MAX_INVALID_DM_CODE_ATTEMPTS,
  DM_CODE_INVALID,
  DM_CODE_NOT_ALPHANUMERIC,
  DM_ERROR_UNEXPECTED,
  DM_ERROR_CODE_LENGTH,
} from './constants/dmCode';

import '../direct-mail.scss';

import Header from './components/Header';
import Footer from './components/Footer';
import Layout from './layouts/Layout';

import Logo from './images/lc-logo.svg';

const App = () => {
  const [submitStatus, setSubmitStatus] = useState(null);
  const [invalidCodeCount, setInvalidCodeCount] = useState(0);
  const [startTime] = useState(Date.now());
  const attemptErrors = [DM_CODE_INVALID];

  useEffect(() => {
    if (invalidCodeCount >= MAX_INVALID_DM_CODE_ATTEMPTS) {
      heapTrackEvent('PL DM Code Submit Attempt', { errMessage: 'too many attempts' });
    }
  }, [invalidCodeCount]);

  const handleDMCodeSubmit = fundingCode => {
    if (fundingCode.length === DM_CODE_MAX_LENGTH) {
      if (!isAlphaNumeric(fundingCode)) {
        setInvalidCodeCount(invalidCodeCount + 1);
        return setSubmitStatus({ error: DM_CODE_NOT_ALPHANUMERIC });
      }

      heapTrackEvent('PL DM Code Submit Attempt', { fundingCode });
      axios
        .post(DM_CODE_SUBMIT_URL, { fundingCode })
        .then(res => {
          const { data } = res;
          if (data.err) {
            data.err.forEach(err => {
              if (attemptErrors.includes(err.error)) setInvalidCodeCount(invalidCodeCount + 1);
            });

            // response has returned with error, so send the first one
            // we see.
            setSubmitStatus({ error: data.err[0] });
          }

          if (data.nextPage) {
            // check to see if the next page directs us to the pi funnel
            // to pre-populate the form
            if (/identity/.test(data.nextPage)) {
              // create a form so we can piggy back on the default
              // submit behavior so we can redirect the user to the
              // pi funnel with the given funding code so we can pre-fill the
              // flow
              const form = document.createElement('form');
              // set the form to redirect submit the data and redirect
              // to the correct url
              form.action = data.nextPage;
              form.method = 'POST';
              // add the form to the page
              document.body.appendChild(form);

              // create a hidden input field that will contain the funding code
              const codeInput = document.createElement('input');
              codeInput.type = 'hidden';
              codeInput.name = 'fundingCode';
              codeInput.value = data.code;
              // add the input to the form
              form.appendChild(codeInput);
              // sumbit the form and redirect.
              form.submit();
              heapTrackEvent('PL DM - click check your rate cta', { invalidCodeCount });
              heapTrackEvent('PL DM - time on page', {
                timeOnPageInSec: (Date.now() - startTime) / 1000,
              });
            } else {
              // then the code has gone though,
              // redirect to the next page
              window.location.assign(data.nextPage);
            }
          }
        })
        .catch(err => {
          // TODO: get correct verbage for this and
          // find out if there some kind of error logger we can use.
          setSubmitStatus({
            error: DM_ERROR_UNEXPECTED,
          });
        });
    } else {
      // TODO: get correct verbage for this and
      // find out if there some kind of error logger we can use.
      heapTrackEvent('PL DM Code Submit Attempt', { errMessage: 'invalid code length' });
      setSubmitStatus({
        error: DM_ERROR_CODE_LENGTH,
      });
    }
  };

  return (
    <div className="DirectMail u-positionRelative">
      <Header logoImgSrc={Logo} logoAltText="LendingClub logo" classes="u-positionAbsolute" />
      <Layout onDMCodeSubmitHandler={handleDMCodeSubmit} submitStatus={submitStatus} />
      <Footer />
    </div>
  );
};

export default App;

