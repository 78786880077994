import React from 'react';
import classnames from 'classnames';
import { useMediaQuery, MEDIA_QUERY } from '@shared_modules/hooks';
import { getResponsiveImage } from '../../utils';
import coverImgSm from './images/cover-626.jpg';
import coverImgMd from './images/cover-768.jpg';
import coverImgLg from './images/cover-1024.jpg';
import coverImgXl from './images/cover-1280.jpg';
import coverImgSmV2 from './images/cover-626-variant.jpg';
import coverImgMdV2 from './images/cover-768-variant.jpg';
import coverImgLgV2 from './images/cover-1024-variant.jpg';
import coverImgXlV2 from './images/cover-1280-variant.jpg';

export const DM_IMAGES_MAP = {
  control: {
    SM: coverImgSm,
    MD: coverImgMd,
    LG: coverImgLg,
    XL: coverImgXl,
  },
  variant: {
    SM: coverImgSmV2,
    MD: coverImgMdV2,
    LG: coverImgLgV2,
    XL: coverImgXlV2,
  },
};

/**
 * ResponsiveImage - Display Cover Image based on Screen Size
 * @param {Object} props
 * @param {Boolean=} props.displayVariant to render the default or variant cover image
 * @returns {JSX.Element} React Element
 */
const ResponsiveImage = ({ displayVariant }) => {
  const isNotMobile = useMediaQuery(MEDIA_QUERY.PHONE_LG_AND_UP);
  const isTabletLg = useMediaQuery(MEDIA_QUERY.TABLET_LG_AND_UP);
  const isDesktop = useMediaQuery(MEDIA_QUERY.DESKTOP_SM_AND_UP);
  const isTabletMd = useMediaQuery(MEDIA_QUERY.TABLET_MD_AND_UP);
  const isTabletSm = isNotMobile && !isTabletLg;
  const expImages = DM_IMAGES_MAP[displayVariant ? 'variant' : 'control'];

  let image = getResponsiveImage({ isNotMobile, isTabletSm, isTabletMd, isTabletLg, isDesktop }, expImages);

  return (
    <img
      src={image.src}
      className={classnames(`DMCodeInput-img-${image.suffix}`, {
        'u-positionAbsolute': !['sm'].includes(image.suffix),
      })}
      alt={`cover-img-${image.suffix}`}
    />
  );
};

ResponsiveImage.defaultProps = {
  displayVariant: false,
};

export default ResponsiveImage;

