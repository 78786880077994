import React from 'react';
import { node, oneOf, string } from 'prop-types';
import { Grid, Card } from 'propulsion-rc-v2';
import classNames from 'classnames';
import './style.scss';

const ROOT_CLASS = 'NoteUI';

/**
 * Note component
 * display Notes in baui repo, we could use with 4 different variants,
 * 'info'|'warning'|'success'|'error'
 *
 * @component
 * @example
 * return (<Note><Component /></Note>);
 *
 * @param   {object}          props           props Object
 * @param   {string=}         props.className className to allow style override
 * @param   {('info'|'warning'|'success'|'error')=} props.variant   multiple style variants support
 * @param   {JSX.Element=}    props.children  content of Note you want to wrap
 * @return  {JSX.Element}     React component
 */
const Note = ({ className, children, variant }) => {
  const classNameVariants = {
    info: `${ROOT_CLASS}--info`,
    warning: `${ROOT_CLASS}--warning`,
    success: `${ROOT_CLASS}--success`,
    error: `${ROOT_CLASS}--error`,
  };

  return (
    <Card className={classNames(ROOT_CLASS, className, classNameVariants[variant])}>
      <Grid container className={`${ROOT_CLASS}-container`}>
        <Grid item xs={12} className={`${ROOT_CLASS}-wrapper`}>
          <div className={`${ROOT_CLASS}-content`}>{children}</div>
        </Grid>
      </Grid>
    </Card>
  );
};

Note.propTypes = {
  children: node,
  className: string,
  variant: oneOf(['info', 'warning', 'success', 'error']),
};

Note.defaultProps = {
  variant: 'info',
};

const NoteWithTitleHoc =
  Note =>
  ({ omit: children, title, content, ...props }) => {
    return (
      <Note {...props}>
        <h3 className={`${ROOT_CLASS}-title`}>{title}</h3>
        <p>{content}</p>
      </Note>
    );
  };

export const NoteWithTitle = NoteWithTitleHoc(Note);

const NoteWithClose =
  Note =>
  ({ content, ...props }) => {
    return (
      <Note {...props}>
        <div className={`${ROOT_CLASS}-close-btn`}>x</div>
        <div className={`${ROOT_CLASS}-body`}>
          <p>{content}</p>
        </div>
      </Note>
    );
  };

export const NoteWithCloseButton = NoteWithClose(Note);

export default Note;

