import { useEffect } from 'react';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';
import AutocompleteAddress from '@shared_modules/local-propulsion/components/AutocompleteAddress/AutocompleteAddress';
import { isPO, isPOBox, PO_WARNING_TEXT } from '@shared_modules/baui-utils/client/utils/address';

/**
 * useAddressInput Hook
 *
 * Custom hook that returns all the event handlers that AutocompleteAddress uses.
 *
 * @component
 * @example
 *  const {
 *    handleOnBlur,
 *    updateAddress,
 *    onFocus,
 *    clearValidationErrors,
 *  } = useAddressInput({
 *    model,
 *    validationErrors,
 *    warningMessages,
 *    showHelperText,
 *    onBlur,
 *    setValidationErrors,
 *    setShowHelperText,
 *    setModel,
 *    setWarningMessages,
 *    setHasSeenAndFixedPOWarning,
 *  });
 *
 * @param   {Object}    props                      props Object
 * @param   {Object}    props.model                object with default values
 * @param   {Object}    props.validationErrors     all validations error values
 * @param   {Object}    props.warningMessages      all warningMessages values
 * @param   {Object}    props.showHelperText       all showHelperText values
 * @param   {function}  props.setValidationErrors  setter to update validationErrors object
 * @param   {function}  props.setShowHelperText    setter to update showHelperText object
 * @param   {function}  props.setModel             setter to update model object
 * @param   {function}  props.setWarningMessages   setter to update warningMessages object
 * @param   {function}  props.setHasSeenAndFixedPOWarning  whether the user put a PO box match and fixed it
 * @param   {React.FormEventHandler} props.onBlur  onBlur event
 *
 * @return  {object}  object with functions
 */
const useAddressInput = ({
  model,
  validationErrors,
  warningMessages,
  showHelperText,
  onBlur,
  setModel,
  setValidationErrors,
  setShowHelperText,
  setWarningMessages,
  setHasSeenAndFixedPOWarning,
}) => {
  useEffect(() => {
    const isPOObject = {
      isSecondary: false,
      address: { streetAddress: model.street || '', secondaryUsePrimaryAddress: false },
    };
    setShowHelperText({
      street: isPO(isPOObject),
    });
    setWarningMessages(isPO(isPOObject) ? { street: PO_WARNING_TEXT } : {});
  }, [model.street]);

  const clearValidationErrors = () => {
    const newValidations = { ...validationErrors };
    Object.keys(validationErrors).forEach(key => {
      if (['street', 'state', 'city', 'zip'].includes(key)) delete newValidations[key];
    });
    setValidationErrors(newValidations);
  };

  const onFocus = ({ target: { name } }) => {
    const hasError = !!validationErrors[name];
    setShowHelperText({
      ...showHelperText,
      [name]: !hasError,
    });
  };

  const updateAddress = newAddress => {
    const { streetAddress: omit, ...restOfAddress } = newAddress;
    setModel(prevModel => ({
      ...prevModel,
      ...restOfAddress,
      street: newAddress.streetAddress,
    }));
  };

  const handleOnBlur = event => {
    const {
      target: { name },
    } = event;
    onBlur(event);
    if (name === 'street') {
      if (isPOBox(model.street)) {
        // user enters a potential PO Box and there isn't already a warning showing
        if (!warningMessages.street) {
          heapTrackEvent('PL PI Address entered potential PO Box', {
            eventValue: 'showing PO warning text',
          });
        }
        setWarningMessages({ ...warningMessages, street: PO_WARNING_TEXT });
        setShowHelperText({ ...showHelperText, street: true });
      } else {
        // user fixes PO warning (enters a non PO Box street address after there is already a warning)
        if (warningMessages.street) {
          heapTrackEvent('PL PI Address fixed potential PO Box', {
            eventValue: 'removing PO warning text',
          });
          setHasSeenAndFixedPOWarning(true);
        }
        const { street: omit, ...restOfWarningMessages } = warningMessages;
        setWarningMessages(restOfWarningMessages);
      }
    }
  };

  return {
    handleOnBlur,
    updateAddress,
    onFocus,
    clearValidationErrors,
    AutocompleteAddress,
  };
};

export default useAddressInput;

