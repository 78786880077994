import { AmountPurposeFieldsTypes } from '../constants';
import validations from '../../AmountPurpose/AmountPurposeFields/amount-purpose-validations';
import loanProperties from '../../../common-properties/loanProperties';
import loanPurposes from '../../../common-properties/loanPurposes';
import { validate as validator } from 'ui-validator';

export const { MONTHLY_PAYMENT_OPTIONAL, MONTHLY_PAYMENT_REQUIRED } = AmountPurposeFieldsTypes;

export const isMonthlyPaymentType = type => [MONTHLY_PAYMENT_OPTIONAL, MONTHLY_PAYMENT_REQUIRED].includes(type);
export const returnValueIfMonthlyPaymentType = (type, payload) => isMonthlyPaymentType(type) && payload;

export const loanAmountFlatValidation = loanAmount => {
  const loanAmountValidator = validations(
    loanProperties.MINIMUM_AMOUNT_TO_BORROW,
    loanProperties.MAXIMUM_AMOUNT_TO_BORROW,
    'loanAmount'
  );
  return validator({ loanAmount }, loanAmountValidator);
};

export const isValidLoanPurposeValue = loanPurpose => loanPurposes.find(item => item['enum-name'] === loanPurpose);

export const verifyAmountAndPurposeFieldsFromURL = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isValidPrefillLoanAmount = Object.keys(loanAmountFlatValidation(queryParams.get('loanAmount'))).length === 0;
  return isValidLoanPurposeValue(queryParams.get('loanPurpose')) && isValidPrefillLoanAmount;
};

const roundNearestUp10 = num => Math.ceil(num / 10) * 10;

export const getMinAndMaxLoanAmountRange = loanAmount => {
  const minAmount = roundNearestUp10(Math.ceil(loanAmount / 53));
  const maxAmount = roundNearestUp10(Math.ceil(loanAmount / 18));
  return { minAmount, maxAmount };
};

export const isValidLoanAmount = loanAmount =>
  loanAmount >= loanProperties.MINIMUM_AMOUNT_TO_BORROW && loanAmount <= loanProperties.MAXIMUM_AMOUNT_TO_BORROW;

export const getMonthlyPaymentTrackingProps = ({ loanAppData }) => {
  const { loanAmount, loanPurpose, monthlyPayment } = loanAppData;
  const { minAmount, maxAmount } = getMinAndMaxLoanAmountRange(loanAmount);
  return {
    LOAN_AMOUNT: loanAmount,
    LOAN_PURPOSE: loanPurpose,
    PAYMENT: monthlyPayment,
    ...(loanAmount && {
      PAYMENT_MIN_RANGE: minAmount,
      PAYMENT_MAX_RANGE: maxAmount,
    }),
  };
};

export const getInputsTrackingProps = ({
  showMonthlyPaymentInput = false,
  loanAmountVisible = true,
  loanPurposeVisible = true,
}) => {
  return {
    IS_LOAN_AMOUNT_INPUT_VISIBLE: loanAmountVisible,
    IS_LOAN_PURPOSE_INPUT_VISIBLE: loanPurposeVisible,
    IS_PAYMENT_INPUT_VISIBLE: showMonthlyPaymentInput,
  };
};

