import React, { useState, createContext, useContext } from 'react';
import { node, string, func, oneOfType, shape } from 'prop-types';
import { PageIdProvider } from '../PageIdProvider';
import dynamicModuleRegistry from 'dynamic-module-registry';

// LCContextObject we create this object from middleware with all params uses by all the funnel
const LCContextObject = dynamicModuleRegistry.get('LCContext') || {
  originator: {},
};
const LCContext = createContext({
  ...LCContextObject,
  setLCContext: () => {},
});
const useLCContext = () => useContext(LCContext);

// PageProvider is PageIdProvider wrapped by a context that contains the originator info (LCBank or WebBank) from the loanAppInProgress
const PageProvider = props => {
  const setLCContext = context => {
    setState({ ...state, ...context });
  };

  const [state, setState] = useState({
    ...props.additionalContext,
    ...LCContextObject,
    // This function will allow to set LCContext dynamically
    setLCContext,
  });

  return (
    <LCContext.Provider value={state}>
      <PageIdProvider {...props}>{props.children}</PageIdProvider>
    </LCContext.Provider>
  );
};

PageProvider.propTypes = {
  appProvider: oneOfType([func, node]),
  children: node.isRequired,
  path: string,
  value: oneOfType([func, string]),
  additionalContext: shape({}),
};

PageProvider.defaultProps = {
  appProvider: null,
  path: undefined,
  value: undefined,
  additionalContext: {},
};

export { LCContext, PageProvider, useLCContext };

