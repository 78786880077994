import React from 'react';
import { string, func } from 'prop-types';
import { Grid } from 'propulsion-rc-v2';
import checkMark from './images/checkmark.svg';
import './style.scss';

/**
 * NotificationBanner
 * Component to display a green notification banner with custom text
 * @component
 * @version 1.0.0
 *
 * @param {Object} props
 * @param {string=} props.message custom message to display in banner
 * @param {func=} props.handleClick optional click handler. used on BT offers redesign
 * @param {string=} props.className optional className to override styles
 * @param {string=} props.icon optional icon to display, defaults to checkmark icon
 * @param {string=} props.rightIcon optional icon to display at the right after message
 * @param {func=} props.handleRightIconClick optional click handler. used on BT offers redesign
 * @return {JSX.Element} React element
 *
 * @example
 * return (<NotificationBanner message="this is a message" />);
 */
const NotificationBanner = ({ message, handleClick, className, icon, rightIcon, handleRightIconClick }) => (
  <Grid
    container
    alignContent="center"
    className={`NotificationBanner ${className}`}
    justifyContent="center"
    onClick={handleClick}
  >
    {/* Visual icon, no context needed for screen readers. */}
    {icon && <img src={icon} className="NotificationBanner-image" alt="" aria-hidden />}
    <p className="NotificationBanner-text">{message}</p>
    {rightIcon && (
      <img
        src={rightIcon}
        className="NotificationBanner-image-right"
        alt=""
        aria-hidden
        onClick={handleRightIconClick}
      />
    )}
  </Grid>
);

NotificationBanner.propTypes = {
  message: string,
  handleClick: func,
  className: string,
  icon: string,
  rightIcon: string,
  handleRightIconClick: func,
};

NotificationBanner.defaultProps = {
  message: '',
  handleClick: () => {},
  className: '',
  icon: checkMark,
  rightIcon: '',
  handleRightIconClick: () => {},
};

export default NotificationBanner;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');