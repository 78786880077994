import { useEffect, useState } from 'react';

/*
  PHONE_SM_AND_UP >= 320px    
  PHONE_MD_AND_UP >= 375px
  PHONE_LG_AND_UP	>= 480px
  TABLET_SM_AND_UP >= 600px
  TABLET_MD_AND_UP >= 768px
  TABLET_LG_AND_UP >= 1024px
  DESKTOP_SM_AND_UP >= 1280px
  DESKTOP_MD_AND_UP >= 1440px
*/
const MEDIA_QUERY = {
  PHONE_SM_AND_UP: '(min-width: 320px)',
  PHONE_MD_AND_UP: '(min-width: 375px)',
  PHONE_LG_AND_UP: '(min-width: 480px)',
  TABLET_SM_AND_UP: '(min-width: 600px)',
  TABLET_MD_AND_UP: '(min-width: 768px)',
  TABLET_LG_AND_UP: '(min-width: 1024px)',
  DESKTOP_SM_AND_UP: '(min-width: 1280px)',
  DESKTOP_MD_AND_UP: '(min-width: 1440px)',
};

const useMediaQuery = query => {
  const mql = window.matchMedia(query);
  const [matched, setMatched] = useState(!!mql.matches);

  const screenTest = e => {
    setMatched(e.matches);
  };

  useEffect(() => {
    // NOTE: We should verify if exist in order to support IE 10
    mql?.addEventListener ? mql.addEventListener('change', screenTest) : mql.addListener(screenTest);
    return () => {
      mql?.addEventListener ? mql.removeEventListener('change', screenTest) : mql.removeListener(screenTest);
    };
  }, [query, matched]);
  return matched;
};

export { useMediaQuery, MEDIA_QUERY };

