import React, { Fragment } from 'react';
import { bool, string, func } from 'prop-types';
import classNames from 'classnames';
import { Button } from '../';

const BackButton = props => (
  <Button
    name={props.name}
    tabIndex="0"
    className="back-btn"
    disabled={props.disabled}
    onClick={props.goBack}
    variant="secondary"
    aria-label="Back"
  />
);

BackButton.propTypes = {
  goBack: func.isRequired,
  disabled: bool.isRequired,
  name: string,
};

BackButton.defaultProps = {
  name: 'backBtn',
};

const SubmitButton = props => (
  <Button
    type="submit"
    tabIndex="0"
    name={props.name}
    className={`submit-btn ${props.className}`}
    disabled={props.disabled}
  >
    {props.label || 'Next'}
  </Button>
);

SubmitButton.propTypes = {
  label: string.isRequired,
  disabled: bool.isRequired,
  className: string.isRequired,
  name: string.isRequired,
};

const PropulsionButtonGroup = props => (
  <>
    <div className={classNames('propulsion-button-group', props.btnGroupClassName)}>
      {props.goBack && (
        <BackButton goBack={props.goBack} disabled={props.disableBack} name={`${props.name}BackButton`} />
      )}
      <SubmitButton
        label={props.submitLabel}
        disabled={props.disableSubmit}
        className={props.submitBtnClassName}
        name={`${props.name}SubmitButton`}
      />
    </div>
    {props.subText && (
      <div
        className={classNames('propulsion-button-group__subtext', {
          'propulsion-button-group__subtext--no-backButton': !props.goBack,
        })}
      >
        {props.subText}
      </div>
    )}
  </>
);

PropulsionButtonGroup.propTypes = {
  goBack: func,
  disableBack: bool,
  submitLabel: string,
  disableSubmit: bool,
  subText: string,
  submitBtnClassName: string,
  btnGroupClassName: string,
  name: string,
};

PropulsionButtonGroup.defaultProps = {
  goBack: null,
  disableBack: false,
  submitLabel: 'Next',
  disableSubmit: false,
  subText: '',
  submitBtnClassName: '',
  btnGroupClassName: '',
  name: 'btn',
};

export default PropulsionButtonGroup;
export { SubmitButton, BackButton };

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/components/ButtonGroup/ButtonGroup.scss');