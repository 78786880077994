import { makeStylesPropulsion as makeStyles } from 'propulsion-rc-v2';

const useStyles = makeStyles(
  theme => {
    const { palette, typography, breakpoints } = theme;
    const { micro, pxToRem } = typography;
    return {
      root: {
        ...micro,
        color: palette.narwhal?.[500],
        maxWidth: '925px',
        alignSelf: 'center',
        margin: '0 auto',
        padding: '0',
        '& > p': {
          margin: '8px 0',
        },
      },
      disclosure: {
        display: 'flex',
        flexDirection: 'column',
      },
    };
  },
  { name: 'LANDING_DISCLOSURE' }
);

export { useStyles };

