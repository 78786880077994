import React, { useContext } from 'react';
import { string } from 'prop-types';
import { RadioGroup as PropulsionRadioGroup } from 'propulsion-rc-v2';
import { PageIdContext } from '../PageIdProvider';

const RadioGroup = ({ name, pageId = useContext(PageIdContext), ...extraProps }) => {
  const id = pageId ? `${pageId}-${name}` : name;
  const dataProps = {
    'data-id': id,
    'data-analytics': id,
    'data-qa-id': id,
    'data-nid-target': id,
  };

  return <PropulsionRadioGroup id={id} name={name} {...dataProps} {...extraProps} />;
};

RadioGroup.propTypes = {
  ...RadioGroup.propTypes,
  name: string.isRequired,
  pageId: string,
};

RadioGroup.defaultProps = {
  pageId: undefined,
};

export default RadioGroup;

