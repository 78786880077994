import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/file';
import hls from 'hls.js';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';
import { useIntersectionObserver } from '@shared_modules/hooks';

// It is necessary to react-player to load it from the global context and not
// from an external CDN.
global.Hls = hls;

// Default wrapper will mess with layouts
function EmptyWrapper({ children }) {
  return children;
}

function createHandlers(props, elementRef) {
  return Object.keys(props.heapEventsNames).reduce((prev, event) => {
    const heapEventName = props.heapEventsNames[event];
    const eventName = `on${event.charAt(0).toUpperCase()}${event.slice(1)}`; // onReady, onStart, onPlay, onProgress, onDuration, onPause
    return Object.assign(prev, {
      [eventName]: (...args) => {
        const heapAdditionalAttributes = Object.assign(
          {},
          props.heapAdditionalAttributes ?? {},
          eventName === 'onError' ? { errorType: args[0], errorDetails: JSON.stringify(args[1]) } : {}
        );
        const eventAttributes = props[`${event}Attributes`];

        Object.assign(
          heapAdditionalAttributes,
          typeof eventAttributes === 'function' ? eventAttributes({ elementRef, ...args }) : eventAttributes ?? {}
        );

        heapTrackEvent(heapEventName, heapAdditionalAttributes);
        props[eventName]?.(...args, elementRef);
      },
    });
  }, {});
}

/**
 * React HLS Player
 * @param {Object} heapEventsNames - key:value object with heap events names
 * @returns React Element
 */
export default function Player({ attributes, hlsOptions, config, pauseOnHidden, resumeOnVisible, ...props }) {
  const elementRef = useRef(null);
  const observerRef = new Proxy(elementRef, {
    get(obj, prop) {
      return obj.current?.getInternalPlayer();
    },
  });
  const [playingState, setPlayingState] = useState({ state: false, actor: 'system' });
  const isVisible = useIntersectionObserver(observerRef, { threshold: 0.5 });
  const videoEventHandlers = createHandlers(props, elementRef);

  function onPause() {
    videoEventHandlers.onPause?.(...arguments);
    if (playingState.state === true) {
      setPlayingState({ state: false, actor: 'user' });
    }
  }

  function onPlay() {
    videoEventHandlers.onPlay?.(...arguments);
    if (playingState.state === false) {
      setPlayingState({ state: true, actor: 'user' });
    }
  }

  useEffect(() => {
    if (resumeOnVisible && playingState.state === false && isVisible && playingState.actor === 'observable') {
      setPlayingState({ state: true, actor: 'observable' });
    } else if (pauseOnHidden && playingState.state === true && !isVisible) {
      setPlayingState({ state: false, actor: 'observable' });
    }
  }, [isVisible]);

  return (
    <ReactPlayer
      ref={elementRef}
      playing={playingState.state}
      config={{
        ...config,
        file: {
          attributes: {
            style: {},
            ...attributes,
          },
          hlsOptions: {
            enableWorker: false,
            capLevelToPlayerSize: true,
            maxBufferLength: 4,
            maxBufferSize: 1,
            ...hlsOptions,
          },
          ...config?.file,
        },
      }}
      wrapper={EmptyWrapper}
      {...props}
      {...videoEventHandlers}
      onPause={onPause}
      onPlay={onPlay}
    />
  );
}

