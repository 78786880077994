import { createValidator, validators as v } from 'ui-validator';

const isNotLendingClubEmail = createValidator((options, value) => {
  const opts = options || {};
  const val = value && value.trim();

  if (val && /^.+@lendingclub.com$/i.test(val))
    return opts.message || 'You are not able to use your LendingClub email. Please use another email address.';
});

const isSameAsPrimaryEmail = (value, key, attributes) => {
  const primaryEmail = attributes.primaryEmail;
  if (primaryEmail !== '' && value === primaryEmail) {
    return `${attributes.firstName !== '' ? attributes.firstName : 'Your co-borrower'}’s email cannot match your email`;
  }
};

const emailValidations = isJoint => {
  const validations = {
    email: {
      required: v.required({ message: 'The email address is required' }),
      email: v.email(),
      notLCEmail: isNotLendingClubEmail(),
      inputLength: v.inputLength({ max: 50, key: 'Your email address' }),
    },
  };

  if (isJoint) {
    validations.emailSecondary = {
      required: v.required({ message: 'The email address is required' }),
      email: v.email(),
      notLCEmail: isNotLendingClubEmail(),
      inputLength: v.inputLength({ max: 50, key: 'Your email address' }),
      isSameAsPrimaryEmail,
    };
  }

  return validations;
};

export default emailValidations;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');