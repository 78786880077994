import React from 'react';
import Section from '../Section';
import MemberReview from '../MemberReview';
import ReviewWidget from '../ReviewWidget';
import { MEMBERS_REVIEW_URL } from '../../constants/userResourceUrls';
import memberImg from '../../images/memberImg.svg';

const MemberEndorsement = () => (
  <Section classes="u-bgColorSapphire100 Layout-sectionPadding">
    <div className="Layout-title u-textCenter u-title1Text u-colorDenim500 u-textAlignCenter">What Our Members Say</div>
    <div className="Layout-reviewSection">
      <ReviewWidget />
    </div>
    <div className="Layout-memberReviewSection">
      <MemberReview
        classes=""
        imgSrc={memberImg}
        quoteText="With this loan I&#39;ll be debt-free  in 2.5 years, and I&#39;ll pay less each month! It really saved me and my future."
        memberName={
          <span>
            <span className="u-colorDenim500 u-fontTextBold">Ruby,</span> a member from Virginia
            <sup>4</sup>
          </span>
        }
        moreReviewsLink={MEMBERS_REVIEW_URL}
      />
    </div>
  </Section>
);

export default MemberEndorsement;

