import React, { Component } from 'react';
import { shape, string, element, oneOfType } from 'prop-types';
import Tooltip from 'rc-tooltip';
import classnames from 'classnames';
import { Grid } from 'propulsion-rc-v2';
import { toMoney, toPercentageTruncZeroOneDecimal } from '@shared_modules/offers/client/utils';
import WithCloseButton from './WithCloseButton';
import TooltipContent from './TooltipContent';
import TriggerWrapper from './TriggerWrapper';
import WithTargetRef from './WithTargetRef';
import DiscountedRateTooltip, { DiscountedRateTooltipOverlay } from './DiscountedRateTooltip';
import WithWhiteBackground from './WithWhiteBackground';
import { getOfferTypesName } from '@shared_modules/baui-utils';
import { StringTemplate } from '@shared_modules/baui-utils/client/utils';
import { REDESIGN_BT_TOOLTIP_CONTENT } from './constants';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const noPropagation = e => e.stopPropagation();

function APRTooltip() {
  return (
    <>
      APR stands for Annual Percentage Rate and measures the total cost of credit annually. Your APR consists of your
      annual interest rate plus a one-time origination fee.
    </>
  );
}

function OFeeTooltip() {
  return (
    <>
      We charge you this fee for making your loan. For more details, visit{' '}
      <a href="/loans/personal-loans/rates-fees" target="_blank" onClick={noPropagation}>
        the Rates and Fees page
      </a>
      .
    </>
  );
}

function OFeeDiscountTooltip({ name, discountPct, icon }) {
  return (
    <div className={`${name}-content`}>
      <h4>
        <img src={icon} className={`${name}-partyIcon`} alt="Celebrate icon" aria-hidden /> Origination Fee Discount
      </h4>
      <span>Hooray! Your fee was reduced by {toPercentageTruncZeroOneDecimal(discountPct)}</span>
    </div>
  );
}

function AvailableDebtPayoffTooltip({
  offer: { minDirectPayAmount },
  isBTDiscountPresent = true,
  btMinimumValue,
  hasSameBTMinimumAmount,
}) {
  const min = toMoney(minDirectPayAmount, { precision: 0 });
  const minDirectPayAmountValue = `${hasSameBTMinimumAmount ? min : btMinimumValue} of this loan${
    hasSameBTMinimumAmount ? '' : ` (${min})`
  }`;
  return (
    <>
      <p>
        <b>Debt Paydown</b>
      </p>
      <ul>
        <li>
          {`${
            isBTDiscountPresent
              ? `To get the discounted rate, you must use at least ${minDirectPayAmountValue} to`
              : `You must use at least ${minDirectPayAmountValue} of this loan to`
          } pay down your debt directly through LendingClub.`}
        </li>
        <li>
          You can pay your debt in full or a portion of it. You’ll choose which accounts to pay down on the next page.
        </li>
        <li>
          We’ll pay your creditors directly after your loan is issued. LendingClub does not charge processing fees for
          these transactions.
        </li>
        <li>
          We’ll subtract your origination fee and any money used to pay your creditors, and the leftover cash will be
          deposited into your bank account.
        </li>
      </ul>
    </>
  );
}

function BalanceTransferToolTip({ offer: { minDirectPayAmount } }) {
  return (
    <Grid container direction="row" justifyContent="center" className="BTTooltip-container">
      <Grid item xs={12} className="BTTooltip-desc">
        <h4>Balance Transfer Minimum</h4>
        <ul>
          <li>
            You are required to use at least {toMoney(minDirectPayAmount, { precision: 0 })} of the total loan amount to
            pay down existing debt.
          </li>
          <li>LendingClub will help send money from your loan straight to your creditors.</li>
          <li>Choose which creditors to pay and how much to pay each creditor on the next page.</li>
        </ul>
      </Grid>
    </Grid>
  );
}

function RedesignBalanceTransferToolTip({
  offer: { minDirectPayAmount, btMinimumAmountValue },
  isSubtitle = false,
  variant = 'control',
  hasSameBTMinimumAmount,
}) {
  const isBTRedesign = variant === 'btRedesign';
  const offerTypesName = getOfferTypesName(isBTRedesign);
  const tooltipTitle = `${offerTypesName.BT}${isBTRedesign ? '' : ' Minimum'}`;

  const bullets = REDESIGN_BT_TOOLTIP_CONTENT[variant];

  minDirectPayAmount = toMoney(minDirectPayAmount, { precision: 0 });

  let btMinimumAmountValueFormatted = hasSameBTMinimumAmount
    ? toMoney(btMinimumAmountValue, { precision: 0 })
    : btMinimumAmountValue;

  const loanText = isBTRedesign
    ? `${btMinimumAmountValueFormatted} of this loan${!hasSameBTMinimumAmount ? ` (${minDirectPayAmount})` : ''}`
    : minDirectPayAmount;

  return (
    <Grid container direction="row" justifyContent="center" className="BTTooltip-container">
      <Grid item xs={12} className="BTTooltip-desc">
        <h4>{isSubtitle ? 'Directly Pay Creditors' : tooltipTitle}</h4>
        <ul>
          {bullets.map((item, index) => {
            return (
              <li key={`tooltip-bullet-${index}`}>
                {StringTemplate.replace(item, {
                  loanText,
                })}
              </li>
            );
          })}
        </ul>
      </Grid>
    </Grid>
  );
}

const getTooltipTrackingAttribute = (name, offer) => {
  if (offer) return `${name}-${offer.duration}-${offer.amount}-${offer.dpType}-tooltip`;
  return `${name}-tooltip`;
};

const autoAdjustOverflow = { adjustX: 1, adjustY: 1 };

const alignments = {
  left: {
    points: ['cr', 'cl'],
    offset: [0, 0],
    overflow: autoAdjustOverflow,
  },
  right: {
    points: ['cl', 'cr'],
    offset: [0, 0],
    overflow: autoAdjustOverflow,
  },
  top: {
    points: ['bc', 'tc'],
    offset: [0, 0],
    overflow: autoAdjustOverflow,
  },
  bottom: {
    points: ['tc', 'bc'],
    offset: [0, 0],
    overflow: autoAdjustOverflow,
  },
  topLeft: {
    points: ['bl', 'tc'],
    offset: ['-25%', 0],
    overflow: autoAdjustOverflow,
  },
  topLeftB: {
    points: ['bl', 'tl'],
    offset: ['-8px', 0],
    overflow: autoAdjustOverflow,
  },
  topRightB: {
    points: ['bc', 'tc'],
    offset: ['10%', 0],
    overflow: autoAdjustOverflow,
  },
  leftTop: {
    points: ['tr', 'tc'],
    offset: [0, '-25%'],
    overflow: autoAdjustOverflow,
  },
  topRight: {
    points: ['br', 'tc'],
    offset: ['25%', 0],
    overflow: autoAdjustOverflow,
  },
  rightTop: {
    points: ['tl', 'tc'],
    offset: [0, '-25%'],
    overflow: autoAdjustOverflow,
  },
  bottomRight: {
    points: ['tr', 'bc'],
    offset: ['25%', 0],
    overflow: autoAdjustOverflow,
  },
  rightBottom: {
    points: ['bl', 'bc'],
    offset: [0, '25%'],
    overflow: autoAdjustOverflow,
  },
  bottomLeft: {
    points: ['tl', 'bc'],
    offset: ['-25%', 0],
    overflow: autoAdjustOverflow,
  },
  leftBottom: {
    points: ['br', 'bc'],
    offset: [0, '25%'],
    overflow: autoAdjustOverflow,
  },
};

class TooltipWrapper extends Component {
  state = { openTime: undefined };

  onVisibleChange = visible => {
    const openTime = this.state.openTime;
    const trackingData = {
      eventName: 'Tooltip View',
      NAME: getTooltipTrackingAttribute(this.props.name, this.props.offer),
      INTERACTION: this.state.action ? this.state.action : 'click',
      ACTION: visible ? 'SHOW' : 'HIDE',
    };

    if (visible) {
      this.setState({ openTime: Date.now() });
    } else {
      trackingData.DURATION = (Date.now() - openTime) / 1000;
    }

    if (window.lcTracking) {
      window.lcTracking.trackCustomEvent(trackingData);
    }
    if (this.props.onVisibleChange) this.props.onVisibleChange(visible);
    if (this.props.alignArrowOnVisible && visible) {
      this.props.calculateArrowPositionFn();
    }
  };

  saveInteraction = e => {
    e.stopPropagation();
    this.setState({ action: e.type });
  };

  render() {
    return (
      <Tooltip
        overlayClassName={classnames('tooltip-overlay', { [this.props.overlayClassName]: this.props.overlayClassName })}
        placement={this.props.placement}
        align={this.props.align || alignments[this.props.placement]}
        builtinPlacements={alignments}
        trigger={isMobile ? ['click'] : ['click', 'focus', 'hover']}
        mouseEnterDelay={0.1}
        overlay={<TooltipContent>{this.props.overlay}</TooltipContent>}
        onVisibleChange={this.onVisibleChange}
        getTooltipContainer={target => target.parentNode}
        onClick={this.saveInteraction}
        onMouseEnter={this.saveInteraction}
        onMouseLeave={this.saveInteraction}
        onFocus={this.saveInteraction}
        onBlur={this.saveInteraction}
        id={`tooltip-${this.props.name}`}
      >
        <TriggerWrapper>{this.props.children}</TriggerWrapper>
      </Tooltip>
    );
  }
}

TooltipWrapper.propTypes = {
  name: string.isRequired,
  offer: shape({}),
  overlay: oneOfType([element, string]).isRequired,
  placement: string,
  children: element.isRequired,
  overlayClassName: string,
};

TooltipWrapper.defaultProps = {
  offer: null,
  overlayClassName: null,
  placement: 'right',
};

const WhiteTooltipWithWrapper = WithWhiteBackground(TooltipWrapper);
const WhiteTooltip = WithWhiteBackground(Tooltip);
const TooltipWithClose = WithCloseButton(Tooltip);
const WhiteTooltipWithClose = WithCloseButton(WhiteTooltip);
const TooltipWithTarget = WithTargetRef(TooltipWrapper);
// This tooltip will have x and also targetRef.
const TooltipWithCloseTarget = WithTargetRef(TooltipWithClose);
const WhiteTooltipWithCloseTarget = WithTargetRef(WhiteTooltipWithClose);

export {
  APRTooltip,
  OFeeTooltip,
  OFeeDiscountTooltip,
  AvailableDebtPayoffTooltip,
  BalanceTransferToolTip,
  RedesignBalanceTransferToolTip,
  DiscountedRateTooltipOverlay,
  WhiteTooltip,
  DiscountedRateTooltip,
  TooltipWrapper,
  TooltipWithClose,
  WhiteTooltipWithClose,
  TooltipWithCloseTarget,
  WhiteTooltipWithCloseTarget,
  WhiteTooltipWithWrapper,
  TooltipWithTarget,
};

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/common-tooltips/CommonTooltips.scss');