const allowedProperties = [
  {
    paramName: 'referrerId',
    apiKey: 'refererId',
  },
];

/**
 * This function captures the query params and filters the query params based on allowed properties [paramName]
 * @param object query url query params
 * @returns object
 */

const getTrackingDataParams = query => {
  if (!query) return {};

  const filteredQueryProperties = allowedProperties.reduce((obj, prop) => {
    const { paramName, apiKey } = prop;
    if (paramName in query) {
      const value = query[paramName];
      if (value) obj[apiKey] = Array.isArray(value) ? value[0] : value;
    }
    return obj;
  }, {});

  return filteredQueryProperties;
};

module.exports = {
  getTrackingDataParams,
};

