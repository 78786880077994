import React, { useContext } from 'react';
import { TextField } from 'propulsion-rc-v2';
import { string, shape, bool, func } from 'prop-types';
import { PageIdContext } from '../PageIdProvider';
import validatorStringify from '../../../ui-validator-stringify/src/ui-validator-stringify';

const Select = ({
  name,
  errors,
  label,
  pageId = useContext(PageIdContext),
  helperText,
  isRequired,
  warningText,
  inputProps,
  ...props
}) => {
  const id = pageId ? `${pageId}-${name}` : name;
  const showError = !!errors && Object.keys(errors).length > 0;
  const errorHelperText = showError ? validatorStringify(errors) : null;
  const extendInputProps = {
    'data-id': id,
    'data-analytics': id,
    'data-qa-id': id,
    'data-nid-target': id,
    ...inputProps,
  };
  let displayIntent = null;
  let displayHelperText = helperText;

  // as per material design, we prioritize showing error > warning > helper text
  if (showError) {
    displayIntent = 'error';
    displayHelperText = errorHelperText;
  } else if (!!warningText && warningText.length > 0) {
    displayIntent = 'warning';
    displayHelperText = warningText;
  }

  return (
    <TextField
      component="Select"
      componentProps={{
        inputProps: {
          ...extendInputProps,
        },
      }}
      key={id}
      id={id}
      name={name}
      onChange={e => {
        e.preventDefault();
        if (props.onChange) props.onChange(e);
      }}
      onBlur={e => {
        e.preventDefault();
        if (props.onBlur) props.onBlur(e);
      }}
      required={isRequired}
      fullWidth
      label={label}
      intent={displayIntent}
      helperText={displayHelperText}
      {...props}
    >
      {props.children}
    </TextField>
  );
};

Select.propTypes = {
  ...TextField.propTypes,
  name: string.isRequired,
  errors: shape(),
  inputProps: shape(),
  onBlur: func,
  onChange: func,
  label: string,
  pageId: string,
  helperText: string,
  isRequired: bool,
  warningText: string,
};

Select.defaultProps = {
  pageId: undefined,
  errors: {},
  inputProps: {},
  label: '',
  onBlur: () => {},
  onChange: () => {},
  helperText: null,
  isRequired: true,
  warningText: null,
};

export default Select;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');