import React from 'react';
import './style.scss';

const ROOT_CLASS = 'WithBoldText';
const getDisplayName = WrappedComponent => WrappedComponent.displayName || WrappedComponent.name || 'Component';

/**
 * WithBoldText HOC
 * wrap any children component with div tag and bold styles
 * @example
 * const ComponentWithBold =  WithBoldText(Component);
 *
 * @param   {Object} Component - component to be wrapped
 * @return  {Object} React component
 */
const WithBoldText = Component => {
  const BoldText = function WithBoldText({ children, ...props }) {
    return (
      <Component {...props}>
        <div className={ROOT_CLASS}>{children}</div>
      </Component>
    );
  };
  //override name for this component
  BoldText.displayName = `${ROOT_CLASS}(${getDisplayName(Component)})`;

  return BoldText;
};

export default WithBoldText;

