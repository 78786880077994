import { FLOW_TYPES, PRODUCT_TYPES } from '../../constants-server';

const footerBasedOnProductId = (headerFooterContext, prodId = '') => {
  let productType = prodId.toLowerCase();

  // In some places cleansweep or CLEANSWEEP are being used instead of ILOC
  // this is helping to know that ILOC footer is required
  if (productType === PRODUCT_TYPES.CLEANSWEEP.toLowerCase()) {
    productType = FLOW_TYPES.ILOC;
  }

  if (productType.includes(PRODUCT_TYPES.TOPUP.toLowerCase())) {
    productType = FLOW_TYPES.TOPUP;
  }

  const productIdsinFlowTypes = Object.values(FLOW_TYPES).includes(productType);
  if (headerFooterContext?.footer && typeof headerFooterContext.footer === 'object' && productIdsinFlowTypes) {
    Object.assign(headerFooterContext.footer, { variant: productType });
  }
};

export { footerBasedOnProductId };

