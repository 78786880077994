import { useEffect } from 'react';

const useClickOutside = (ref, onOutsideClick) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick(event);
      }
    };
    // add the event listener
    document.body.addEventListener('click', handleClickOutside, false);

    return () => document.body.removeEventListener('click', handleClickOutside, false);
  }, [ref]);
};

export default useClickOutside;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');