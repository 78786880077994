import loanProperties from '@shared_modules/common-properties/loanProperties';
import validations from './AmountPurposeFields/amount-purpose-validations.js';
import AmountPurposeFields from './AmountPurposeFields/AmountPurposeFields';
import { useForm } from '@shared_modules/baui-ui/hooks';
import { returnValueIfMonthlyPaymentType } from './utils';

// TODO: lets pass a function with object param with type: props so we will avoid next iterations.
const useAmountPurpose = (initialLoanAmount = '', initialLoanPurpose = '', initialMonthlyPayment = '', props = {}) => {
  const { model, validationErrors, onChange, onBlur, createSubmitHandler } = useForm({
    defaultValues: {
      loanAmount: initialLoanAmount,
      loanPurpose: initialLoanPurpose,
      ...returnValueIfMonthlyPaymentType(props.variant, { monthlyPayment: initialMonthlyPayment }),
    },
    validations: {
      ...validations(
        loanProperties.MINIMUM_AMOUNT_TO_BORROW,
        loanProperties.MAXIMUM_AMOUNT_TO_BORROW,
        undefined,
        props
      ),
    },
    transform: {
      loanAmount: value => value.split(',').join(''), // we want to capture the rawValue of currency fields rather than the formatted values. This is a hack for now, need to modify useForm onChange handler to use rawValue somehow
    },
  });

  return {
    loanAmount: model.loanAmount,
    loanPurpose: model.loanPurpose,
    ...returnValueIfMonthlyPaymentType(props.variant, { monthlyPayment: model.monthlyPayment }),
    validationErrors,
    onChange,
    onBlur,
    createSubmitHandler,
    AmountPurposeFields,
  };
};

export default useAmountPurpose;

