/* istanbul ignore file */
export { default as Note, NoteWithTitle } from './Note';
export { default as WithBoldText } from './WithBoldText';
export { default as CoborrowerConsent } from './CoborrowerConsent';
export { default as CoBorrowerAddress } from './CoBorrowerAddress';
export { default as Footer } from './Footer';
export { default as BackArrowButton } from './BackArrowButton/BackArrowButton';
export { default as Video } from './LandingPageVideo/Video/video';
export { default as TranscriptIcon } from './LandingPageVideo/TranscriptIcon/transcriptIcon';

