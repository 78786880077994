import React, { useContext } from 'react';
import { shape, string, bool, oneOfType, node } from 'prop-types';
import { TextField, MaskedInput } from 'propulsion-rc-v2';
import { PageIdContext } from '../PageIdProvider';
import validatorStringify from '@shared_modules/ui-validator-stringify/src/ui-validator-stringify';

const PLTextField = props => {
  const {
    name,
    autoFocus,
    errors,
    label,
    inputProps,
    pageId = useContext(PageIdContext),
    helperText,
    isRequired,
    warningText,
    component,
    ...extraProps
  } = props;

  const createId = () => {
    if (extraProps.hasOwnProperty('customId')) {
      return `${name}-${extraProps.customId}`;
    }

    return pageId ? `${pageId}-${name}` : name;
  };
  const id = createId();
  const showError = !!errors && Object.keys(errors).length > 0;
  const errorHelperText = showError ? validatorStringify(errors) : null;

  const extendInputProps = {
    'data-id': id,
    'data-analytics': id,
    'data-qa-id': id,
    'data-nid-target': id,
    ...inputProps,
  };

  let displayIntent = null;
  let displayHelperText = helperText;

  // as per material design, we prioritize showing error > warning > helper text
  if (showError) {
    displayIntent = 'error';
    displayHelperText = errorHelperText;
  } else if (!!warningText && warningText.length > 0) {
    displayIntent = 'warning';
    displayHelperText = warningText;
  }

  // TODO: Propulsion UI Components should export MaskedInput as well.
  const componentOverride = component === 'Masked' ? MaskedInput : component;

  return (
    <TextField
      key={id}
      autoFocus={!!autoFocus}
      id={id}
      name={name}
      inputProps={extendInputProps}
      InputLabelProps={{
        'data-qa-id': `${id}-label`,
        ...extraProps.InputLabelProps,
      }}
      FormHelperTextProps={{
        'data-qa-id': `${id}-helper-text`,
        ...extraProps.FormHelperTextProps,
      }}
      autoComplete="off"
      required={isRequired}
      fullWidth
      label={label}
      intent={displayIntent}
      helperText={displayHelperText}
      component={componentOverride}
      {...extraProps}
    />
  );
};

PLTextField.propTypes = {
  ...TextField.propTypes,
  errors: shape({}),
  inputProps: shape({}),
  name: string.isRequired,
  label: oneOfType([string, node]),
  pageId: string,
  helperText: string,
  isRequired: bool,
  warningText: string,
};

PLTextField.defaultProps = {
  errors: {},
  inputProps: {},
  label: '',
  onKeyDown: () => {},
  pageId: undefined,
  helperText: null,
  isRequired: true,
  warningText: null,
};

export default PLTextField;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/components/PLTextField/PLTextField.scss');