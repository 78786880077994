// format clientSide or inline Errors
function formatErrors(errors) {
  let errKeys = [];
  let errMessages = [];
  Object.entries(errors).forEach(([fieldName, fieldErrors]) => {
    errKeys = errKeys.concat(Object.keys(fieldErrors).map(key => `${fieldName}_${key}`));
    errMessages = errMessages.concat(Object.values(fieldErrors));
  });
  return { errKeys, errMessages };
}

const heapTrackEvent = (eventName, additionalAttributes = {}) => {
  if (window.lcTracking) {
    window.lcTracking.trackCustomEvent({
      ...additionalAttributes,
      eventName,
    });
  }
};

const heapEventFn = moduleName => {
  return ({ EVENT: eventName = '', ...props }) => {
    if (moduleName) {
      props.MODULE = moduleName;
      eventName = `[PL Funnel][${moduleName}] - ${eventName}`;
    }
    heapTrackEvent(eventName, props);
  };
};

const heapTrackErrors = (errors = [], { customEventName, additionalAttributes = {} } = {}) => {
  if (!window.lcTracking) return;

  const errKeys = [];
  let errMessages = [];
  let validationNames = [];
  let errType;

  if (errors instanceof Error) {
    // axios
    if (!errors.response) {
      // Network Error
      errKeys.push(errors.message); // 'Network Error'
    } else if (errors.response) {
      // HTTP Error
      errKeys.push(errors.response.status);
      errMessages.push(errors.response.statusText);
    }
    errType = 'Connection Error';
  } else if (typeof errors.status !== 'undefined') {
    // jquery ajax
    if (errors.status === 0) {
      // Network Error
      errKeys.push('Network Error');
    } else {
      // HTTP Error
      errKeys.push(errors.status);
      errMessages.push(errors.statusText);
    }
    errType = 'Connection Error';
  } else if (Array.isArray(errors)) {
    //  Server Error from pbs etc
    errors.forEach(err => {
      errKeys.push(err.error);
      errMessages.push(err.message);
    });
    errType = 'Server Error';
  } else if (typeof errors === 'object') {
    // errors is an object, client side validation style
    Object.entries(errors).forEach(([fieldName, fieldErrs]) => {
      errKeys.push(fieldName);
      validationNames = validationNames.concat(
        Object.keys(fieldErrs || {}).map(validationName => `${fieldName}_${validationName}`)
      ); // ex: monthBirth_validDate
      errMessages = errMessages.concat(Object.values(fieldErrs || {}));
    });
    errType = 'Client Error';
  } else {
    // did not fall into above cases. text or some other err
    errKeys.push(errors);
    errType = 'Uncaught Error';
  }

  if (errKeys.length) {
    window.lcTracking.trackCustomEvent({
      eventName: customEventName || 'Funnel Error',
      ERROR_KEYS: errKeys.join('::'),
      ERROR_MESSAGES: errMessages.join('::'),
      VALIDATION_NAMES: validationNames.join('::'),
      ERROR_TYPE: errType,
      ...additionalAttributes,
    });
  }
};

const trackInlineErrors = errors => {
  if (window.lcTracking) {
    const formmatedErrors = formatErrors(errors);
    window.lcTracking.trackCustomEvent({
      eventName: 'Funnel Inline Error',
      ERROR_KEYS: formmatedErrors.errKeys.join('::'),
      ERROR_MESSAGES: formmatedErrors.errMessages.join('::'),
    });
  }
};

export { heapTrackErrors, heapTrackEvent, trackInlineErrors, heapEventFn };

