import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const HelpCalloutItem = ({ icon, title, text, classes, titleClassNames, textClassNames }) => (
  <div className={classNames('HelpCalloutItem', classes)}>
    <div className="HelpCalloutItem-img">
      <img src={icon} className="HelpCalloutItem-icon" alt="" aria-hidden />
    </div>
    <div className="HelpCalloutItem-content">
      <div className={classNames('HelpCalloutItem-title u-title2Text', titleClassNames)}>{title}</div>
      <div className={classNames('HelpCalloutItem-text u-bodyText', textClassNames)}>{text}</div>
    </div>
  </div>
);

HelpCalloutItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  classes: PropTypes.string,
  titleClassNames: PropTypes.string,
  textClassNames: PropTypes.string,
};

HelpCalloutItem.defaultProps = {
  classes: '',
  titleClassNames: '',
  textClassNames: '',
};

export default HelpCalloutItem;

