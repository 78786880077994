import React from 'react';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';
import Section from '../Section';
import InfoCallout from '../InfoCallout';
import emailIcon from '../../images/A/email-icon.svg';
import questionIcon from '../../images/A/question-icon.svg';
import { RESOURCE_CENTER_URL, EMAIL_HELP_URL } from '../../constants/userResourceUrls';

const Support = () => (
  <Section classes="u-bgColorWhite Layout-sectionPadding">
    <InfoCallout
      classes="Layout-col1"
      linkClasses="u-textDecorationUnderline"
      imgSrc={questionIcon}
      imgAltText="question-icon"
      title={
        <span>
          <span className="Layout-textBreak Layout-textBreak--lrgInline">Questions?</span> We&#39;ve got answers
        </span>
      }
      text="Learn more about personal loans and debt management by heading to our Resource Center."
      linkText="See the Resource Center"
      linkUrl={RESOURCE_CENTER_URL}
      onClick={() => heapTrackEvent('PL DM - click resource center link')}
    />
    <InfoCallout
      classes="Layout-col2 Layout-infoMarginTop"
      linkClasses="u-textDecorationUnderline"
      imgSrc={emailIcon}
      imgAltText="email-icon"
      title="We&#39;re here to help"
      text="Reach out to our support team anytime by contacting us through our Help Center."
      linkText="Email Our Support Team"
      linkUrl={EMAIL_HELP_URL}
      onClick={() => heapTrackEvent('PL DM - click email help link')}
    />
  </Section>
);

export default Support;

