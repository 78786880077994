import React from 'react';
import VideoPlayer from '@shared_modules/rc-hls-player/rc-hls-player';
import './style.scss';

export function Video({ contextAttr, events, posterUrl, videoUrl }) {
  const videoAdditionalHeapAttributes = ({ elementRef }) => {
    const VIDEO_FULL_LENGTH = Math.floor(elementRef.current.getDuration());
    const VIDEO_DURATION = Math.floor(elementRef.current.getCurrentTime());
    const VIDEO_DURATION_PERCENTAGE = Math.round((VIDEO_DURATION * 100) / VIDEO_FULL_LENGTH);

    return {
      VIDEO_FULL_LENGTH,
      VIDEO_DURATION,
      VIDEO_DURATION_PERCENTAGE,
    };
  };

  const videoEndAddiotionalHeapAttribute = ({ elementRef }) => {
    return { VIDEO_FULL_LENGTH: Math.floor(elementRef.current.getDuration()) };
  };

  return (
    <VideoPlayer
      attributes={{
        poster: posterUrl,
        width: '1280',
        height: '820',
        className: 'landing-Video',
      }}
      id="landing-video"
      url={videoUrl}
      controls
      playsinline
      pauseOnHidden
      resumeOnVisible
      heapEventsNames={{
        play: events.PLAY_VIDEO,
        pause: events.PAUSE_VIDEO,
        ended: events.END_VIDEO,
        error: events.ERROR,
      }}
      heapAdditionalAttributes={contextAttr}
      playAttributes={videoAdditionalHeapAttributes}
      pauseAttributes={videoAdditionalHeapAttributes}
      endedAttributes={videoEndAddiotionalHeapAttribute}
    />
  );
}

export default Video;

