import React from 'react';
import { useStyles } from './Disclosure.styles';
import classNames from 'classnames';

const Disclosure = ({ disclosureMap }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, classes.disclosure)}>
      {disclosureMap.map(({ key, text }) => (
        <p key={key}>{text}</p>
      ))}
    </div>
  );
};

export default Disclosure;

