import React, { useState } from 'react';
import { string, func, shape, bool } from 'prop-types';
import { InputLabel } from '@ui-propulsion/react-components';
import { RadioButton, RadioGroup, Select } from '@shared_modules/local-propulsion/components';
import loanPurposes from '@shared_modules/common-properties/loanPurposes';
import ErrorMessage from '@shared_modules/rc-inline-error-message';
import { BT_REIMAGINE_LOAN_PURPOSE_OPTIONS, CASH_LOAN_OPTIONS, VARIANT_TYPES } from '@shared_modules/baui-constants';
import './BTLoanAmountPurpose.scss';

const getLoanPurposeOptions = (_loanPurposes, isDm) => {
  const options = [];
  _loanPurposes.map(currentValue => {
    if (currentValue.active) {
      const value = currentValue['enum-name'] || currentValue.value;
      const label = value === 'credit_card' && isDm ? 'Balance Transfer' : currentValue.label;
      options.push(
        <option key={value} value={value}>
          {label}
        </option>
      );
    }
  });
  return options;
};

const BTLoanAmountPurpose = ({
  fieldName,
  validationErrors,
  onChange,
  onBlur,
  defaultValue,
  disabled,
  variantType,
  isDm,
}) => {
  const ROOT_CLASS = 'BTLoanAmountPurpose';
  const isBTReimagined = variantType === VARIANT_TYPES.Reimagined;

  if (variantType === VARIANT_TYPES.BT) return null;

  if (isBTReimagined) {
    defaultValue = BT_REIMAGINE_LOAN_PURPOSE_OPTIONS.find(obj => obj.value === defaultValue)?.value || 'other';
  }

  const purposes =
    (isBTReimagined && BT_REIMAGINE_LOAN_PURPOSE_OPTIONS) ||
    (variantType === VARIANT_TYPES.Cash && CASH_LOAN_OPTIONS) ||
    loanPurposes;
  const [dropdownOptions] = useState(getLoanPurposeOptions(purposes, isDm));

  if ([VARIANT_TYPES.Control, VARIANT_TYPES.Reimagined].includes(variantType)) {
    return (
      <div className="Form-input">
        <Select
          defaultValue={defaultValue === 'life_event' ? '' : defaultValue}
          disabled={disabled}
          errors={validationErrors[fieldName]}
          id={fieldName}
          label="Loan Purpose"
          name={fieldName}
          onBlur={onBlur}
          onChange={onChange}
        >
          <option key="" value="" disabled={defaultValue}>
            What’s the money for?
          </option>
          {dropdownOptions}
        </Select>
      </div>
    );
  }

  const hasBTLoanAmountPurposeError = validationErrors[fieldName];
  return (
    <div className={`${ROOT_CLASS}`}>
      <legend>
        <InputLabel
          className={`${ROOT_CLASS}-inputLabel`}
          component="div"
          error={hasBTLoanAmountPurposeError}
          htmlFor={fieldName}
          required
        >
          What will you use this money for?
        </InputLabel>
      </legend>
      <div className={`${ROOT_CLASS}-radio-wrapper`}>
        <RadioGroup name={fieldName} onChange={onChange} value={defaultValue}>
          {purposes.map(option => {
            return (
              <RadioButton
                error={hasBTLoanAmountPurposeError}
                id={`${fieldName}-${option.value}`}
                inputProps={{}}
                key={option.value}
                label={option.label}
                labelProps={{
                  id: `${fieldName}Label-${option.value}`,
                  htmlFor: `${fieldName}-${option.value}`,
                }}
                name={fieldName}
                value={option.value}
              />
            );
          })}
        </RadioGroup>
        {validationErrors[fieldName] && (
          <ErrorMessage validationErrors={validationErrors[fieldName]} dataQaIdPrefix={`${fieldName}`} />
        )}
      </div>
    </div>
  );
};

BTLoanAmountPurpose.propTypes = {
  defaultValue: string,
  disabled: bool,
  fieldName: string,
  isDm: bool,
  onBlur: func,
  onChange: func.isRequired,
  validationErrors: shape({}),
  variantType: string,
};

BTLoanAmountPurpose.defaultProps = {
  defaultValue: '',
  disabled: false,
  fieldName: 'loanPurpose',
  isDm: false,
  onBlur: () => {},
  validationErrors: {},
  variantType: VARIANT_TYPES.Control,
};

export default BTLoanAmountPurpose;

