import React from 'react';
import classnames from 'classnames';
import { bool, func, shape, string } from 'prop-types';
import leftArrowIcon from '@shared_modules/common-images/left-arrow.svg';
import { useStyles } from './BackArrowButton.styles';

/**
 * Function that triggers history back with a call back
 * @param {function} cb call back function
 * @param {Boolean} historyBack flag to identify whether to call window.history or not
 *
 * @returns {void}
 */
const goToPreviousLocation = (cb = () => {}, historyBack) => {
  cb();
  if (historyBack) window?.history?.back();
};

/**
 * BackButton component
 * display Back arrow button
 *
 * @component
 * @example
 * return (<BackButton />);
 *
 * @param {Object} props props Object
 * @param {String=} props.className to allow style override
 * @param {Object=} props.extraProps to allow properties customization
 * @param {Function=} props.onClickCb onclick callback
 * @param {Boolean=} props.historyBack flag to use history
 * @param {String=} props.icon button icon
 * @return {JSX.Element} React component
 */
const BackArrowButton = ({ className, onClickCb, extraProps, icon, historyBack }) => {
  const classes = useStyles();
  return (
    <div
      className={classnames(classes.root, { [className]: !!className })}
      aria-label={extraProps.ariaLabel || 'Navigate to the page you visited previously'}
      role="button"
      tabIndex={0}
      onClick={() => goToPreviousLocation(onClickCb, historyBack)}
      {...extraProps}
    >
      <img src={icon} alt="Back arrow" />
    </div>
  );
};

BackArrowButton.propTypes = {
  className: string,
  extraProps: shape({}),
  onClickCb: func,
  historyBack: bool,
  icon: string,
};

BackArrowButton.defaultProps = {
  className: '',
  extraProps: {},
  onClickCb: () => {},
  historyBack: true,
  icon: leftArrowIcon,
};

export default BackArrowButton;

