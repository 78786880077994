import React, { createContext } from 'react';
import { node, string, func, oneOfType } from 'prop-types';
import { Route } from 'react-router-dom';

const PageIdContext = createContext('');

// You could provide a custom path or a fixed value. Not both of them.
// For custom path or value as function this provider MUST be inside Router context.
// By default it will match the first /path
// If you provide a custom path, make sure to use :pageId to the matched part
const PageIdProvider = ({ children, appProvider: App, path, value: customValue }) => {
  const createApp = (childrenApp, value) => (
    <PageIdContext.Provider value={value}>{App ? <App>{childrenApp}</App> : childrenApp}</PageIdContext.Provider>
  );

  return typeof customValue === 'string' ? (
    createApp(children, customValue)
  ) : (
    <Route
      path={path || '/:pageId?'}
      render={({ match }) => {
        const value =
          typeof customValue === 'function' ? customValue(match.params) : customValue || match.params.pageId;

        return createApp(children, value);
      }}
    />
  );
};

PageIdProvider.propTypes = {
  appProvider: oneOfType([func, node]),
  children: node.isRequired,
  path: string,
  value: oneOfType([func, string]),
};

PageIdProvider.defaultProps = {
  appProvider: null,
  path: undefined,
  value: undefined,
};

export { PageIdContext, PageIdProvider };

