import React from 'react';

const noPropagation = e => e.stopPropagation();

const TooltipContent = props => (
  <div
    onClick={noPropagation}
    role="presentation"
    style={{
      whiteSpace: 'normal',
    }}
    {...props}
  />
);

export default TooltipContent;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/common-tooltips/CommonTooltips.scss');