import { OFFER_CARD_LABELS } from './index';

export default class TopUpOfferTagStrategy {
  setTagToShortestTerm({ filteredOffers, tags }) {
    tags[filteredOffers[0].duration] = OFFER_CARD_LABELS.BEST_RATE;
  }

  setTagToSmallestMonthlyPaymentTerm({ filteredOffers, tags, smallestMonthlyPaymentTerm }) {
    tags[smallestMonthlyPaymentTerm.duration] = OFFER_CARD_LABELS.LOWEST_PAYMENT;
  }

  calculateOfferTags({ filteredOffers, termWithLowerAprTopUp, tags, smallestMonthlyPaymentTerm }) {
    if (termWithLowerAprTopUp) {
      if (filteredOffers[0].duration !== smallestMonthlyPaymentTerm.duration) delete tags[filteredOffers[0].duration];
      tags[termWithLowerAprTopUp.duration] = OFFER_CARD_LABELS.BEST_RATE;
    }

    if (
      smallestMonthlyPaymentTerm.duration === termWithLowerAprTopUp.duration ||
      smallestMonthlyPaymentTerm.monthlyPayment === termWithLowerAprTopUp.monthlyPayment
    )
      tags[termWithLowerAprTopUp.duration] = OFFER_CARD_LABELS.BEST_RATE_AND_LOWEST_PAYMENT;

    return tags;
  }
}

