import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './index.scss';

import BAReviewWidget from '../BAReviewWidget';
import TrustPilotWidget from '../TrustPilotWidget';

const ReviewWidget = ({ classes }) => {
  return (
    <div className={classnames('ReviewWidget', classes)}>
      <BAReviewWidget classes="ReviewWidget-baReview" haveSup />
      <TrustPilotWidget classes="ReviewWidget-trustPilot" />
    </div>
  );
};

ReviewWidget.propTypes = {
  classes: PropTypes.string,
};

export default ReviewWidget;

