import objectPath from 'object-path';
import moment from 'moment';
import tenureOptions from '../../shared_modules/common-properties/tenure';
import { BT_OFFER_UP_KEYS, CASH_OFFER_UP_KEYS, OFFER_TYPE_NAMES } from '../../shared_modules/baui-constants';

export { default as getStreetAddress } from './get-street-address';
export { default as addUserAgent } from './add-user-agent';
export { default as defaultFormPost } from './defaultFormPost';
export { getSignedAgreements } from './get-signed-agreements';
export { getJanusCookieName } from './clear-session';

// this is used in pi2 for prefill. however note that for repeats-redesign
// the adjustTenurePrefill fn which WILL prefill tenure if it is not a perfect multiple of 12 months ( roudned down )
// see LMS-95575 -  For any other tenure value (in months) that is not a multiple of 12, don't prefill the field for pi2.
export const getTenureInYears = tenureInMonths => {
  if (typeof tenureInMonths !== 'number') return;
  const tenureInYears = tenureInMonths / 12;

  return tenureInMonths < 12 ? '0' : tenureInMonths % 12 === 0 && tenureInYears < 99 ? `${tenureInYears}` : undefined;
};

// if the prefill tenure value is old (based on the application date), then add to the tenure value so the user does not have to update manually or overlook correction
// outputInMonths was used before for pi2 long form prefill, where it was a dropdown with labels '10 years' mapping to a value of 120 (months) that pbs stores. with react pi2 long form, it is in years until submit to pbs, we transform to months
export function adjustTenurePrefill(
  prefillData,
  currentAppDate,
  {
    outputInMonths = false, // still done in pi2 as the default, as it gets passed through to getTenureInYears later TODO consolidate everything to years just in this fn. note that this still only adds to adjust whole year (12 month) values - less than a year would be +0 adjustment
    prefillDataPaths = {
      primary: 'actor.personalInfo.cumulativeJobTenure',
      secondary: 'secondaryActor.personalInfo.cumulativeJobTenure',
    },
  } = {}
) {
  const primaryTenure = objectPath.get(prefillData, prefillDataPaths.primary);
  const secondaryTenure = prefillDataPaths.secondary && objectPath.get(prefillData, prefillDataPaths.secondary);
  if (
    !prefillData ||
    (primaryTenure && typeof primaryTenure !== 'number') ||
    (secondaryTenure && typeof secondaryTenure !== 'number')
  )
    return;

  const currAppDate = moment(currentAppDate);
  const oldAppDate = moment(prefillData?.applicationDate);
  const yearsPassed = currAppDate.diff(oldAppDate, 'years') || 0; // rounded down
  // if (!yearsPassed) return;

  const largestPossibleTenureValue = tenureOptions[tenureOptions.length - 1].value;
  const calculatedPrimaryTenure = outputInMonths
    ? Math.min(primaryTenure + 12 * yearsPassed, largestPossibleTenureValue)
    : yearsPassed + Math.floor(primaryTenure / 12); /*original tenure*/
  objectPath.set(prefillData, prefillDataPaths.primary, calculatedPrimaryTenure);
  if (secondaryTenure) {
    const calculatedSecondaryTenure = outputInMonths
      ? Math.min(secondaryTenure + 12 * yearsPassed, largestPossibleTenureValue)
      : yearsPassed + Math.floor(secondaryTenure / 12);
    objectPath.set(prefillData, prefillDataPaths.secondary, calculatedSecondaryTenure);
  }
  return calculatedPrimaryTenure;
}

export const getTextBasedOnTag = tag =>
  [BT_OFFER_UP_KEYS.OFFER_UP, CASH_OFFER_UP_KEYS.OFFER_UP].includes(tag) ? ' at the same APR' : '';

/** Return proper offer type name for BT Reimagined experiment*/
export const getOfferTypesName = isBTRedesign => OFFER_TYPE_NAMES[isBTRedesign ? 'variant' : 'control'];

export const getExperimentsEnabled = exps => {
  return Object.keys(exps).reduce((acc, curr) => ({ ...acc, ...(exps[curr] ? { [curr]: exps[curr] } : {}) }), {});
};

