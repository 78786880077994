import React from 'react';
import PropTypes from 'prop-types';
import EasyLoanCallout from '../components/EasyLoanCallout';
import MemberEndorsement from '../components/MemberEndorsement';
import Support from '../components/Support';
import AboutLC from '../components/AboutLC';
import DMCodeInput from '../components/DMCodeInput';

// if you're importing other components, you'll need to put the local components (in this case Layout)
// css import at the bottom of the stack, in order to to have the css compile in the correct order.
// this import order wont be needed once we upgrade to css modules.
import './Layout.scss';

const Layout = ({ onDMCodeSubmitHandler, submitStatus }) => {
  return (
    <div className="Layout">
      <DMCodeInput onDMCodeSubmitHandler={onDMCodeSubmitHandler} submitStatus={submitStatus} />
      <AboutLC />
      <EasyLoanCallout classNames="u-bgColorSapphire100" contentClassNames="u-positionRelative" />
      <MemberEndorsement />
      <Support />
    </div>
  );
};

Layout.propTypes = {
  onDMCodeSubmitHandler: PropTypes.func.isRequired,
  submitStatus: PropTypes.object,
};

export default Layout;

