import { useEffect } from 'react';

/**
 * Hook that allows us to know when browser back event has been launched and perform an action through a callback
 * @example
 * useBrowserBack({ callbackFn: () => {}, });
 *
 * @param   {object}          props             props object
 * @param   {Function}   props.callbackFn  callback function to handle browserback event
 *
 * @return  {Object}  object with hook options
 */
const useBrowserBack = ({ callbackFn }) => {
  useEffect(() => {
    // We need to add a record in the history in that way avoid back navigation by default, in this way we can perform the desired action when the event occurs
    const { pathname, search } = window.location;
    // TODO: verify if we really need this extra pushState
    if (search?.includes('token')) {
      window.history.pushState(null, null, pathname + search);
    } else {
      window.history.pushState(null, null, pathname);
    }
    window.addEventListener('popstate', callbackFn);
    return () => {
      window.removeEventListener('popstate', callbackFn);
    };
  }, []);
};

export default useBrowserBack;

