import React from 'react';
import { arrayOf, func, shape, string, bool } from 'prop-types';
import classNames from 'classnames';
import { useLCContext } from '../../../local-propulsion/components/PageProvider/PageProvider';
import Disclosure from '@shared_modules/prescreen-components/components/Disclosure';
import { FAQ } from '@shared_modules/baui-ui/components/FAQ';

import './Footer.scss';

/**
 * Footer component.
 *
 * @param {Object} props props object
 * @param {String=} props.className custom class
 * @param {String=} props.disclosureClassName custom class for disclosure div
 * @param {Array=} props.faqMap FAQs list
 * @param {String=} props.faqSectionTitle custom title for FAQ section
 * @param {Array=} props.disclosureMap disclosures
 * @param {Function=} props.onFAQChange function to capture selected question.
 * @param {Boolean=} props.isBTRedesign if BTRedesign is active show UI changes
 * @returns {JSX.Element} React Component
 */

const Footer = ({
  className,
  disclosureClassName,
  disclosureMap,
  faqMap,
  faqSectionTitle,
  isBTRedesign,
  onFAQChange,
}) => {
  const lcContext = useLCContext();

  const FAQAdditionalProps = {
    ...(isBTRedesign ? { alignItems: 'flex-start' } : {}),
    ...(isBTRedesign ? { smallerFont: true } : {}),
  };

  return (
    <div className={classNames('Footer', className)} data-qa-id="footer-container">
      {faqMap && (
        <div className="Footer-faq">
          <FAQ faqs={faqMap} onChange={onFAQChange} title={faqSectionTitle} {...FAQAdditionalProps} />
        </div>
      )}

      <div className={classNames('Footer-disclaimer', disclosureClassName)}>
        {disclosureMap && <Disclosure disclosureMap={disclosureMap} />}
        {lcContext?.floridaStateDisclaimer && (
          <p>Loans issued to Florida residents may be subject to a state documentary stamp tax.</p>
        )}
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: string,
  disclosureClassName: string,
  disclosureMap: arrayOf(shape({})),
  faqMap: arrayOf(shape({})),
  faqSectionTitle: string,
  onFAQChange: func,
  isBTRedesign: bool,
};

Footer.defaultProps = {
  className: '',
  disclosureClassName: '',
  disclosureMap: undefined,
  faqMap: undefined,
  faqSectionTitle: undefined,
  onFAQChange: () => {},
  isBTRedesign: false,
};

export default Footer;

