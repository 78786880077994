import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './index.scss';

const InfoCallout = props => {
  const {
    classes,
    titleClasses,
    textClasses,
    linkClasses,
    imgClasses,
    imgSrc,
    imgAltText,
    title,
    text,
    linkText,
    linkUrl,
    isIcon,
    onClick,
  } = props;

  const infoImg = imgSrc ? (
    <img className={classnames('InfoCallout-img', imgClasses)} src={imgSrc} alt={imgAltText} />
  ) : null;
  const imgDisplay = isIcon ? infoImg : imgSrc;

  return (
    <div className={classnames('InfoCallout', classes)}>
      <div className="InfoCallout-imgContainer">{imgDisplay}</div>
      <div className="InfoCallout-infoContainer">
        <div className={classnames('InfoCallout-title u-title1Text u-colorDenim500', titleClasses)}>{title}</div>
        <div className={classnames('InfoCallout-text u-bodyText u-colorDenim500', textClasses)}>{text}</div>
        <div className="InfoCallout-link u-bodyText">
          <a
            className={classnames('u_textDecorationUnderline u-colorSapphire500', linkClasses)}
            href={linkUrl}
            target="_blank"
            onClick={onClick}
            rel="noopener noreferrer"
          >
            {linkText}
          </a>
        </div>
      </div>
    </div>
  );
};

InfoCallout.propTypes = {
  classes: PropTypes.string,
  titleClasses: PropTypes.string,
  textClasses: PropTypes.string,
  linkClasses: PropTypes.string,
  imgClasses: PropTypes.string,
  imgSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  imgAltText: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  isIcon: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

InfoCallout.defaultProps = {
  isIcon: true,
  onClick: () => {},
};

export default InfoCallout;

