import { DM_PAGE_CONTENT_DEFAULT, DM_PAGE_CONTENT_VARIANTS } from '../constants/content';

export const getPageContent = dmPersadoVariant => {
  const dmContentVariant = DM_PAGE_CONTENT_VARIANTS[dmPersadoVariant];

  if (!dmPersadoVariant || dmPersadoVariant === 'Control' || !dmContentVariant) return DM_PAGE_CONTENT_DEFAULT;

  return dmContentVariant;
};

/**
 * returns the appropriate image to display based on the screen size.
 * @param {Object} params
 * @param {Boolean=} params.isNotMobile flag to identify small screens (mobile)
 * @param {Boolean=} params.isTabletSm flag to identify tablet small and up
 * @param {Boolean=} params.isTabletMd flag to identify tablet medium and up
 * @param {Boolean=} params.isTabletLg flag to identify tablet large and up
 * @param {Boolean=} params.isDesktop  flag to identify desktop and up
 * @param {Object} images  mapped images object
 * @param {String} images.SM
 * @param {String} images.MD
 * @param {String} images.LG
 * @param {String} images.XL
 * @returns {Object} cover image obj
 */
export const getResponsiveImage = (
  { isNotMobile, isTabletSm, isTabletMd, isTabletLg, isDesktop },
  images = { SM: '', MD: '', LG: '', XL: '' }
) => {
  let image = {};

  switch (true) {
    case isNotMobile === false:
    case isTabletSm && !isTabletMd:
      image.src = images.SM;
      image.suffix = 'sm';
      break;
    case isTabletSm && isTabletMd:
      image.src = images.MD;
      image.suffix = 'md';
      break;
    case isTabletLg && !isDesktop:
      image.src = images.LG;
      image.suffix = 'lg';
      break;
    case isTabletLg && isDesktop:
      image.src = images.XL;
      image.suffix = 'xl';
      break;
    default:
  }
  return image;
};

