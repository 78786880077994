import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@shared_modules/rc-modal-v2';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';
import FundingCodeImageSrc from './images/funding-code.svg';
import { Button } from '@shared_modules/local-propulsion/components';
import './index.scss';

const ROOT_CLASS = 'WhereToFindCodeTooltip';

const WhereToFindCodeTooltip = ({ children }) => {
  const [show, setShow] = useState(false);
  const handleOpenToolTip = e => {
    setShow(true);
    heapTrackEvent('PL DM - click where to find code');
  };
  const modalProps = {
    'data-qa-id': `${ROOT_CLASS}`,
    'aria-labelledby': `${ROOT_CLASS}-content`,
    handleClose: () => setShow(false),
    className: `${ROOT_CLASS}`,
    overlayClassName: `${ROOT_CLASS}-overlay`,
    showModal: show,
    showCloseIcon: true,
    ariaHideApp: false,
  };
  return (
    <div className={`${ROOT_CLASS}`}>
      <div className={`${ROOT_CLASS}-trigger`} onClick={handleOpenToolTip}>
        {children}
      </div>
      <Modal {...modalProps}>
        <div className={`${ROOT_CLASS}-content`}>
          <img src={FundingCodeImageSrc} alt="12 digit RSVP code" />
          <h2 className={`${ROOT_CLASS}-heading`}>Find your 12 digit RSVP code</h2>
          <p className={`${ROOT_CLASS}-text`}>
            In the printed letter we sent you, look for a 12-digit alphanumeric code with hyphens. You will use this
            code to start your Online Application.
          </p>
          <Button name="button" className={`${ROOT_CLASS}-button`} variant="secondary" onClick={() => setShow(false)}>
            Found It!
          </Button>
          <p className={`${ROOT_CLASS}-footer`}>
            No? Speak with a Dedicated Credit Specialist <b>1-855-363-4151</b> (Mon-Fri 5 am-5 pm PST)
          </p>
        </div>
      </Modal>
    </div>
  );
};

WhereToFindCodeTooltip.propTypes = {
  children: PropTypes.element,
};

WhereToFindCodeTooltip.defaultProps = {
  children: null,
};

export default WhereToFindCodeTooltip;

