export default {
  ACCOUNT_LOCKED: 'If you are having trouble logging into your account please call customer support at (888) 596-3158 ',
  AGREEMENTS_MISSING: 'Agreements could not be found for the loan application.',
  ALTERNATE_PHONE_TOO_LONG: 'Your alternate phone is too long',
  AUTO_LOAN_NOT_FOUND: 'The auto loan application could not be found.',
  AUTO_PRIMARY_BORROWER_UNSUPPORTED_STATE: "Auto loans are not available in the borrower's state.",
  AUTO_SECONDARY_BORROWER_UNSUPPORTED_STATE: "Auto loans are not available in the second borrower's state.",
  AUTO_VEHICLE_VIN_INVALID:
    "We weren't able to find your vehicle based on the VIN number you provided. Please try entering your VIN number again.",
  CITY_TOO_LONG: 'Your city must be 40 characters or less.',
  CREDIT_CLASS_ID_MISSING: '',
  CREDIT_MODEL_SERVICE_CONNECTION_ISSUES:
    'We had trouble processing your request just now and apologize for the inconvenience. Please try submitting your information again, or try again later.',
  CSI_SERVER_ERROR:
    'Oops! Something went wrong. Try resubmitting the form or if you continue to have problems please try again later.',
  DMCODE_FROZEN:
    "Unfortunately, we didn't match your code. Please contact Customer Support at (888)596-3158 for further assistance.",
  DMCODE_INVALID: 'Oops! The code you entered does not match our records. Please try again.',
  DMCODE_USED:
    'Sorry, the funding code you entered has already been used towards a loan, please log in and use the email and password you have already given us. You may also contact our Customer Support at (888)596-3158.',
  DMCODE_EXPIRED: 'The code you entered has expired.',
  DMCODE_ALPHANUMERIC_ERROR: 'Please enter an alphanumeric pre-approval code.',
  DOES_NOT_HAVE_LOANAPP: 'The user does not have a loan application',
  DONT_SHARE_EMAIL: "We're sorry, this email is already in use. Please apply using a new email.",
  DUPLICATE_EMAIL_WRONG_PASSWORD:
    'It looks like this email is already in use. Use your existing password or create a new account with a different email. <br/><br/><a href="/account/forgotPassword.action">Forgot password</a>?',
  EMAIL_ALREADY_EXIST: 'The email address already exists.',
  EMAIL_EMPTY: 'The email address is required',
  EMAIL_TAKEN:
    'This email address is already registered. To open a new account, please provide an alternative email address to the current one on file.',
  EMAIL_TOO_LONG: 'Your email address must be 50 characters or less.',
  NO_LENDINGCLUB_EMAIL: 'You are not able to use your LendingClub email. Please use another email address.',
  EMPLOYER_TOO_LONG: 'Your employer name must be 40 characters or less.',
  EMPLOYER_WEB_SITE_TOO_LONG: 'Your employer website must be 40 characters or less.',
  EMPLOYMENT_STREET_ADDRESS_TOO_LONG: 'Your employment address must be 50 characters or less.',
  ENTER_VALID_WEBSITE: 'Please enter a valid url address',
  ETIMEDOUT: 'The server was temporarily not available. Please try again.',
  FIRST_NAME_EMPTY: 'Your first name is required',
  IDENTICAL_SSN_SECONDARY:
    'The 2nd Borrower cannot be the same person as the Primary Borrower. Please enter a different person for the 2nd Borrower.',
  IDENTITY_NOT_VERIFIED:
    'Your identity has not been verified, please contact customer support or restart the registration process',
  INCOME_NEGATIVE: 'The income you have entered is negative',
  INSUFFICIENT_DATA_TO_REQUEST_CREDIT_REPORT: 'INSUFFICIENT_DATA_TO_REQUEST_CREDIT_REPORT',
  INSUFFICIENT_LC_HISTORY:
    "We're sorry, but we are unable to list your loan at this time because you already have a loan with us, which has less than 6 months of payment history or has not been current at all time over the last 6 months.",
  INSUFFICIENT_PAYMENT_HISTORY:
    "We're sorry, but we are unable to list your loan at this time because you already have a loan with us, which has less than 6 months of payment history or has not been current at all time over the last 6 months.",
  INSUFFICIENT_PAYMENT_HISTORY_12:
    "We're sorry, but we are unable to list your loan at this time because you already have a loan with us, which has less than 12 months of payment history or has not been current at all time over the last 12 months.",
  INVALID_ACCOUNT: '',
  INVALID_ALTERNATE_PHONE: 'A valid alternate phone number is required',
  INVALID_CHARS_ERROR_ALTERNATE_PHONE_NUMBER:
    "Alternate phone number may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_DATE:
    "Date may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_EMAIL:
    "The Email may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_EMPLOYER:
    "Employer may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_EMPLOYER_ADDRESS:
    "Work address may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_EMPLOYER_WEB_SITE:
    "Employer Website description may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_FIRST_NAME:
    "The First Name may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_HOME_ADDRESS:
    "Street address may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_HOME_CITY:
    "City may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_HOME_STATE:
    "State may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_JOB_TITLE:
    "Position may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_LAST_NAME:
    "The last name may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_LOAN_PURPOSE_NAME:
    "Loan purpose may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_MORTGAGE_AMOUNT:
    "Mortgage amount may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_PASSWORD:
    "Password may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_PRIMARY_PHONE_NUMBER:
    "Primary phone number may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_RENT_AMOUNT:
    "Rent amount may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_SSN_EIN:
    "The SSN or Trust EIN may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_WORK_PHONE_NUMBER:
    "Work phone number may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_CHARS_ERROR_ZIP_CODE:
    "The zip code may not have one or more of the following characters: <, >, =, -- or contain strings that start with '&' and end with ';'",
  INVALID_DOB: 'A valid date of birth is required',
  INVALID_EMAIL: 'Please specify a valid email address',
  INVALID_HOME_RENT_AMOUNT: 'Please enter a valid home rent amount',
  INVALID_LOAN_AMOUNT: 'Please enter a valid loan amount',
  INVALID_LOAN_APPLICATION: 'Invalid loan application',
  INVALID_LOAN_PURPOSE: 'Invalid loan purpose specified',
  INVALID_MORTGAGE_AMOUNT: 'Please enter a valid mortgage amount',
  INVALID_PRIMARY_PHONE_NUMBER: 'A valid primary phone number is required, such as (555) 555-5555, (555) 555-5555 x555',
  INVALID_ROUTING_NUMBER:
    'We cannot identify your routing number. Please enter it again; make sure it includes 9 digits without any dashes, spaces or letters.',
  INVALID_SENSITIVE_CONTENT_ADDR_CITY:
    "Please revise your city to ensure that it doesn't include your name, Social Security Number, email address, or other information that could identify where you live. Please do not use any of the following characters: *",
  INVALID_SENSITIVE_CONTENT_BR_WEBSITE:
    "Please revise your website address to ensure that it doesn't include your name, Social Security Number, email address, or other information that could identify where you live. Please do not use any of the following characters: *",
  INVALID_SENSITIVE_CONTENT_EMPLOYER_NAME:
    "It appears that you included information which might reveal your identity to potential investors. Please revise your employer name to ensure that it doesn't include your Social Security Number, email address, or other information that could identify where you live.",
  INVALID_SENSITIVE_CONTENT_JOB_TITLE:
    "Please revise your job position to ensure that it doesn't include your name, Social Security Number, email address, or other information that could identify where you live. Please do not use any of the following characters: *",
  INVALID_SSN:
    'Invalid Social Security Number. The number you provided does not match your credit history. Please re-enter or call Customer Service at (888) 596-3157.',
  INVALID_SSN_SECONDARY:
    'Invalid Co-borrower Social Security Number. The number you provided does not match your credit history. Please re-enter or call Customer Service at (888) 596-3157.',
  INVALID_STATE: 'A valid state is required',
  INVALID_TOTAL_INCOME: 'Invalid income',
  INVALID_USER: '',
  INVALID_WORK_PHONE: 'A valid work phone number is required',
  INVALID_ZIP: 'A valid zip code is required',
  JOB_TITLE_TOO_LONG: 'Your job title must be 40 characters or less.',
  LAST_NAME_EMPTY: 'Your last name is required',
  LOAN_ALREADY_ACCEPTED: 'Loan app already accepted',
  LOAN_AMOUNT_MAXIMUM: 'The loan amount you have entered is above the {0} maximum loan amount allowed',
  LOAN_AMOUNT_MINIMUM: 'The loan amount you have entered is below the minimum loan amount allowed',
  LOAN_AMOUNT_MINIMUM_ND:
    'The loan amount you have entered is below the minimum loan amount allowed, $1025 is the minimum in North Dakota',
  LOAN_APP_IN_PROGRESS:
    'We\'re sorry, you already have a loan application in progress.<br>Please click <a href="/apply/personal/identity">here</a> to proceed with your application.',
  LOAN_NOT_APPROVED: 'Loan was not approved',
  MAXIMUM_SSN_REACHED:
    'Invalid Social Security Number. To protect your identity and prevent fraudulent loan requests, you must contact Customer Service at (888) 596-3157 to complete your loan request.',
  MAXIMUM_SSN_TRY_REACHED:
    'Invalid Social Security Number. To protect your identity and prevent fraudulent loan requests, you must contact Customer Service at (888) 596-3157 to complete your loan request.',
  MAX_HOME_RENT_AMOUNT: 'The home rent amount you have entered is above the maximum rent amount allowed',
  MAX_MORTGAGE_AMOUNT: 'The mortgage amount you have entered is above the maximum mortgage amount allowed',
  MAX_TOTAL_INCOME: 'The income you have entered is above the maximum income allowed',
  MISSING_CITY: 'A valid city is required',
  MISSING_DOB: 'A valid date of birth is required',
  MISSING_EMPLOYER: 'Please enter your employer name',
  MISSING_EMPLOYMENT_INFO: 'Please verify that you have submitted all your Employment Information',
  MISSING_EMPLOYMENT_STATUS: 'Please enter your employment status',
  MISSING_EMPLOYMENT_STREET_ADDRESS: 'Employment address is required',
  MISSING_HOME_RENT_AMOUNT: 'Please enter the home rent amount',
  MISSING_HOME_STATUS: 'Please enter your home status',
  MISSING_JOB_TENURE: 'Please enter your job tenure',
  MISSING_JOB_TITLE: 'Please enter a valid job title',
  MISSING_LOAN_AMOUNT: 'A valid loan amount is required',
  MISSING_LOAN_PURPOSE: 'A loan purpose is required',
  MISSING_MORTGAGE_AMOUNT: 'Please enter the mortgage amount',
  MISSING_NAME_OF_BUSINESS: 'Please enter name of your business',
  MISSING_PRIMARY_PHONE_NUMBER: 'A valid primary phone number is required',
  MISSING_SSN: 'Your Social Security number is required',
  MISSING_STATE: 'A valid state is required',
  MISSING_STREET_ADDRESS: 'A valid street address is required',
  MISSING_WORK_PHONE: 'A valid work phone number is required',
  MISSING_ZIP: 'A valid zip code is required',
  MULTI_FIRSTNAME:
    "It appears that you have entered more than one individual's first name. Please apply using only one person's information.",
  NO_BANK_ACC: 'No bank account found',
  NO_VALUATION_FOUND: '',
  OFFER_EXPIRED: 'The offer has expired',
  OFFER_MISSING: 'The offer is missing',
  OFFERS_NOT_AVAILABLE:
    'Oops! Something went wrong. Try resubmitting the form or if you continue to have problems please try again later.',
  PASSWORD_EMPTY: 'A password is required',
  PASSWORD_MATCHES_LOGIN_EMAIL: 'Your password must not match your login email',
  PASSWORD_NOT_VALID: 'Password must have 8 or more characters, with at least 1 number and 1 letter',
  PASSWORD_TOO_LONG: 'Your password must be between 8 and 40 characters',
  PERSONAL_INFO_LENGTH_ERROR_LASTNAME: 'The length of the last name must be less than 40 characters',
  PRIMARY_PHONE_TOO_LONG: 'Your primary phone is too long',
  REJECTED_BORROWER:
    'You have recently submitted a loan application. Please click <a href="/apply/personal/identity">here</a> to access your account.',
  SAME_PRIMARY_ALTERNATE_PHONE: 'Primary phone and alternate phone cannot be the same',
  SAME_PRIMARY_WORK_PHONE: 'Primary phone and work phone cannot be the same',
  STREET_ADDRESS_TOO_LONG: 'Your street address must be 50 characters or less.',
  UNABLE_TO_FIND_REFERRERS: 'Unable to find the referrer',
  UNDERAGE_JOINT_USER: 'The second borrower must be at least 18 years old to apply',
  UNDERAGE_USER: 'You must be at least 18 years old to apply',
  UNEXPECTED_ERROR:
    'Oops! Something went wrong. Try resubmitting the form or if you continue to have problems please try again later.',
  UNEXPECTED_BT_ERROR: 'Try again in a few minutes. If you continue to have problems, call 888-596-3157',
  BT_SYSTEM_ERROR: 'Try again in a few minutes. If you continue to have problems, call 888-596-3157',
  INVALID_PAYMENT_AMOUNT: 'Invalid total payment amount submitted.  Please reload.',
  INVALID_SUBMIT_STATE: 'Balance transfers may have already been confirmed.  Please reload.',
  INVALID_REQUEST_TO_SUBMIT: 'Cannot submit balance transfers.  Please reload.',
  INVALID_ADD_STATE: 'Try again in a few minutes. If you continue to have problems, call 888-596-3157',
  INVALID_REQUEST_TO_DELETE: 'Try again in a few minutes. If you continue to have problems, call 888-596-3157',
  INVALID_REQUEST_TO_RESET: 'Try again in a few minutes. If you continue to have problems, call 888-596-3157',
  INVALID_UPDATE_STATE_2NDLOAN: 'Try again in a few minutes. If you continue to have problems, call 888-596-3157',
  BAD_REQUEST: 'Try again in a few minutes. If you continue to have problems, call 888-596-3157',
  EMPTY_DIRECT_PAY_ORDERS: 'Try again in a few minutes. If you continue to have problems, call 888-596-3157',
  UNKNOWN_ERROR: '',
  UNQUALIFIED: '',
  VALUATION_ERROR: '',
  WORK_PHONE_TOO_LONG: 'Your work phone is too long',
  RECENT_LC_LOAN:
    'We are sorry, but we are unable to proceed with your application at this time because you recently got a loan with us. After 30 days has passed from your last loan issued, you can try again from your account here <a href="/apply/personal/identity">here</a>. Your information has been saved.',
  PI1_SSN_PRIMARY_REQUIRED:
    'We need your social security number so we may provide you with a loan rate, otherwise, your application will no longer be considered.',
  PI1_SSN_SECONDARY_REQUIRED:
    'We need your social security number so we may provide you with a loan rate, otherwise, your application will no longer be considered.',
  PI1_SSN_PRIMARY_SECONDARY_REQUIRED:
    'We need your social security number so we may provide you with a loan rate, otherwise, your application will no longer be considered.',
  PLAID_ERR:
    'We are having trouble connecting your bank account. Please try again or manually enter your bank details below.',
  PLAID_1016_ERR: 'We are having trouble connecting your bank account. Please try again.',
  INVALID_PLAID_SUB_ACCOUNT:
    'Failed to Link Bank Account. When signing in to your bank please ensure you select only Checking or Savings type accounts to link.',
  PLAID_EXIT_MESSAGES: 'Did something go wrong? Try linking again or manually enter bank details.',
  LOCKER_NOT_AUTHENTICATED: 'We could not verify your identity with the information you provided. Please try again.',
  PENDING_LOAN_APP_EXISTS:
    'You already have a pending loan application with us. We can consider only one loan application at a time.',
  INVALID_OFFER_SELECTED:
    'It appears your offers have changed.<br>Please click <a href="/apply/personal/loan-offers">here</a> to proceed with your application.',
  SESSION_TIMEOUT:
    'Oops! Your session has timed out. Please click <a href="/auth/login?login_url=%2Fapply%2Fpersonal%2Fidentity">here</a> to log back in.',
  INVALID_ORDERS_COUNT:
    "Please specify only up to 12 creditors. For creditors you don't want to pay, set the amount to $0",
  DUPLICATE_CREDITORS_FOUND:
    "We noticed you're trying to make multiple payments to the same creditor. Please remove one of them and try submitting again.",
  AUTH_SESSION_VALIDATION_FAILED: 'Oops! We hit a snag, but your information was saved. Click "Next" to continue.',
  EXCEED_MAX_OUTSTANDING_PRINCIPAL:
    'It looks like you’re close to paying off your existing loan and we may have other loan options for you.',
  CHARGED_OFF_PRIOR_LOAN:
    'Before we can consider your new credit request, we need to speak with you about your existing personal loan.',
  DELINQUENT_CURRENT_LOAN:
    'Before we can consider your new credit request, we need to speak with you about your existing personal loan.',
  ENROLLED_IN_HARDSHIP_PLAN:
    'Before we can consider your new credit request, we need to speak with you about your existing personal loan.',
  INSUFFICIENT_ON_TIME_PAYMENTS_ON_EXISTING_LOANS:
    'Before we can consider your new credit request, we need to speak with you about your existing personal loan.',
};

