import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { useLCContext } from '../PageProvider';
import ShortDisclaimer from './ShortDisclaimer';

import './OffersDisclaimer.scss';
/**
 * Offers Disclaimer component.
 *
 * @param {Object} props props object
 * @param {String=} props.className custom class
 * @param {Boolean=} props.hasDivider flag to show or hide divider
 * @param {Boolean=} props.hasExtraDisclaimer flag to show or hide variant disclaimer copy
 * @returns {JSX.Element} React Component
 */
const OffersDisclaimer = ({ className, hasDivider, hasExtraDisclaimer }) => {
  const lcContext = useLCContext();
  return (
    <div className={classNames('disclaimer', className)} data-qa-id="disclaimer-container">
      {hasDivider && <div className="disclaimer-divider" />}
      {hasExtraDisclaimer && <ShortDisclaimer className="disclaimer-text" />}
      <p className="disclaimer-text">
        All offers and terms are subject to application approval and completion of the loan issuance process. You may
        cancel your application at any time prior to issuance at absolutely no cost to you. There is no down payment,
        and you may repay your loan at any time with no additional costs or pre-payment penalties. An approved loan may
        issue for less than the full requested amount, and loan closing is contingent on your acceptance of all
        applicable agreements and disclosures.
      </p>
      {lcContext.floridaStateDisclaimer && (
        <p className="disclaimer-text">
          Loans issued to Florida residents may be subject to a state documentary stamp tax.
        </p>
      )}
    </div>
  );
};

OffersDisclaimer.propTypes = {
  className: string,
  hasDivider: bool,
  hasExtraDisclaimer: bool,
};

OffersDisclaimer.defaultProps = {
  className: '',
  hasDivider: true,
  hasExtraDisclaimer: false,
};

export default OffersDisclaimer;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');