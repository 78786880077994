/**
 * getJanusCookieName
 * Read the sessionId from janus cookie
 * @return {string} Cookie name
 */
export const getJanusCookieName = () => {
  let janusCookieName = 'janus';
  if (!process.env.NODE_ENV.includes('prod')) janusCookieName = `${janusCookieName}-${process.env.NODE_ENV}`;
  return janusCookieName;
};

