import React from 'react';
import { Accordion, Grid } from '@ui-propulsion/react-components';
import { array, func, string, oneOf, bool } from 'prop-types';
import classnames from 'classnames';

import './FAQ.scss';

/**
 * FAQs component to display the FAQS.
 *
 * @param {Object} props
 * @param {string} props.title - title of the accordion
 * @param {Array=} props.faqs - faqs that is displaying in the accordion
 * @param {Function=} props.onChange - function to pass the selected question.
 * @param {string=} props.alignItems - alignItems flex property
 * @param {boolean=} props.smallerFont - alignItems flex property

 *
 * @returns {JSX.Element} React Element
 *
 * @example
 * return (
 *  <Faqs
 *    title= "Frequently Asked Questions"
 *    faqs = {faqAccordionData}
 *    onChange= () => {}
 *  />)
 */

export function FAQ({ title, faqs, onChange, alignItems, smallerFont }) {
  const trackQuestionClicked = (id, expanded) => {
    const section = faqs.find(element => element.id === id);
    onChange(section, expanded);
  };

  return (
    <Grid container item className="FAQ" alignItems={alignItems} direction="column">
      <Grid item xs className={classnames('FAQ-title', { 'FAQ-title--section': smallerFont })}>
        {title}
      </Grid>
      <Accordion
        onChange={trackQuestionClicked}
        className={classnames('FAQ-content', { 'FAQ-content--section': smallerFont })}
        controlled
        data={faqs}
      />
    </Grid>
  );
}

FAQ.propTypes = {
  alignItems: oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch']),
  faqs: array.isRequired,
  onChange: func,
  smallerFont: bool,
  title: string,
};

FAQ.defaultProps = {
  alignItems: 'center',
  onChange: () => {},
  smallerFont: false,
  title: 'Frequently Asked Questions',
};

