export default {
  MINIMUM_AMOUNT_TO_BORROW: 1000,
  MAXIMUM_AMOUNT_TO_BORROW: 40000,
  COUNTER_OFFER_SECOND_LOAN_CUTOFF_AMOUNT: 20000,
  COUNTER_OFFER_SECOND_LOAN_QUALIFICATION_TIME_LOW: 7,
  COUNTER_OFFER_SECOND_LOAN_QUALIFICATION_TIME_HIGH: 13,
  COUNTER_OFFER_SECOND_LOAN_QUALIFICATION_PAYMENT_LOW: 6,
  COUNTER_OFFER_SECOND_LOAN_QUALIFICATION_PAYMENT_HIGH: 12,
  SECOND_LOAN_BANNER_PARTNER_ID: '87194',
  SECOND_LOAN_EMAIL_PARTNER_ID: '75227',
  SECOND_LOAN_UNITED_REFERRER_ID: '489114',
  LOAN_PRODUCT_IDS: {
    NEAR_PRIME: 1004,
    PLAID_LOAN: 1016,
    COLORADO_PLAID_LOAN: 1021,
  },
  MINIMUM_INCOME_AMOUNT: 10000,
  NEAR_PRIME_FICO_RANGE: [600, 659], //inclusive
};

