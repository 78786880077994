import React from 'react';
import { func, array, bool } from 'prop-types';
import { Accordion } from 'propulsion-rc-v2';
import InformationBox from '@shared_modules/local-propulsion/components/InformationBox';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';
import checkMarkIconSrc from '@shared_modules/common-images/green-circle-check.svg';
import './FAQNote.scss';
import classNames from 'classnames';

const FAQNoteClassName = 'FAQNote';
const CheckMarkIcon = () => (
  <img src={checkMarkIconSrc} className={`${FAQNoteClassName}-checkMarkIcon`} alt="Green checkmark" />
);

const ListItem = ({ children }) => (
  <li>
    <CheckMarkIcon />
    {children}
  </li>
);

const accordionContentDefault = [
  {
    id: FAQNoteClassName,
    header: 'What can I include as total annual income?',
    className: `${FAQNoteClassName}-section`,
    details: (
      <div className={`${FAQNoteClassName}-detailsWrapper`}>
        <p className={`${FAQNoteClassName}-description`}>Some examples of income include the following:</p>
        <ul className={`${FAQNoteClassName}-details`}>
          <ListItem>
            <p>
              <b>Income from employment,</b> including full-time, part-time, freelance, or seasonal jobs
            </p>
          </ListItem>
          <ListItem>
            <p>
              <b>Self-employment,</b> including any business dividends or rental income
            </p>
          </ListItem>
          <ListItem>
            <p>
              <b>Non-taxable income,</b> if included, should be increased by 25%
            </p>
          </ListItem>
          <ListItem>
            <div>
              <p>
                <b>Any other income</b> such as retirement, social security, public assistance, or any non-taxable
                income
              </p>
              <p className="FAQNote-spacing">
                Only include alimony, child support, or separate maintenance if you wish for us to consider it as a
                source of repayment
              </p>
            </div>
          </ListItem>
        </ul>
      </div>
    ),
  },
];

const FAQNote = ({ onChange, accordionContent, opened, wrapped }) => {
  const handleOnChange = e => {
    heapTrackEvent(!opened ? 'Tooltip_Opened' : 'Tooltip_Closed');
    onChange(e, !opened);
  };

  return (
    <div
      className={classNames(`${FAQNoteClassName}`, {
        [`${FAQNoteClassName}--wrapped`]: wrapped,
      })}
    >
      <InformationBox className={`${FAQNoteClassName}-container`}>
        <Accordion
          className={`${FAQNoteClassName}-accordion`}
          expanded={{
            [FAQNoteClassName]: opened,
          }}
          controlled
          onChange={handleOnChange}
          data={accordionContent}
        />
      </InformationBox>
    </div>
  );
};

FAQNote.propTypes = {
  onChange: func,
  accordionContent: array,
  opened: bool,
  wrapped: bool,
};

FAQNote.defaultProps = {
  onChange: () => {},
  accordionContent: accordionContentDefault,
  opened: true,
  wrapped: true,
};

export default FAQNote;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/components/FAQNote/FAQNote.scss');