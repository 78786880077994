import React, { useRef } from 'react';
import { arrayOf, bool, func, node, oneOfType, shape, string } from 'prop-types';
import { Grid } from '@ui-propulsion/react-components';
import { Card } from '@shared_modules/local-propulsion/components';
import CheckList from '@shared_modules/check-list/CheckList';
import { toPercentageTruncZero } from '@shared_modules/offers/client/utils';
import { WhiteTooltipWithCloseTarget } from './CommonTooltips';
import { discountedTooltipInfo } from '@shared_modules/baui-constants';
import { StringTemplate } from '@shared_modules/baui-utils/client/utils';
import checkMark from '@shared_modules/common-images/checkmark-outline-green.svg';

import './DiscountedRateTooltip.scss';

const ROOT_CLASS = 'DiscountedRateTooltip';

//Last Position in subtitleOffers the array should be discounted offer
export const DiscountedRateTooltipOverlay = ({ subtitleOffers, variant }) => {
  const tooltipInfo = discountedTooltipInfo[variant];
  const checkListItems = tooltipInfo.discountList;
  let tooltipDesc = tooltipInfo.content;
  if (variant === 'btRedesign') {
    const [cashOffer, btOffer] = subtitleOffers;
    tooltipDesc = StringTemplate.replace(tooltipInfo.content, {
      duration: btOffer.duration,
      apr: toPercentageTruncZero(btOffer.apr),
      aprCash: toPercentageTruncZero(cashOffer.apr),
    });
  }

  return (
    <div className={`${ROOT_CLASS}-container`}>
      <div className={`${ROOT_CLASS}-offerInfo`}>
        <div className={`${ROOT_CLASS}-offerWrapper`}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Card circled className={`${ROOT_CLASS}-circle`}>
                <Grid container alignItems="center" justifyContent="center" className={`${ROOT_CLASS}-circleWrapper`}>
                  {subtitleOffers.map((offer, i, array) => (
                    <Grid
                      item
                      className={`${ROOT_CLASS}-discountedWrapper--${array.length - i}`}
                      key={`${i}-${offer.id}`}
                    >
                      <span className={`${ROOT_CLASS}-discounted--${array.length - i}`}>
                        {toPercentageTruncZero(offer.apr)}
                      </span>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Grid>
            <Grid item>
              <p className={`${ROOT_CLASS}-offerShown`}>{subtitleOffers[0].duration} mo. APR shown</p>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={`${ROOT_CLASS}-desc`}>
        <h5>{tooltipInfo.title}</h5>
        <p>{tooltipDesc}</p>
        <CheckList items={checkListItems} icon={checkMark} className={`${ROOT_CLASS}-checklist`} />
      </div>
    </div>
  );
};

/**
 * OFee Tooltip component
 * @param {Object} props
 * @param {Boolean=} props.show hide/display tooltip
 * @param {Function=} props.onClose handler to close modal
 * @param {Object} props.cashOffer offer object
 * @param {Object} props.btOffer offer object
 * @param {Object} props.offer offer selected object
 * @param {Object} props.subtitleOffers subtitleOffers selected object
 * @param {String} props.variant variant type control|singleDiscount|MultipleDiscount
 * @param {String} props.name name tooltip
 * @param {Object=} props.customRef custom reference from parent
 * @param {JSX.Element|JSX.Element[]=} props.children Tooltip content to be displayed
 *
 * @returns {JSX.Element} React element
 */
const DiscountedRateTooltip = ({ show, onClose, children, offer, subtitleOffers, variant, customRef, name }) => {
  const tooltipRef = useRef(null);
  return (
    <WhiteTooltipWithCloseTarget
      name={name}
      className={`${ROOT_CLASS}`}
      targetRef={customRef || tooltipRef}
      tabIndex={0}
      onClose={onClose}
      overlayClassName={`${ROOT_CLASS}-overlay`}
      placement="bottom"
      offer={offer}
      visible={show}
      showTooltip={show}
      hasBlackCloseIcon
      destroyTooltipOnHide
      overlay={<DiscountedRateTooltipOverlay {...{ subtitleOffers, variant }} />}
    >
      <div ref={tooltipRef}>{children}</div>
    </WhiteTooltipWithCloseTarget>
  );
};

DiscountedRateTooltip.propTypes = {
  show: bool,
  onClose: func,
  children: oneOfType([arrayOf(node), node]),
  offer: shape({}).isRequired,
  subtitleOffers: shape({}).isRequired,
  variant: string,
};

DiscountedRateTooltip.defaultProps = {
  show: false,
  onClose: () => {},
  variant: 'control',
};

export default DiscountedRateTooltip;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/common-tooltips/CommonTooltips.scss');