import React, { useState, useCallback } from 'react';
import { validate } from 'ui-validator';
import { func, string, bool } from 'prop-types';
import { Button } from 'propulsion-rc-v2';
import PLTextField from '../PLTextField';
import passwordValidations from './password-validations';
import checkBlacklistedService from './check-blacklisted';

const PasswordInput = props => {
  const { blackListHeapIdentifier, checkBlacklisted, email, onBlur, onChange, password, ...otherProps } = props;
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [displayShowHide, setdisplayShowHide] = useState(Boolean(password));
  const [checkBlacklistedState, setCheckBlacklistedState] = useState(false);

  const validateInput = useCallback(
    (fieldName, value) => validate({ [fieldName]: value, email }, passwordValidations),
    [email]
  );

  const onBlurHandler = useCallback(
    async event => {
      if (!checkBlacklistedState) {
        const { name, value } = event.target;
        let validationErrors = validateInput(name, value);
        if (Object.entries(validationErrors).length === 0 && checkBlacklisted) {
          const blacklisted = await checkBlacklistedService(value, blackListHeapIdentifier);
          if (blacklisted) {
            setCheckBlacklistedState(true);
            validationErrors = {
              password: {
                isBlacklisted:
                  'Avoid common words that could be easy to guess and passwords you use with other websites',
              },
            };
          }
        }

        onBlur({ name, validationErrors });
      }
    },
    [checkBlacklistedState, setCheckBlacklistedState, blackListHeapIdentifier, validateInput, checkBlacklisted, onBlur]
  );

  const onChangeHandler = useCallback(
    event => {
      setdisplayShowHide(event.target.value.length > 0);
      setCheckBlacklistedState(false);
      onChange(event);
    },
    [setdisplayShowHide, setCheckBlacklistedState, onChange]
  );

  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility(state => !state);
  }, [setPasswordVisibility]);

  return (
    <PLTextField
      type={passwordVisibility ? 'text' : 'password'}
      inputProps={{ maxLength: 40 }}
      defaultValue={password}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      componentProps={{
        endAdornment: (
          <Button
            variant="tertiary"
            position="end"
            style={{
              cursor: 'pointer',
              minWidth: '0px',
            }}
            disablePointerEvents={false}
            onClick={togglePasswordVisibility}
          >
            {displayShowHide && (passwordVisibility ? 'HIDE' : 'SHOW')}
          </Button>
        ),
      }}
      helperText="Must be at least 8 characters, with at least 1 number."
      placeholder="Create password"
      label="Password"
      {...otherProps}
    />
  );
};

PasswordInput.propTypes = {
  ...PLTextField.propTypes,
  blackListHeapIdentifier: string,
  checkBlacklisted: bool,
  email: string,
  name: string,
  onBlur: func,
  onChange: func.isRequired,
  password: string,
};

PasswordInput.defaultProps = {
  blackListHeapIdentifier: null,
  checkBlacklisted: true,
  email: '',
  name: 'password',
  onBlur: () => {},
  onFocus: () => {},
  password: '',
};

export default PasswordInput;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');