const COMMON_BULLETS = [
  'You can pay your debt in full or a portion of it. You’ll choose which accounts to pay down on the next page.',
  'We’ll pay your creditors directly after your loan is issued. LendingClub does not charge processing fees for these transactions.',
  'We’ll subtract your origination fee and any money used to pay your creditors, and the leftover cash will be deposited into your bank account.',
];

export const REDESIGN_BT_TOOLTIP_CONTENT = {
  control: [
    'Use {{loanText}} (or more) of this loan to pay down existing debt.',
    'Choose which creditors to pay and how much to pay each creditor on the next page.',
    'We will transfer the money directly to your creditors. This is a free service.',
    'Any remaining cash will be deposited directly into your bank account.',
  ],
  btRedesign: [
    'To get the discounted rate, you must use at least {{loanText}} to pay down your debt directly through LendingClub.',
    ...COMMON_BULLETS,
  ],
  disparity: [
    'You must use at least {{loanText}} to pay down your debt directly through LendingClub.',
    ...COMMON_BULLETS,
  ],
};

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/common-tooltips/CommonTooltips.scss');