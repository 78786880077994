import axios from 'lc-http';
import { trackHeapEvent } from '../../../custom-heap-tracking-attributes/custom-heap-tracking-attributes';

const checkBlacklisted = (password, heapEventIdentifier) =>
  axios({
    method: 'POST',
    url: '/apply/personal/identity/check-blacklisted-password',
    data: {
      password,
    },
  })
    .then(response => {
      const { data } = response;
      if (data.errors && data.errors[0] === 'BLACKLISTED_PASSWORD') {
        if (heapEventIdentifier) trackHeapEvent(`${heapEventIdentifier}_Blacklisted_Password_Attempt`);
        return true;
      }
      return false;
    })
    .catch(() => {
      if (heapEventIdentifier) trackHeapEvent(`${heapEventIdentifier}_Check_Backlisted_Password_Attempt_Failed`);
      return false; // return false so we don't block the user if we can't check for blacklisted pw
    });

export default checkBlacklisted;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');