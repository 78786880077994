import React, { useEffect, useRef } from 'react';

/**
 * useDidMountEffect hook
 *
 * hook to run or simulate componentDidMount so we are verifying first render with an state
 * and we avoid call fn in the first iteration
 *
 * @param {Function} effect function effect
 * @param {Array=} deps dependencies array
 * @returns {void}
 */
const useDidMountEffect = (effect, deps) => {
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      const unmount = effect();
      return () => {
        mounted.current = false;
        unmount && unmount();
      };
    }
    mounted.current = true;
  }, deps);
};

export default useDidMountEffect;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');