import { validators as v } from 'ui-validator';

const isSameAsEmail = (value, key, attributes) => {
  const email = attributes.email;
  if (email !== '' && value === email) {
    return 'Your password must not match your login email';
  }
};

export default {
  password: {
    required: v.required({ message: 'A password is required' }),
    validPassword: v.validPassword(),
    suspicious: v.suspicious(),
    isSameAsEmail,
  },
};

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');