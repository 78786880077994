import React from 'react';
import { Grid, InputLabel } from 'propulsion-rc-v2';
import { Checkbox } from '@shared_modules/local-propulsion/components';
import { bool, func, shape, string } from 'prop-types';
import useAddressInput from '../../hooks/useAddressInput';
import './styles.scss';

const CoBorrowerAddress = ({
  inputs: { addressSecondary, secondaryUsePrimaryAddress },
  validationErrors,
  warningMessages,
  onChange,
  showHelperText,
  primaryAddress,
  showLabel,
}) => {
  const { AutocompleteAddress, handleOnBlur, updateAddress, onFocus, clearValidationErrors } = useAddressInput({
    ...addressSecondary,
    showHelperText,
    validationErrors,
    warningMessages,
  });

  return (
    <Grid item xs={12} className="CoBorrowerPIForm-paddingTop CoBorrowerPIForm-borderTop">
      <div className="CoBorrowerPIForm-checkbox">
        {showLabel && (
          <InputLabel required className="CoBorrowerPIForm-inputLabel">
            Home Address
          </InputLabel>
        )}
        <Checkbox
          className="CoBorrowerPIForm-addressCheckbox"
          checked={secondaryUsePrimaryAddress.checked}
          onChange={secondaryUsePrimaryAddress.onChange}
          name="secondaryUsePrimaryAddress"
        >
          We share the same address
        </Checkbox>
      </div>
      {secondaryUsePrimaryAddress.checked ? (
        <div className="CoBorrowerPIForm-addressSection">
          <label className="CoBorrowerPIForm-addressLabel">Home Address (Mailing Address)</label>
          <div className="CoBorrowerPIForm-addressValue">
            {primaryAddress.street} <br />
            {primaryAddress.city}, {primaryAddress.state} {primaryAddress.zip}
          </div>
        </div>
      ) : (
        <AutocompleteAddress
          address={addressSecondary.defaultValue || {}}
          clearValidationErrors={clearValidationErrors}
          handleBlur={handleOnBlur}
          handleFocus={onFocus}
          handleInputChange={onChange}
          inputNames={addressSecondary.inputNames}
          showHelperText={showHelperText}
          labels={{ street: 'Street Address' }}
          updateAddress={updateAddress}
          validationErrors={validationErrors}
          warningMessages={warningMessages}
        />
      )}
    </Grid>
  );
};

CoBorrowerAddress.propTypes = {
  primaryAddress: shape({
    street: string.isRequired,
    city: string.isRequired,
    state: string.isRequired,
    zip: string.isRequired,
  }).isRequired,
  inputs: shape({
    addressSecondary: shape({
      defaultValue: shape({}),
      handleInputChange: func,
      updateAddress: func,
      clearValidationErrors: func,
      handleBlur: func,
      handleFocus: func,
    }),
    secondaryUsePrimaryAddress: shape({
      defaultValue: string,
      checked: bool,
      onChange: func,
    }),
  }),
  validationErrors: shape({}),
  warningMessages: shape({}),
  showHelperText: shape({}),
  onChange: func,
};

CoBorrowerAddress.defaultProps = {
  inputs: {
    addressSecondary: {
      handleInputChange: () => {},
      updateAddress: () => {},
      clearValidationErrors: () => {},
      handleBlur: () => {},
      handleFocus: () => {},
    },
    secondaryUsePrimaryAddress: {
      onChange: () => {},
      checked: true,
    },
  },
  validationErrors: {},
  warningMessages: {},
  showHelperText: {},
  onChange: () => {},
};

export default CoBorrowerAddress;

