import { heapTrackEvent } from '../../../../shared_modules/baui-heap-tracking';
import React from 'react';

export const disableAutoScroll = history => {
  if ('scrollRestoration' in history) {
    try {
      history.scrollRestoration = 'manual';
    } catch (e) {
      /* do nothing */
    }
  }
};

/**
 * StringTemplate Util Calls
 * @class
 * @description StringTemplate for string manipulations.
 */
export class StringTemplate {
  /**
   * replace "{{key}} value in any string with dynamic content".
   * @param {String} str Description of name.
   * @param {Object} keys key to be replaced.
   * @param {Array.<String>} delimiter Description of factoryThisObj.
   */
  static replace(str, keys, delimiter = ['{{', '}}']) {
    Object.keys(keys).forEach(key => {
      const regexp = new RegExp(delimiter[0] + key + delimiter[1], 'g');
      const value = keys[key];
      str = str.replace(regexp, value);
    });
    return str;
  }
}

export const wait = ms => new Promise(r => setTimeout(r, ms));

export const changeBTRedesignQuestion = (redesignFaqs, btMinimumAmountValue) => {
  const question = redesignFaqs.find(question => question.id === 'whatAreTheRequirementsToGetDiscountedRate');

  if (question && typeof question.details === 'string') {
    question.details = (
      <div>
        <p>{StringTemplate.replace(question.details, { btMinimumAmountValue })}</p>
      </div>
    );
  }

  return redesignFaqs;
};

export const trackFAQEvent = ({ eventName = 'PL_BT_FAQ_Tracking', faqItem, expanded, faqLocation }) => {
  if (expanded) {
    heapTrackEvent(eventName, {
      ACTION: 'question_opened',
      BT_FAQ_QUESTION_TEXT: faqItem.header,
      BT_FAQ_PAGE_LOCATION: faqLocation,
    });
  }
};

export const getBtMktLoanPurposeXpStr = (experiments = {}) => {
  const EXPERIMENT_KEY = 'PL_BT_MKT_PAGE_AND_LOAN_PURPOSE';
  const variant = experiments[EXPERIMENT_KEY];
  return variant ? `${EXPERIMENT_KEY}__${variant}` : '';
};

export default {
  changeBTRedesignQuestion,
  disableAutoScroll,
  getBtMktLoanPurposeXpStr,
  StringTemplate,
  wait,
};

