import { useState, useRef } from 'react';
import { validate as validator } from 'ui-validator';
import { heapTrackErrors } from '@shared_modules/baui-heap-tracking';
import deepEqual from 'deep-equal';

/**
 * useForm Hook
 *
 * handles all validating, creates validation errors and
 * process submit event handling validations
 *
 * @component
 * @example
 * const {
 *  handleSubmit,
 *  onChange,
 *  onBlur,
 *  model,
 *  validationErrors,
 *  setValidationErrors
 * } = useForm({ defaultValues, validations, transform, customValidationFunction });
 *
 * @param   {object}          props                props Object
 * @param   {object=}         props.defaultValues  object with default values to init form model
 * @param   {object=}         props.validations    validations object with all validations model
 * @param   {object=}         props.transform  transform object to process value after onChange event
 * @param   {function=}       props.customValidationFunction  function that replace the validation function in case that is passed
 * @param   {boolean=}        props.trackModelChanges verify each input change against defaultValues
 *
 * @return  {object}  object with all options
 */
const useForm = ({
  defaultValues = {},
  validations = {},
  transform = {},
  customValidationFunction,
  trackModelChanges = false,
}) => {
  const [model, setModel] = useState(defaultValues);
  const [validationErrors, setValidationErrors] = useState({});
  const trackValuesRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const validationFunction = customValidationFunction && customValidationFunction({ model, validator, validations });

  const clearValidations = () => setValidationErrors({});

  const onChange = ({ target: { name, value } }) => {
    if (name) {
      setModel(prevModel => ({
        ...prevModel,
        [name]: transform[name] ? transform[name](value) : value,
      }));
    }
  };

  const trackChanges = name => {
    const trackInputs = Object.keys(model).reduce((acc, curr) => {
      acc[curr] = model[curr] !== defaultValues[curr];
      return acc;
    }, {});
    trackValuesRef.current = trackInputs;
  };

  const getErrors = fieldName => {
    let validators = validations;
    if (fieldName) validators = { [fieldName]: validations[fieldName] || true };
    return validator(model, validators);
  };

  const validateInput = fieldName => {
    let errors = customValidationFunction ? validationFunction(fieldName) : getErrors(fieldName);

    if (fieldName) {
      // extracting out other errors than error for the current field
      const { [fieldName]: omit, ...restOfValidationErrors } = validationErrors;
      const mergedErrors = {
        ...restOfValidationErrors,
        ...errors,
      };
      errors = mergedErrors;
    }

    setValidationErrors(errors);
    return errors;
  };

  const onBlur = ({ target: { name } }) => {
    if (isSubmitting) return; // avoid doing the validation twice
    validateInput(name);
    if (trackModelChanges) {
      trackChanges(name);
    }
    setHasChanges(!deepEqual(defaultValues, model));
  };

  const createSubmitHandler =
    (onSubmitSuccess = model => {}, onSubmitError = e => {}, beforeValidateInSubmit = model => {}) =>
    e => {
      setIsSubmitting(true);
      if (e) e.preventDefault();
      beforeValidateInSubmit(model);
      const errors = validateInput();
      setValidationErrors(errors);
      setIsSubmitting(false);
      if (Object.keys(errors).length > 0) {
        heapTrackErrors(errors);
        onSubmitError(errors);
      } else {
        onSubmitSuccess(model);
      }
    };

  if (trackModelChanges) {
    trackChanges();
  }

  return {
    model,
    validationErrors,
    setValidationErrors,
    clearValidations,
    setModel,
    onChange,
    onBlur,
    createSubmitHandler,
    validateInput,
    hasChanges,
    setHasChanges,
    trackValues: trackValuesRef.current,
  };
};

export default useForm;

