import React from 'react';
import { node, string } from 'prop-types';
import { Grid, Card } from 'propulsion-rc-v2';
import classNames from 'classnames';
import './style.scss';

/**
 * InformationBox component for wrap co-borrower information.
 *
 * @component
 * @example
 * return (<InformationBox><Component /></InformationBox>);
 *
 * @param   {object}          props           props Object
 * @param   {string}          props.title     title information in the card
 * @param   {string}          props.className className to allow style override
 * @param   {JSX.Element=}    props.children  content of InformationBox you want to wrap
 * @return  {JSX.Element}     React component
 */
const InformationBox = ({ className, title, children }) => (
  <Card className={classNames('InformationBox', className)}>
    <Grid container className="InformationBox-container">
      <Grid item xs={12} className="InformationBox-wrapper">
        {title && <h2 className="InformationBox-title">{title}</h2>}
        <div className="InformationBox-content">{children}</div>
      </Grid>
    </Grid>
  </Card>
);

InformationBox.propTypes = {
  title: string,
  children: node,
  className: string,
};

InformationBox.defaultProps = {
  className: '',
  title: '',
};

export default InformationBox;

