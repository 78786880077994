import React from 'react';
import { string, shape, func, bool, number, oneOfType } from 'prop-types';
import loanProperties from '@shared_modules/common-properties/loanProperties';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';
import { formatters as format } from 'ui-formatter';
import { CurrencyInput } from '@shared_modules/local-propulsion/components';
import {
  MONTHLY_PAYMENT_OPTIONAL,
  getMinAndMaxLoanAmountRange,
  isMonthlyPaymentType,
  isValidLoanAmount,
} from '../utils';
import BTLoanAmountPurpose from './BTLoanAmountPurpose';
import { VARIANT_TYPES } from '@shared_modules/baui-constants';

const FormInput = ({ children }) => <div className="form__input">{children}</div>;
const Input = ({ children, show }) => {
  if (show) return <FormInput>{children}</FormInput>;
  return null;
};

const AmountPurposeFields = ({
  btVariantType,
  disabled,
  dm,
  handleBlur,
  handleInputChange,
  hideEmptyMonthlyPayment,
  loanAmount,
  loanPurpose,
  monthlyPayment,
  showLoanAmount = true,
  showLoanPurpose = true,
  showMonthlyPayment = false,
  validationErrors,
  variant,
}) => {
  let minRangeValue;
  let maxRangeValue;
  let validLoanAmount;

  const isMonthlyPaymentActive = isMonthlyPaymentType(variant);
  const shouldCalculateMonthlyPaymentRange = !!(isMonthlyPaymentActive && loanAmount);
  const showEmptyMonthlyPayment = hideEmptyMonthlyPayment
    ? !!monthlyPayment
    : isMonthlyPaymentActive || showMonthlyPayment;

  if (shouldCalculateMonthlyPaymentRange) {
    const { minAmount, maxAmount } = getMinAndMaxLoanAmountRange(loanAmount);
    minRangeValue = minAmount;
    maxRangeValue = maxAmount;
    validLoanAmount = isValidLoanAmount(loanAmount);
  }

  const handleBlurMonthlyPayment = e => {
    if (shouldCalculateMonthlyPaymentRange && monthlyPayment) {
      heapTrackEvent('PL_Payment_Input_By_User', {
        LOAN_AMOUNT: loanAmount,
        PAYMENT_MIN_RANGE: minRangeValue,
        PAYMENT_MAX_RANGE: maxRangeValue,
        PAYMENT: monthlyPayment,
      });
    }
    handleBlur(e);
  };

  const getHelperText = key => {
    switch (key) {
      case 'monthlyPayment':
        return `Enter your desired monthly payment amount for your ${format.toMoney(
          loanAmount.toString()
        )} loan request`;
      default:
        return `Enter an amount between ${format.toMoney(
          loanProperties.MINIMUM_AMOUNT_TO_BORROW.toString()
        )} and ${format.toMoney(loanProperties.MAXIMUM_AMOUNT_TO_BORROW.toString())}`;
    }
  };

  return (
    <>
      <Input show={showLoanAmount}>
        <CurrencyInput
          name="loanAmount"
          label="Loan Amount"
          defaultValue={isNaN(loanAmount) ? loanAmount : loanAmount.toString()}
          onBlur={handleBlur}
          onChange={handleInputChange}
          errors={validationErrors.loanAmount}
          helperText={getHelperText()}
          aria-invalid={Object.keys(validationErrors).length > 0 && !!validationErrors.loanAmount}
          autoFocus={!loanAmount}
          disabled={disabled}
          helperTextVisible
        />
      </Input>
      <Input show={showLoanPurpose}>
        <BTLoanAmountPurpose
          defaultValue={loanPurpose}
          disabled={disabled}
          isDm={dm.isDm && dm.isBtaDmReferrer}
          onBlur={handleBlur}
          onChange={handleInputChange}
          variantType={btVariantType}
          validationErrors={validationErrors}
        />
      </Input>
      {showEmptyMonthlyPayment && (
        <Input show={isMonthlyPaymentActive || showMonthlyPayment}>
          <CurrencyInput
            name="monthlyPayment"
            label="Desired Monthly Payment"
            onBlur={handleBlurMonthlyPayment}
            onChange={handleInputChange}
            defaultValue={monthlyPayment}
            errors={validationErrors.monthlyPayment}
            helperText={validLoanAmount ? getHelperText('monthlyPayment') : ''}
            aria-invalid={!!validationErrors.monthlyPayment}
            autoFocus={!!(loanAmount && loanPurpose)}
            disabled={disabled}
            isRequired={variant !== MONTHLY_PAYMENT_OPTIONAL}
          />
        </Input>
      )}
    </>
  );
};

AmountPurposeFields.propTypes = {
  btVariantType: string,
  disabled: bool,
  dm: shape({
    isDm: bool,
    isBtaDmReferrer: bool,
  }),
  handleBlur: func.isRequired,
  handleInputChange: func.isRequired,
  hideEmptyMonthlyPayment: bool,
  loanAmount: oneOfType([string, number]).isRequired,
  loanPurpose: string.isRequired,
};

AmountPurposeFields.defaultProps = {
  btVariantType: VARIANT_TYPES.Control,
  disabled: false,
  dm: {
    isDm: false,
    isBtaDmReferrer: false,
  },
  hideEmptyMonthlyPayment: false,
};

export default AmountPurposeFields;

