import React from 'react';
import { string, bool, func, shape } from 'prop-types';
import { Select, InputLabel } from 'propulsion-rc-v2';
import classnames from 'classnames';
import { PLTextField } from '..';
import './PhoneNumberInput.scss';

const PhoneNumberInput = ({
  classes,
  defaultValue,
  label,
  onBlur,
  onFocus,
  onChange,
  onPhoneTypeChange,
  name,
  phoneType,
  showType,
  validationErrors,
  ...otherProps
}) => {
  const options = ['Mobile', 'Home'].map(currentValue => (
    <option key={currentValue} value={currentValue.toUpperCase()}>
      {currentValue}
    </option>
  ));
  const containerClasses = classnames('PhoneNumber-container', classes);

  return (
    <div className="PhoneNumber">
      {showType && (
        <InputLabel htmlFor="phoneNumber" error={!!validationErrors[name]} required>
          {label}
        </InputLabel>
      )}
      <div className={containerClasses}>
        {showType && (
          <Select
            name="phoneType"
            onBlur={onPhoneTypeChange}
            onChange={onPhoneTypeChange}
            className="PhoneNumber-type"
            value={phoneType}
            inputProps={{
              className: 'PhoneNumber-typeSelect',
              'data-nid-target': 'phoneType',
              'data-analytics': 'phoneType',
              'data-id': 'phoneType',
              'data-qa-id': 'phoneType',
              'aria-label': 'Phone Type',
            }}
          >
            {options}
          </Select>
        )}
        <PLTextField
          id="phoneNumber"
          name={name}
          className="PhoneNumber-input"
          type="tel" // For browsers that do not support inputmode prop
          defaultValue={defaultValue}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          component="Phone"
          placeholder="(###) ###-####"
          label={showType ? '' : label}
          errors={validationErrors[name]}
          {...otherProps}
        />
      </div>
    </div>
  );
};

PhoneNumberInput.propTypes = {
  classes: string,
  defaultValue: string,
  label: string.isRequired,
  name: string.isRequired,
  onBlur: func,
  onFocus: func,
  onChange: func.isRequired,
  onPhoneTypeChange: func,
  phoneType: string,
  showType: bool,
  validationErrors: shape({}),
};

PhoneNumberInput.defaultProps = {
  defaultValue: '',
  classes: '',
  onBlur: () => null,
  onFocus: () => null,
  onPhoneTypeChange: () => null,
  phoneType: '',
  showType: false,
  validationErrors: {},
};

export default PhoneNumberInput;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');