import React, { useRef } from 'react';
import classNames from 'classnames';
import { useClickOutside } from '@shared_modules/local-propulsion/hooks';

import './WithCloseButton.scss';

const ClickOutside = ({ tooltipRef, onClickOutside }) => {
  useClickOutside(tooltipRef, onClickOutside);
  return null;
};

const WithCloseButton =
  TooltipComponent =>
  ({ closeClickOutside, onKeyPress, onClose, overlay, children, ...props }) => {
    const tooltipWrapperRef = useRef(null);
    return (
      <TooltipComponent
        {...props}
        overlay={
          <div tabIndex={0} className="TooltipWithCloseButton" ref={tooltipWrapperRef}>
            {closeClickOutside && (
              <ClickOutside
                tooltipRef={tooltipWrapperRef}
                onClickOutside={e => {
                  onClose(e, { trigger: 'outside' });
                }}
              />
            )}
            <div
              role="button"
              tabIndex={0}
              onClick={e => onClose(e, { trigger: 'close button' })}
              onKeyPress={onKeyPress}
              id={props.id}
              className={classNames('TooltipWithCloseButton-closeIcon', {
                'TooltipWithCloseButton-closeIcon--blackCloseIcon': props.hasBlackCloseIcon,
              })}
              aria-label="Tooltip close icon"
            />
            {overlay}
          </div>
        }
      >
        {children}
      </TooltipComponent>
    );
  };

export default WithCloseButton;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/common-tooltips/CommonTooltips.scss');