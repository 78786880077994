import { useCallback } from 'react';
import classNames from 'classnames';

const globalKey = 'lc';

const addPrefix = (pre, className) => {
  if (!pre || !className) return '';

  if (Array.isArray(className)) {
    return classNames(className.filter(name => !!name).map(name => `${pre}-${name}`));
  }

  return `${pre}-${className}`;
};

const getLastClassName = (classes, useCustomClass) => {
  if (useCustomClass) return useCustomClass;
  const names = classes.split(' ');
  return names[names.length - 1];
};

const useClassNames = (str, useGlobalKey = true) => {
  const componentName = useGlobalKey ? addPrefix(globalKey, str) : str;

  const prefix = useCallback(
    (...classes) => {
      const mergeClasses = classes.length
        ? classNames(...classes)
            .split(' ')
            .map(className => addPrefix(componentName, className))
        : [];

      return mergeClasses.filter(cls => cls).join(' ');
    },
    [componentName]
  );

  const withClassPrefix = useCallback(
    (...classes) => {
      const mergeClasses = prefix(classes);
      return mergeClasses ? `${componentName} ${mergeClasses}` : componentName;
    },
    [componentName, prefix]
  );

  return {
    prefix: addPrefix,
    withClassPrefix,
    merge: classNames,
    getLastClassName,
    componentName,
  };
};

export default useClassNames;

