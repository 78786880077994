import React, { useCallback } from 'react';
import { validate as validator } from 'ui-validator';
import { func, string, bool, shape } from 'prop-types';
import { PLTextField } from '../';
import emailValidations from './email-validations';

const EmailInput = ({
  captureEmail,
  checkEmail,
  defaultValue,
  firstName,
  isCoApplicant,
  isJoint,
  name,
  onBlur,
  onChange,
  primaryEmail,
  validationErrors,
  helperText,
  ...otherProps
}) => {
  const getHelperText = useCallback(() => {
    if (helperText) return helperText;
    if (!isJoint) return '';

    return isCoApplicant
      ? `We'll use this email to send ${firstName || 'your co-borrower'} confirmation of your joint application.`
      : 'You’ll use this email to log in to your account and see your loan details.';
  }, [isJoint, isCoApplicant, firstName]);

  const validateInput = useCallback(
    (fieldName, value) => validator({ [fieldName]: value, primaryEmail, firstName }, emailValidations(isJoint)),
    [primaryEmail, firstName, isJoint]
  );

  const onBlurHandler = useCallback(
    event => {
      const { value } = event.target;
      event.target.value = value && value.trim();
      event.target.validationErrors = validateInput(event.target.name, event.target.value);

      if (!event.target.validationErrors.email) {
        captureEmail(event.target.value);
        checkEmail(event.target.value);
      }

      onBlur(event);
    },
    [validateInput, captureEmail, checkEmail, onBlur]
  );

  const onChangeHandler = useCallback(
    event => {
      const { value } = event.target;
      event.target.value = value && value.toLowerCase();
      onChange(event);
    },
    [onChange]
  );

  return (
    <PLTextField
      autoFocus={isCoApplicant ? false : !defaultValue}
      defaultValue={defaultValue}
      errors={validationErrors[name]}
      helperText={getHelperText()}
      inputProps={{
        maxLength: 50,
      }}
      name={name}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      type="email"
      {...otherProps}
    />
  );
};

EmailInput.propTypes = {
  captureEmail: func.isRequired,
  checkEmail: func,
  defaultValue: string,
  disabled: bool,
  firstName: string.isRequired,
  isCoApplicant: bool,
  isJoint: bool.isRequired,
  label: string,
  name: string,
  onBlur: func.isRequired,
  onFocus: func,
  onChange: func.isRequired,
  placeholder: string,
  primaryEmail: string,
  validationErrors: shape({}),
  helperText: string,
};

EmailInput.defaultProps = {
  checkEmail: () => {},
  defaultValue: '',
  disabled: false,
  isCoApplicant: false,
  label: 'Email',
  name: 'email',
  onFocus: () => {},
  placeholder: 'your@email.com',
  primaryEmail: '',
  validationErrors: {},
  helperText: '',
};

export default EmailInput;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/core/styles.scss');