import React, { useContext } from 'react';
import { Button as PLButton } from 'propulsion-rc-v2';
import { string } from 'prop-types';
import { PageIdContext } from '../PageIdProvider';

const Button = ({ pageId = useContext(PageIdContext), ...props }) => {
  const id = pageId ? `${pageId}-${props.name}` : props.name;
  const dataProps = {
    'data-id': id,
    'data-analytics': id,
    'data-qa-id': id,
  };

  return (
    <PLButton
      {...dataProps}
      {...props}
      onClick={e => {
        e?.target?.focus?.();
        props.onClick?.(e);
      }}
      onMouseDown={e => {
        e?.preventDefault?.();
        props.onMouseDown?.(e);
      }}
    />
  );
};

Button.propTypes = {
  ...PLButton.propTypes,
  name: string.isRequired,
  pageId: string,
};

Button.defaultProps = {
  pageId: undefined,
};

export default Button;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/components/Button/Button.scss');