import axios from 'lc-http';

class SmartyStreetsService {
  constructor(config) {
    this.authId = config.authId;
    this.suggestions = config.suggestions;
    this.supportedStates = config.supportedStates || [];
    this.suggestionURL = config.suggestionURL;
    this.detailURL = config.detailURL;
  }

  getSuggestions = input =>
    axios({
      url: this.suggestionURL,
      params: {
        'auth-id': this.authId,
        suggestions: this.suggestions,
        state_filter: this.supportedStates.join(','),
        prefix: input,
      },
      useCsrf: false,
    });

  getDetail = input =>
    axios({
      url: this.detailURL,
      params: {
        'auth-id': this.authId,
        street: input,
      },
      useCsrf: false,
    });
}

export default SmartyStreetsService;

