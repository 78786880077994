/**
 * @function isPOBox - Returns true if a street address likely contains a P.O. Box
 * @param {string} streetAddress
 * @returns {boolean}
 */
export const isPOBox = streetAddress => {
  const isPO =
    /^.*((((p|(p[\s\.]))(o|(o\s))[\.]?)\s?)|(post\s?office\s?))((box|bin|b[\.\s])?\s?(num|number|#)?\s?\d+)/gim;
  return isPO.test(streetAddress);
};

// just address 1, address 2 comparison
const areAddressesTheSame = (primaryAddress, secondaryAddress) =>
  primaryAddress.streetAddress.trim() === secondaryAddress.streetAddress.trim() &&
  primaryAddress.city.trim() === secondaryAddress.city.trim() &&
  primaryAddress.state.trim() === secondaryAddress.state.trim() &&
  primaryAddress.zip.trim() === secondaryAddress.zip.trim();

export const initAddressProps = ({ isSecondary, sharedAddress, address, isRMEJA }) => {
  if (isSecondary && address.secondaryUsePrimaryAddress) return sharedAddress;
  if (isRMEJA && isSecondary && areAddressesTheSame(sharedAddress, address)) address.secondaryUsePrimaryAddress = true;
  return address;
};

// only show PO warning message to secondary borrower if they dont share an address
export const isPO = ({ isSecondary, address: { streetAddress, secondaryUsePrimaryAddress } }) =>
  !!streetAddress.length && isPOBox(streetAddress) && (isSecondary ? !secondaryUsePrimaryAddress : true);

export const PO_WARNING_TEXT = 'Please make sure you are entering your home address, not a P.O. box.';

