export const prescreenStatus = {
  SESSION_MISMATCH: 'SESSION_MISMATCH',
  CLOSED: 'CLOSED',
  EXPIRED: 'EXPIRED',
  INACTIVE: 'INACTIVE',
  OPEN: 'OPEN',
  MALFORMED: 'MALFORMED',
};

export const prescreenType = {
  ILOC: 'CLEANSWEEP',
  LBB: 'LBB',
  PL2BT: 'PL2BT',
  PL_PRE_APPROVAL: 'PL_PRE_APPROVAL',
  TOP_UP: 'TOP_UP',
};

export const PSS_PAYLOAD_CATEGORIES = {
  MARKETING_OFFER: 'marketing_offer',
};

export const pssErrors = {
  PSSTYPE_MISMATCH: 'PSSTYPE_MISMATCH',
  PSSID_MISMATCH: 'PSSID_MISMATCH',
  USER_INELIGIBLE: 'USER_INELIGIBLE',
  CLOSED: 'TOKEN_USED',
  EXPIRED: 'TOKEN_EXPIRED',
  MALFORMED: 'MALFORMED',
  TOKEN_NOT_FOUND: 'TOKEN_NOT_FOUND',
  SESSION_MISMATCH: 'SESSION_MISMATCH',
};

export const preApprovedErrorStatuses = {
  ...prescreenStatus,
  TOKEN_NOT_FOUND: 'TOKEN_NOT_FOUND',
  APP_IN_PROG_RETURN: 'APP_IN_PROG_RETURN',
  APP_IN_PROG_OTHER: 'APP_IN_PROG_OTHER',
};

export const errorRedirectMap = {
  CLEANSWEEP_IDENTITY: 'CLEANSWEEP',
  TOPUP_IDENTITY: 'TOPUP',
};

export const TOPUP_SOURCES = {
  PL2PL_TOPUP: 'PL2PL_TOPUP',
  PL_PRE_APPROVAL_TOPUP: 'PL_PRE_APPROVAL_TOPUP',
  PL2PL_MB_TOPUP: 'PL2PL_MB_TOPUP',
  PL2PL_CP_TOPUP: 'PL2PL_CP_TOPUP',
};

export const fsStateToPrescreenTypeMap = {
  CLEANSWEEP: ['CLEANSWEEP'],
  CLEANSWEEP_IDENTITY: ['CLEANSWEEP'],
  TOPUP: [
    TOPUP_SOURCES.PL2PL_TOPUP,
    TOPUP_SOURCES.PL_PRE_APPROVAL_TOPUP,
    TOPUP_SOURCES.PL2PL_MB_TOPUP,
    TOPUP_SOURCES.PL2PL_CP_TOPUP,
  ],
  TOPUP_IDENTITY: [
    TOPUP_SOURCES.PL2PL_TOPUP,
    TOPUP_SOURCES.PL_PRE_APPROVAL_TOPUP,
    TOPUP_SOURCES.PL2PL_MB_TOPUP,
    TOPUP_SOURCES.PL2PL_CP_TOPUP,
  ],
};

export const SOURCE_TYPE_MAP = {
  PL2PL_TOPUP: prescreenType.TOP_UP,
  PL_PRE_APPROVAL_TOPUP: prescreenType.TOP_UP,
  PL2PL_CP_TOPUP: prescreenType.TOP_UP,
  PL2PL_MB_TOPUP: prescreenType.TOP_UP,
};

export const isMatchingPrescreenType = (currentStateFromUrl, pssType) => {
  return (fsStateToPrescreenTypeMap[currentStateFromUrl] || []).includes(pssType);
};

const payloadsStrategies = {
  PL2PL_TOPUP: 'PL2PL_TOPUP_MKTG_OFFER',
  PL2PL_CP_TOPUP: 'PL2PL_TOPUP_MKTG_OFFER',
  PL2PL_MB_TOPUP: 'PL2PL_TOPUP_MKTG_OFFER',
  PL_PRE_APPROVAL_TOPUP: 'PL_PA_TOPUP_MKTG_OFFER',
};

const getTopUpPayload = (payloads, payloadName) => {
  const payloadStrategy = payloadsStrategies[payloadName];
  if (payloads) {
    return payloads[`${payloadStrategy}|1`] || {};
  }
  return {};
};

export const getPSSContext = prescreenContext => {
  switch (prescreenContext.tokenType) {
    case prescreenType.TOP_UP:
      const currentPayload = getTopUpPayload(prescreenContext.payloads, prescreenContext.type) || {};
      const { minTopUpAmount, maxTopUpAmount } = currentPayload.payload || {};
      return {
        minCashAmount: minTopUpAmount,
        maxCashAmount: maxTopUpAmount,
      };
    default:
      return {};
  }
};

const mustHavePSSElements = {
  PL2PL_TOPUP: ['minCashAmount', 'maxCashAmount'],
  PL2PL_CP_TOPUP: ['minCashAmount', 'maxCashAmount'],
  PL2PL_MB_TOPUP: ['minCashAmount', 'maxCashAmount'],
  PL_PRE_APPROVAL_TOPUP: ['minCashAmount', 'maxCashAmount'],
};

export const isMalformedPSSEntry = (prescreenContext, type) => {
  // no must-have elements to check, get out
  if (!mustHavePSSElements[type]) return false;

  const typeSpecificContext = getPSSContext(prescreenContext);
  // make sure that PSS type specific elements are present in PSS entry
  const hasAllKeys = mustHavePSSElements[type].every(
    item => typeSpecificContext.hasOwnProperty(item) && typeSpecificContext[item] !== undefined
  );
  return !hasAllKeys;
};

