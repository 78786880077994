const differenceInSeconds = require('date-fns/difference_in_seconds');
const { getLoanAmount, getOfferSetPayloadMapped } = require('../offers/client/utils');
const objectPath = require('object-path');
const { COUPON, DISCOUNT_TYPES, REPEATS, DISC_LEVEL, PA_V1_NON_DISCOUNT } = require('../baui-constants');
const { getTrackingDataParams } = require('../tracking-data-params/tracking-data-params');

const getOfferTrackingAttributes = (loanApp, offers, features = {}) => {
  if (!loanApp || !offers) {
    return {};
  }
  const offerCount = getOfferCount(offers);
  const offerRepeats = {};

  if (offers.isBetterRateEligible && offers.offerBetterRateFromPreviousOfferSelected && !features.discountedOFee) {
    offerRepeats.Is_Better_Rate_Available = true;
    offerRepeats.Better_Rate_Term = offers.offerBetterRateFromPreviousOfferSelected.duration;
    offerRepeats.Better_Rate_APR = offers.offerBetterRateFromPreviousOfferSelected.apr;
    offerRepeats.Last_Loan_Issued_APR = offers.previousOfferSelected.apr;
    offerRepeats.Better_Rate_Difference =
      offers.previousOfferSelected.apr - offers.offerBetterRateFromPreviousOfferSelected.apr;
  } else {
    offerRepeats.Is_Better_Rate_Available = false;
  }
  return {
    OFFER_TYPE: getOfferType(loanApp, offers.offerTypes),
    APP_TYPE: loanApp.borrowers.length > 1 ? 'Joint' : 'Individual',
    LOAN_APP_FEATURE: getLoanAppFeature(loanApp), // "Joint App for Pending", "Joint Organic", "Joint App for Offers"
    LOAN_ID: objectPath.get(loanApp, 'loanId'),
    PROD_ID: loanApp.getProductId(),
    FUNNEL: getFunnelName(loanApp),
    REQUESTED_AMOUNT: objectPath.get(loanApp, 'desiredAmount'),
    ...offerCount,
    ...offerRepeats,
    PAYDOWN_OPTIONS: getBTPayDownOptionsCount(offers),
    MANDATORY_BT: !!offers.hasNoCashOffers,
  };
};

const getBTPayDownOptionsCount = offers => {
  let btHeroOffer = {};
  if (objectPath.get(offers, 'selectedOffer.dpType') === 'DIRECT_PAY_FOR_ALL') btHeroOffer = offers.selectedOffer;
  else if (objectPath.get(offers, 'secondHeroOffer.dpType') === 'DIRECT_PAY_FOR_ALL')
    btHeroOffer = offers.secondHeroOffer;
  const paydownOptions =
    (offers.allBTOffers &&
      offers.allBTOffers.filter(
        offer =>
          offer.amount === btHeroOffer.amount &&
          offer.duration === btHeroOffer.duration &&
          offer.dpType === btHeroOffer.dpType
      )) ||
    [];

  return paydownOptions.length;
};

const getSelectedOfferTrackingAttributes = (offers, selectedOfferId, defaultOffer) => {
  if (!offers) {
    return {};
  }

  let selectedOffer;

  if (typeof selectedOfferId === 'object') {
    // TODO deprecate the default way soon as part of refactor BT
    // assume we are passing the whole selectedOffer as first param, don't need to find
    selectedOffer = offers;
    defaultOffer = selectedOfferId;
  } else {
    selectedOffer = offers.find(offer => offer.id === selectedOfferId) || {};
  }

  let attributes = {
    SELECTED_OFFER_ID: selectedOfferId,
    OFFER_TAKEN_TYPE: selectedOffer.dpType,
    OFFER_TAKEN_AMOUNT: selectedOffer.amount,
    OFFER_TAKEN_APR: selectedOffer.apr,
    OFFER_TAKEN_TERM: selectedOffer.duration,
    OFFER_TAKEN_GRADE: selectedOffer.grade,
    OFFER_TAKEN_COMPARED_TO_DEFAULT: compareOffer(selectedOffer, defaultOffer, 'amount'),
  };

  if (selectedOffer.dpType !== 'CASH') {
    attributes.OFFER_TAKEN_PAYDOWN_AMOUNT = selectedOffer.minDirectPayAmount;
    attributes.OFFER_TAKEN_PAYDOWN_COMPARED_TO_DEFAULT = compareOffer(
      selectedOffer,
      defaultOffer,
      'minDirectPayAmount'
    );
  }

  return attributes;
};

const getOfferType = (loanApp, offerTypes = {}) => {
  // BT offer types
  if (offerTypes.DIRECT_PAY_FOR_ALL) return 'BTA';

  // Non-BT means CASH only
  if (loanApp.isCountered) return 'Cash Countered';
  return 'Cash Full';
};

const getOfferCount = offers => {
  let cashOfferCount = 0;
  let balanceTransferOfferCount = 0;
  let cashOffers = offers.cashOffers || [];
  let balanceTransferOffers = offers.allBTOffers || [];

  if (offers.hasCashAndBTOffers) {
    // Can't use hasMixedOfferTypes as it doesn't account for BT-HR (dpType 'DIRECT_PAY_FOR_HIGHRISK')
    cashOfferCount = cashOffers.length;
    balanceTransferOfferCount = balanceTransferOffers.length;
  } else if (offers.isBTAEligible) {
    // BT offers only
    balanceTransferOfferCount = balanceTransferOffers.length;
  } else {
    // Cash only
    cashOfferCount = cashOffers.length;
  }

  return {
    CASH_OFFER_COUNT: cashOfferCount,
    BT_OFFER_COUNT: balanceTransferOfferCount,
  };
};

const getFunnelName = loanApp => {
  const FUNNELS = {
    FunnelC: 'Main',
    FunnelG: 'Direct Mail',
    FunnelD: 'Partner',
  };

  // TODO how is this working flakily at all? this is not mocked right. need to mock 1 layer deep and EVERYTHING
  // when it doesn't flake (like when i use testnode mocha, it doesn't even get into this code...)
  return FUNNELS[loanApp.getFunnelLabel && loanApp.getFunnelLabel()] || 'Other';
};

const getLoanAppFeature = loanApp => {
  if (hasLoanAppFeature('PLAID_HIGH_RISK', loanApp)) {
    // Feature: PLAID_V#, where # is the version number
    return 'Plaid High Risk';
  }
  if (hasLoanAppFeature('PLAID', loanApp)) {
    return 'Plaid for Decline';
  }
  if (hasLoanAppFeature('JOINT_APP_FOR_OFFERS', loanApp)) {
    return 'Joint App for Offers';
  }
  if (hasLoanAppFeature('JOINT_APP', loanApp)) {
    return 'Joint App for Pending';
  }
};

const hasLoanAppFeature = (feature, loanApp) => {
  const availableFeature = objectPath.get(loanApp, 'availableFeatures.0', '');
  const usedFeature = objectPath.get(loanApp, 'usedFeatures.0', '');

  return availableFeature.indexOf(feature) > -1 || usedFeature.indexOf(feature) > -1;
};

const trackHeapEvent = (eventName, eventValue, extraInfo) => {
  if (window.lcTracking) {
    var trackData = extraInfo || {};
    trackData.eventName = eventName;
    trackData.eventValue = eventValue;
    window.lcTracking.trackCustomEvent(trackData);
  }
};

const trackOfferGAEvent = (eventCategory, eventAction, selectedOffer) => {
  selectedOffer = selectedOffer || {};
  if (window.lcTracking) {
    var payload = {
      eventCategory,
      eventAction,
      eventValue: selectedOffer.amount,
      eventLabel: `${selectedOffer.duration}, ${selectedOffer.dpType}`,
    };
    window.lcTracking.trackCustomEvent(payload, { trackingEngine: 'google-analytics' });
  }
};

const getTrackingTooltip = (heapEvent, customAttributes = {}) => ({
  onVisibleChange: showTooltip => {
    trackHeapEvent(heapEvent, 'Clicked', {
      ACTION: showTooltip ? 'opened' : 'closed',
      ...customAttributes,
    });
  },
});

/**
 * Calls trackHeapEvent with the correct offer tracking attributes. Works for single offers or an array of offers.
 * For multiple offers case, offer specific attributes get joined together. see joinValuesIfMultiple below
 * @param {object} params
 * @param {object|array} params.chosenOfferOrOffers
 * @param {object} params.defaultOffer
 * @param {object=} params.betterRateOffer
 * @param {boolean=} params.isBetterRate
 * @param {string} params.trackingType which action triggered the track event. ex) amount filter change should pass 'amount'
 */
const trackOfferChosen = ({ chosenOfferOrOffers, defaultOffer, trackingType, betterRateOffer, isBetterRate }) => {
  // used for tracking unique values across multiple loans
  // ex. OFFER_ID "76598,76434" and OFFER_TERM "36,60"
  const joinValuesIfMultiple = prop =>
    Array.isArray(chosenOfferOrOffers)
      ? [...new Set(chosenOfferOrOffers.map(o => o[prop]))].join(',')
      : chosenOfferOrOffers[prop];

  const OFFER_TYPE = joinValuesIfMultiple('dpType');

  let attributes = {
    OFFER_ID: joinValuesIfMultiple('id'),
    OFFER_AMOUNT: joinValuesIfMultiple('amount'),
    OFFER_TERM: joinValuesIfMultiple('duration'),
    OFFER_TYPE: OFFER_TYPE,
    APR: joinValuesIfMultiple('apr'),
    DEFAULT_AMOUNT: defaultOffer.amount,
    COMPARED_TO_DEFAULT_AMOUNT: compareOffer(chosenOfferOrOffers[0] || chosenOfferOrOffers, defaultOffer, 'amount'),
    OFFER_CHOSEN_TERM_CHANGED: trackingType === 'term',
    OFFER_CHOSEN_AMOUNT_CHANGED: trackingType === 'amount',
    OFFER_CHOSEN_TYPE_CHANGED: trackingType === 'offerType',
    OFFER_CHOSEN_PAYDOWN_CHANGED: trackingType === 'paydown',
  };

  attributes.Is_Better_Rate_Available = isBetterRate ? !!betterRateOffer : false;

  if (!!OFFER_TYPE && OFFER_TYPE !== 'CASH') {
    const paydownAttributes = {
      PAYDOWN_AMOUNT: joinValuesIfMultiple('minDirectPayAmount'),
      OFFER_CHOSEN_PAYDOWN_COMPARED_TO_DEFAULT: compareOffer(
        chosenOfferOrOffers[0] || chosenOfferOrOffers,
        defaultOffer,
        'minDirectPayAmount'
      ),
    };

    attributes = { ...attributes, ...paydownAttributes };
  }
  trackHeapEvent('PL Offer Chosen', 'Change Offer', attributes);
};

const compareOffer = (offerToCompare, defaultOffer, attribute) => {
  if (!offerToCompare || !Object.keys(offerToCompare).length || !defaultOffer || !Object.keys(defaultOffer).length) {
    return;
  }
  let comparedToDefault = 'Equal';

  if (offerToCompare[attribute] > defaultOffer[attribute]) comparedToDefault = 'Greater';
  if (offerToCompare[attribute] < defaultOffer[attribute]) comparedToDefault = 'LessThan';

  return comparedToDefault;
};

// Invoked on server side
const getLockedPI1TrackingAttributes = loanApp => {
  const signedAgreements = loanApp && loanApp.getSignedAgreements();
  const isTilSigned =
    signedAgreements && signedAgreements.indexOf(loanApp.isILoc() ? 'CleanSweepTIL' : 'PersonalEstimatedTIL') >= 0;
  const lockedPrimaryBorrower = !!objectPath.get(loanApp, 'borrowers.0.locked');

  let pageType;
  if (isTilSigned) pageType = 'PI1 Fully Locked';
  else if (lockedPrimaryBorrower) pageType = 'PI1 Locked';
  else pageType = 'PI1 Unlocked';

  return {
    PAGE_TYPE: pageType,
  };
};

// these attributes are collected for the tracking event invoked on partner-entry page, React version
const getPartnerEntryTrackingAttributes = (loanApp, partnerLocker, context, query) => {
  const { actor, loanAppProperties } = context || {};
  if (!loanApp || !actor) {
    return {};
  }

  const from = objectPath.get(query, 'from');
  const utm_medium = objectPath.get(query, 'utm_medium');
  const utm_campaign = objectPath.get(query, 'utm_campaign');
  let linkType;
  // partner token param is NOT present in URL
  if (!partnerLocker || !partnerLocker.actorId) {
    linkType = 'no_token';
  } else {
    // reflect token type, not the overwrite that happens in OLL test
    linkType = objectPath.get(partnerLocker, 'offerId') ? 'offer_level_link' : 'loan_level_link';
  }

  let timeSinceToken = partnerLocker?.createDate
    ? differenceInSeconds(new Date(), new Date(partnerLocker.createDate))
    : '';

  // OfferTypes from borrower offers, if offerTypes is empty the borrower has no offers
  const offerTypes = loanApp.getOfferTypes();
  const hasOffers = offerTypes && Object.keys(offerTypes).length > 0;
  const declineType =
    (loanAppProperties?.isDeclined && 'HARD_DECLINE') ||
    (loanAppProperties?.isJointAppForDecline && 'SOFT_DECLINE') ||
    (loanAppProperties?.isPlaid && 'PLAID');

  return {
    APP_TYPE: loanApp.applicationRequestType === 'JOINT' ? 'Joint' : 'Individual',
    LOAN_APP_FEATURE: getLoanAppFeature(loanApp), // "Joint App for Pending", "Joint Organic", "Joint App for Offers"
    LOAN_ID: objectPath.get(loanApp, 'loanId'),
    OFFER_TYPE: hasOffers ? getOfferType(loanApp, offerTypes) : 'NO_OFFERS',
    ...(declineType ? { PARTNER_ENTRY_DECLINED: declineType } : {}),
    MEDIUM: utm_medium,
    CAMPAIGN: utm_campaign,
    PARTNER_LINK_TYPE: linkType,
    PRIMARY_AID: loanApp.getPrimaryAid(),
    PROD_ID: loanApp.getProductId(),
    REDIRECT_FROM: from,
    REFERRER_ID: loanApp.getReferrerId(),
    VERIFICATION_REQUIRED: !loanApp.getUserAccountStatus() && partnerLocker && partnerLocker.challenge,
    AGREEMENT_SIGNED: actor?.agreementsAccepted,
    LOGIN_REQUIRED: (!actor?.isLoggedIn || !actor?.agreementsAccepted) && actor?.isAuthenticated,
    TOKEN_CREATION_DATE: partnerLocker ? partnerLocker.createDate : '',
    TIME_SINCE_TOKEN_CREATION: timeSinceToken,
    API_EXPERIMENTS: partnerLocker && partnerLocker.experiments ? partnerLocker.experiments : '',
  };
};

const getPartnerOfferViewAttrs = (loanApp, selectedOffer = {}) => {
  if (!loanApp) {
    return {};
  }

  return {
    APP_TYPE: loanApp.borrowers.length > 1 ? 'Joint' : 'Individual',
    LOAN_APP_FEATURE: getLoanAppFeature(loanApp),
    LOAN_ID: objectPath.get(loanApp, 'loanId'),
    AID: loanApp.getActorId(),
    PRIMARY_AID: loanApp.getPrimaryAid(),
    REFERRER_ID: loanApp.getReferrerId(),
    PROD_ID: loanApp.getProductId(),
    REQUESTED_AMOUNT: objectPath.get(loanApp, 'desiredAmount'),
    OFFER_O_FEE: selectedOffer.originationFeeAmt,
    OFFER_INT_RATE: selectedOffer.interestRate,
    OFFER_TERM: selectedOffer.duration,
    OFFER_ID: selectedOffer.id,
    OFFER_APR: selectedOffer.apr,
    OFFER_TYPE: selectedOffer.dpType,
    OFFER_AMOUNT: selectedOffer.amount,
    OFFER_GRADE: selectedOffer.grade,
  };
};

const constants = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  OPENED: 'OPENED',
  CLOSED: 'CLOSED',
};

/**
 * utilTrackingLTE - Utility to get custom heap attributes for LTE events.
 */
const utilTrackingLTE = {
  /**
   * get heap tracking attributes from term selector tooltip (LoanTermCompare)
   * @param {Object} params
   * @param {Object} params.tooltips - tooltips display / hide.
   * @param {Object} params.term - current term.
   * @param {number} params.loanAmount - loan amount which user has selected.
   * @returns {Object}
   */
  getTooltipAttrs: ({ tooltips, term, loanAmount }) => ({
    EVENT: 'Term_Selector_Tooltip',
    LOAN_AMOUNT: loanAmount,
    TOOLTIP_STATUS: !tooltips[term.duration] ? constants.OPENED : constants.CLOSED,
    TERM: term.duration,
  }),
  /**
   * get heap tracking attributes for click event on LoanTermCompare component
   * @param {Object} params
   * @param {Object} params.term - current term.
   * @param {Object} params.loanAmount - loan amount which user has selected.
   * @returns {Object}
   */
  getTermSelectionAttrs: ({ term, loanAmount }) => ({
    EVENT: 'Term_Selector_Click',
    LOAN_AMOUNT: loanAmount,
    SELECTOR_CLICKED: term.duration,
  }),
  /**
   * heap get custom submitted offer attributes
   * @param {Object} params
   * @param {Object} params.initialTermsInfo - current term information from getOffersByTerm method on page load.
   * @param {number} params.offerDuration - offer duration which user has selected.
   * @returns {Object}
   */
  getSubmitedOfferAttrs: ({ initialTermsInfo, offerDuration }) => {
    const { missingDurations, bestTerm, unavailableTerms } = initialTermsInfo;

    return {
      EVENT: 'Offer_Card_CTA_Click',
      SELECTED_TERM_WAS_MISSING_ONLOAD:
        missingDurations && missingDurations.length > 0 && missingDurations.includes(offerDuration),
      SELECTED_TERM_WAS_MISSING_LEFT_COL: bestTerm && !bestTerm.offer && offerDuration === bestTerm.duration,
      SELECTED_TERM_WAS_MISSING_RIGHT_COL:
        unavailableTerms && unavailableTerms.length > 0 && unavailableTerms.includes(offerDuration),
      SELECTED_TERM: offerDuration,
    };
  },
  /**
   * heap get custom submitted offer attributes when we submit an offer
   * @param {Object} params
   * @param {Object} params.selectedOffer - current selectedOffer.
   * @param {Array} params.initialOffers - initialOffers set filtered by current loan amount.
   * @returns {Object}
   */
  getSubmittedDefaultOfferAttrs: ({ initialOffers, selectedOffer }) => {
    return {
      EVENT: 'Offer_Card_CTA_Click',
      SELECTED_TERM_WAS_MISSING_ONLOAD: !initialOffers.map(offer => offer.duration).includes(selectedOffer.duration),
      SELECTED_TERM: selectedOffer.duration,
    };
  },
  /**
   * get common heap attributes for lte events
   * @param {Object} params
   * @param {Object} params.termsInfo - current term information from getOffersByTerm method
   * @param {number} params.loanAmount - loan amount which user has selected.
   * @param {Object} params.bestTerm - current best term object.
   * @param {Array} params.durations - available loan term durations.
   * @returns {Object}
   */
  getCommonAttrs: ({ termsInfo, loanAmount, bestTerm, durations }) => ({
    LOAN_AMOUNT: loanAmount,
    MISSING_TERMS: termsInfo.missingDurations.join(';'),
    MISSING_TERMS_COUNT: termsInfo.missingDurationsCount,
    AVAILABLE_TERMS: termsInfo.availableDurations.join(';'),
    AVAILABLE_TERMS_COUNT: termsInfo.availableDurationsCount,
    LEFT_COLUMN_STATUS: !bestTerm.offer ? constants.UNAVAILABLE : constants.AVAILABLE,
    RIGHT_COLUMN_STATUS: termsInfo.unavailableTerms.length === 2 ? constants.UNAVAILABLE : constants.AVAILABLE,
    TOTAL_TERMS_COUNT: durations.length,
  }),
  /**
   * heap - get default offer attributes when we render new filtered offers
   * @param {Object} params
   * @param {Array} params.filteredOffers - current filtered offers available.
   * @param {number} params.loanAmount - current loan amount.
   * @param {Array} params.durations - all available durations from offers array.
   * @returns {Object}
   */
  getDefaultAttrs: ({ filteredOffers, loanAmount, durations }) => {
    const currentOfferDurations = filteredOffers.map(offer => offer.duration);
    const missingOfferDurations = durations.reduce((acc, duration) => {
      if (!currentOfferDurations.includes(duration)) acc.push(duration);
      return acc;
    }, []);
    return {
      LOAN_AMOUNT: loanAmount,
      AVAILABLE_TERMS: currentOfferDurations.join(';'),
      AVAILABLE_TERMS_COUNT: currentOfferDurations.length,
      MISSING_TERMS: missingOfferDurations.join(';'),
      MISSING_TERMS_COUNT: missingOfferDurations.length,
      TOTAL_TERMS_COUNT: durations.length,
    };
  },
  /**
   * heap - get extra onload properties.
   * @param {Object} params.bestTerm - current best term object.
   * @param {Object} params.compareTerm - current compare term object.
   * @returns {Object}
   */
  getExtraOnloadAttrs: ({ bestTerm, compareTerm }) => ({
    LEFT_COLUMN_TERM: bestTerm.offer?.duration,
    RIGHT_COLUMN_TERM: compareTerm?.duration,
  }),
  /**
   * heap - get attributes to track loan amount filter change
   * @param {Object} params
   * @param {Number} params.newAmount - new amount filter
   * @param {String} params.actionType - filter amount action
   * @param {String} params.leadSource - leadSource
   * @returns {Object}
   */
  getLoanAmountChangeAttrs: ({ newAmount, actionType, leadSource }) => ({
    EVENT: 'Loan_Amount_Change',
    NEW_LOAN_AMOUNT: newAmount,
    CHANGE_TYPE: actionType,
    LEAD_SOURCE: leadSource,
  }),
  /**
   * heap - get attributes when we click on the terms card
   * @param {Object} selectedOffer - current selected offer
   * @returns {Object}
   */
  getTermCardClickAttrs: selectedOffer => ({
    EVENT: 'Loan_Term_Card_Click',
    LOAN_TERM_SELECTED: selectedOffer.duration,
    LOAN_AMOUNT: selectedOffer.amount,
    OFFER_ORIGINATION_FEE: selectedOffer.originationFeeAmt,
    OFFER_TYPE: selectedOffer.dpType,
  }),
  /**
   * @description heap retrieve extra submit properties: (getDefaultAttrs + SelectedTermWasMissingOnLoad + Origination Fee)
   * @param {Object} params
   * @param {Array} params.offers - current offers set
   * @param {Number} params.filterAmount - current filter amount
   * @param {Object} params.submittedOffer - current offer submitted from pbs
   * @param {Object} params.initialOffer - initial offer
   * @param {Array} params.durations - available loan term durations
   *
   * @returns {Object}
   */
  getExtraSubmitAttributes: ({ offers, filterAmount, submittedOffer, initialOffer, durations }) => {
    const filteredOffers = offers.filter(offer => getLoanAmount(offer) === filterAmount);
    const { SELECTED_TERM_WAS_MISSING_ONLOAD } = utilTrackingLTE.getSubmittedDefaultOfferAttrs({
      initialOffers: offers.filter(offer => getLoanAmount(offer) === initialOffer.amount),
      selectedOffer: submittedOffer,
    });

    const extraProps = {
      ...utilTrackingLTE.getDefaultAttrs({
        filteredOffers,
        loanAmount: filterAmount,
        durations,
      }),
      OFFER_ORIGINATION_FEE: submittedOffer.originationFeeAmt,
      OFFER_INTEREST_RATE: submittedOffer.interestRate,
      SELECTED_TERM_WAS_MISSING_ONLOAD,
    };
    return extraProps;
  },
  /**
   * @description heap tracking attributes for onload event
   * @param {Object} params
   * @param {Object=} params.selectedOffer current selected offer
   * @param {Object} params.heapAttrs heap tracking attributes
   * @param {Object=} params.extraProps additional custom attributes
   * @returns {Object}
   */
  getOfferTermsOnLoad: ({ selectedOffer, heapAttrs, extraProps = {} }) => {
    return {
      EVENT: 'Offer_Terms_On_Load',
      OFFER_ORIGINATION_FEE: selectedOffer ? selectedOffer.originationFeeAmt : 'NOT_AVAILABLE',
      OFFER_TYPE: heapAttrs.OFFER_TYPE,
      APP_TYPE: heapAttrs.APP_TYPE,
      ...heapAttrs,
      ...(extraProps ? extraProps : {}),
    };
  },
};

const getPL2BTEntryTrackingAttributes = (loanApp, pssContext, actor, query) => {
  if ((!pssContext && !loanApp) || !actor) {
    return {};
  }

  const from = objectPath.get(query, 'from');
  const utm_medium = objectPath.get(query, 'utm_medium');
  const utm_campaign = objectPath.get(query, 'utm_campaign');
  const appType = pssContext?.applicationRequestType || loanApp?.applicationRequestType;
  const primaryActorId = pssContext?.primaryActorId || loanApp.getPrimaryAid();
  const appSource = pssContext?.type || loanApp?.getLeadType();

  return {
    APP_TYPE: appType,
    SOURCE: appSource,
    LOAN_ID: loanApp ? objectPath.get(loanApp, 'loanId') : 'na',
    MEDIUM: utm_medium,
    CAMPAIGN: utm_campaign,
    PRIMARY_AID: primaryActorId,
    PROD_ID: loanApp ? loanApp.getProductId() : 'na',
    REDIRECT_FROM: from,
    AGREEMENT_SIGNED: loanApp ? actor.agreementsAccepted : false,
    LOGIN_REQUIRED: (!actor.isLoggedIn || !actor.agreementsAccepted) && actor.isAuthenticated,
    API_EXPERIMENTS: 'na',
  };
};

const getCleanSweepTrackingAttributes = (loanApp, pssContext, actor, query) => {
  if ((!pssContext && !loanApp) || !actor) {
    return {};
  }
  const pssAppType = pssContext ? (pssContext?.targets?.length > 1 ? 'JOINT' : 'INDIVIDUAL') : '';
  const from = objectPath.get(query, 'from');
  const utm_medium = objectPath.get(query, 'utm_medium');
  const utm_campaign = objectPath.get(query, 'utm_campaign');
  const appType = pssAppType || loanApp?.applicationRequestType;
  const primaryActorId = pssContext?.primaryActorId || loanApp?.getPrimaryAid();
  const appSource = pssContext?.type || loanApp?.getLeadType();
  const prodId = loanApp?.getProductId() || pssContext?.productId || 'na';
  const { refererId } = getTrackingDataParams(query);
  const token = objectPath.get(query, 'token');

  return {
    AGREEMENT_SIGNED: loanApp ? actor.agreementsAccepted : false,
    AID: primaryActorId,
    API_EXPERIMENTS: 'na',
    APP_TYPE: appType,
    CAMPAIGN: utm_campaign,
    LOAN_ID: loanApp ? objectPath.get(loanApp, 'loanId') : 'na',
    LOGIN_REQUIRED: (!actor.isLoggedIn || !actor.agreementsAccepted) && actor.isAuthenticated,
    MEDIUM: utm_medium,
    PROD_ID: prodId,
    REDIRECT_FROM: from,
    REFERRER_ID: refererId,
    SOURCE: appSource,
    TOKEN: token,
  };
};

const DEFAULT_TRACKING_PARAMS = {
  queryParams: ['referrerId', 'partnerID', 'lc_referrer', 'referralID'],
  cookieParams: ['param1', 'referrerId', 'reg_referrer'],
};

const getTrackingUrlParams = (targetParams = DEFAULT_TRACKING_PARAMS.queryParams, prefixKey = 'url_param_') => {
  let urlQueryParams = {};
  let filterParams = !!targetParams.length;

  const searchParamsArr = Array.from(new URL(location.href).searchParams);

  searchParamsArr.forEach(item => {
    let qsName = item[0];
    let qsValue = item[1];
    let keyName = `${prefixKey}${qsName}`;
    filterParams
      ? targetParams.includes(qsName) && (urlQueryParams[keyName] = qsValue)
      : (urlQueryParams[keyName] = qsValue);
  });

  urlQueryParams.url_referrer_tracking = !!Object.keys(urlQueryParams).length;

  return urlQueryParams;
};

const DiscountTracking = () => {
  const isCoupon = subtype => subtype === COUPON;
  const getAllPayloadMapKeys = (payloadMap, prefix) => {
    const { data, discPctName } = payloadMap;
    return (
      data && {
        PCT_BEFORE_DISC: data[`${prefix}pct_before_disc`],
        PCT_AFTER_DISC: data[`${prefix}pct_after_disc`],
        AMT_BEFORE_DISC: data[`${prefix}amt_before_disc`], //'the interest rate value before the discount is applied'
        AMT_AFTER_DISC: data[`${prefix}amt_after_disc`], // the interest rate value after the discount is applied'
        APR_PCT_BEFORE_DISC: data[`apr_pct_before_${prefix}disc`], // 'the APR value before the discount is applied'
        DISC_PCT: data[`${prefix}${discPctName}`], // This is shown for OFEE % shown on the UI
        DISC_LEVEL: data[`${prefix}disc_level`], // 'the coupon discount % (discount level) that corresponds to default offer',
        TOTAL_LOAN_DISC_AMT: data[`${prefix}total_loan_disc_est_amt`],
        DISC_AMT: data[`${prefix}disc_amt`],
      }
    );
  };

  const getCommonCouponAttrs = (offerKey, offer, keys) => {
    return {
      [`${offerKey}_COUPON_BEFORE_DISC`]: keys.PCT_BEFORE_DISC,
      [`${offerKey}_COUPON_AFTER_DISC`]: keys.PCT_AFTER_DISC,
      [`${offerKey}_COUPON_FINAL`]: offer.interestRate,
      [`${offerKey}_APR_BEFORE_DISC`]: keys.APR_PCT_BEFORE_DISC,
      [`${offerKey}_APR_FINAL`]: offer.apr,
    };
  };

  const getNewOfee = keys => Number(keys.AMT_BEFORE_DISC) - Number(keys.DISC_AMT || keys.TOTAL_LOAN_DISC_AMT);

  const getSubtypeOfferProps = (type, subtype, offerKey, offer, keys) => {
    // TODO: improve this code with different strategy
    if (type === DISCOUNT_TYPES.ONT) {
      if (isCoupon(subtype)) {
        return getCommonCouponAttrs(offerKey, offer, keys);
      }
      return {
        [`${offerKey}_OFEE_BEFORE_DISC`]: keys.AMT_BEFORE_DISC,
        [`${offerKey}_OFEE_AFTER_DISC`]: getNewOfee(keys),
      };
    }
    if (type === DISCOUNT_TYPES.PRE_APPROVED) {
      if (isCoupon(subtype)) {
        return getCommonCouponAttrs(offerKey, offer, keys);
      }
    }
  };

  const _getOfferDiscountTracking = (offerKey, offer = {}, discount = {}) => {
    const type = discount.type;
    const payloadMap = getOfferSetPayloadMapped(offer.payloadMapped);

    if (!payloadMap || !payloadMap.type) {
      return {};
    }

    const prefix = payloadMap.keyPrefix || '';
    const keys = getAllPayloadMapKeys(payloadMap, prefix);

    const attrs = {};

    return {
      ...attrs,
      ...getSubtypeOfferProps(type, discount.subtype, offerKey, offer, keys),
    };
  };

  const _buildPageTracking = ({ discount, progressNavBarVariant = '' }, cb) => {
    let heapProps = { DISC_AVAILABLE: false };
    if (!discount) return heapProps;

    const productType = discount.type;
    const isPAv1NonDiscount = discount.subtype === PA_V1_NON_DISCOUNT;

    heapProps = {
      DISC_AVAILABLE: isPAv1NonDiscount ? false : !!discount,
      PRODUCT_TYPE_DISC: productType,
      DISC_TYPE: isPAv1NonDiscount ? '' : discount.subtype,
      DISC_BANNER_SHOWN: [REPEATS.PROGRESS_NAV_BAR_TYPES.ONT, REPEATS.PROGRESS_NAV_BAR_TYPES.PRE_APPROVED].includes(
        progressNavBarVariant
      ),
      DISC_STATUS: discount.status,
      DISC_LEVEL: discount.data[`${discount.keyPrefix}${DISC_LEVEL}`],
      DISC_PCT: discount.data[`${discount.keyPrefix}${discount.discPctName}`],
      DISC_TYPE_VERSION: discount.version,
    };

    return cb(heapProps);
  };

  return {
    getOfferDiscountTracking: _getOfferDiscountTracking,
    buildPageTracking: _buildPageTracking,
  };
};

module.exports = {
  getOfferTrackingAttributes,
  getSelectedOfferTrackingAttributes,
  trackHeapEvent,
  trackOfferGAEvent,
  trackOfferChosen,
  getLockedPI1TrackingAttributes,
  getPartnerEntryTrackingAttributes,
  getPartnerOfferViewAttrs,
  utilTrackingLTE,
  getPL2BTEntryTrackingAttributes,
  getCleanSweepTrackingAttributes,
  getTrackingUrlParams,
  getTrackingTooltip,
  discountTracking: DiscountTracking(),
};

