import { OFFER_CARD_LABELS } from './index';

export default class MultipleOfferTermsTagStrategy {
  setTagToShortestTerm({ filteredOffers, tags }) {
    tags[filteredOffers[0].duration] =
      filteredOffers.length > 2 ? OFFER_CARD_LABELS.FASTEST_PAYOFF : OFFER_CARD_LABELS.FASTER_PAYOFF;
  }

  setTagToSmallestMonthlyPaymentTerm({ filteredOffers, tags, smallestMonthlyPaymentTerm }) {
    // If the same term is at the same time the "Faster/est Pay-Off" and the “Lower/est Payment“, we keep "Faster/est Pay-Off"
    if (filteredOffers[0].duration !== smallestMonthlyPaymentTerm.duration) {
      tags[smallestMonthlyPaymentTerm.duration] =
        filteredOffers.length > 2 ? OFFER_CARD_LABELS.LOWEST_PAYMENT : OFFER_CARD_LABELS.LOWER_PAYMENT;
    }
  }

  calculateOfferTags({ filteredOffers, termWithLowerApr, tags }) {
    if (termWithLowerApr) {
      tags[termWithLowerApr.duration] =
        filteredOffers.length > 2 ? OFFER_CARD_LABELS.BEST_RATE : OFFER_CARD_LABELS.BETTER_RATE;
    }
    return tags;
  }
}

