export const AmountPurposeFieldsTypes = {
  DEFAULT: 'DEFAULT',
  MONTHLY_PAYMENT_REQUIRED: 'MONTHLY_PAYMENT_REQUIRED',
  MONTHLY_PAYMENT_OPTIONAL: 'MONTHLY_PAYMENT_OPTIONAL',
};

export const monthlyPaymentExperimentVariants = {
  Control: '',
  A: AmountPurposeFieldsTypes.MONTHLY_PAYMENT_REQUIRED,
  B: AmountPurposeFieldsTypes.MONTHLY_PAYMENT_OPTIONAL,
};

