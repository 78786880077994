import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from '@shared_modules/local-propulsion/components';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';
import './index.scss';

const MemberReview = ({ classes, imgSrc, quoteText, memberName, moreReviewsLink }) => {
  const handleReadMoreReviews = e => {
    heapTrackEvent('PL DM - click read more reviews', 'cta');
    window.open(moreReviewsLink, '_blank');
  };
  const moreReviewsButton = moreReviewsLink ? (
    <div className="MemberReview-btn">
      <Button name="MemberReview-btn" fullWidth rel="noopener noreferrer" onClick={handleReadMoreReviews}>
        Read More Reviews
      </Button>
    </div>
  ) : null;

  return (
    <div className={classnames('MemberReview', classes)}>
      <div className="MemberReview-imgWrapper">
        <img className="MemberReview-img" src={imgSrc} alt="" aria-hidden />
      </div>
      <div className="MemberReview-quoteSection">
        <div className="MemberReview-quote u-title1Text u-colorDenim500">{quoteText}</div>
        <div className="MemberReview-memberName">{memberName}</div>
        {moreReviewsButton}
      </div>
    </div>
  );
};

MemberReview.propTypes = {
  classes: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  quoteText: PropTypes.string.isRequired,
  memberName: PropTypes.element.isRequired,
  moreReviewsLink: PropTypes.string,
};

export default MemberReview;

