import React, { useContext } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Checkbox } from '../.';
import { PageIdContext } from '../PageIdProvider';
import ErrorMessage from '../../../rc-inline-error-message';
import objectPath from 'object-path';
import { heapEventFn } from '@shared_modules/baui-heap-tracking';

const heapTrack = heapEventFn();

// if passing agreements, don't need to pass agreementLinks and vice versa. either derive from the latter or explicit from former
// only passing agreements for coborrower consent flow-thus primary state not considered anyway
const AgreementsCheckBox = ({
  agreements /*processed agreements from server*/,
  agreementLinks,
  checked,
  disabled,
  handleCheckboxChange,
  hidePreText,
  isJoint,
  isJointAppConsent,
  pageId = useContext(PageIdContext),
  showFullText,
  validationErrors,
  token,
  individualPreText,
  jointPreText,
  isInlineText,
  certifyText,
  tracking,
}) => {
  const agreementsHost = `/apply/personal/`;
  const showErrors = !!Object.keys(validationErrors).length;
  const tokenQs = token && encodeURIComponent(`?token=${token}`);

  if (!agreements.length) {
    agreements = [
      // default agreements
      {
        agreement: 'SharedElectronicConsent',
        text: 'ESIGN Act Consent',
      },
      {
        agreement: 'SharedTermsOfUse',
        text: 'Terms Of Use',
      },
      {
        agreement: 'ApplicationTermsOfUse',
        text: 'Application Terms and Conditions',
      },
      {
        agreement: 'PrivacyPolicy',
        text: 'Privacy Policy',
      },
      {
        agreement: 'PersonalCreditProfileAuth',
        text: 'Credit Profile Authorization',
      },
    ];
  }

  return (
    <div className="agreements-checkbox">
      {!hidePreText && (
        <div className="agreements-checkbox__text">
          {isJoint
            ? jointPreText ||
              'By checking this box, each of you represent and acknowledge that you have clicked on, read and agree to:'
            : individualPreText ||
              'By checking this box, I represent and acknowledge that I have clicked on, read and agree to:'}
        </div>
      )}
      <Checkbox
        labelClass="AgreementsCheckbox-Label"
        className="AgreementsCheckbox-Checkbox"
        name="agreements"
        checked={checked}
        onChange={handleCheckboxChange}
        disabled={disabled}
        error={showErrors && Boolean(validationErrors.agreements)}
      >
        {isInlineText
          ? 'By checking this box, I represent and acknowledge that I have clicked on, read and agree to the'
          : showFullText
          ? `${isJoint ? 'We' : 'I'} have read and ${isJointAppConsent ? 'individually ' : ''}agree to the`
          : 'The'}
        {agreements.map((agreement, index) => (
          <span
            className={classNames('agreements-checkbox__agreement', {
              'agreements-checkbox__agreement--last': index === agreements.length - 1,
            })}
            key={agreement.text}
          >
            <a
              className="agreements-checkbox__link"
              onClick={e => {
                if (tracking)
                  heapTrack({ EVENT: `[PL Funnel][${tracking}] - Click Agreement Link`, KEY: agreement.agreement });
              }}
              href={`${agreementsHost}popup?url=${
                agreement.link /*from processed agreements*/ ||
                objectPath.get(agreementLinks, `${objectPath.get(agreement, 'agreement')}.link`)
              }${tokenQs}`}
              target="_blank"
              rel="noopener noreferrer"
              data-qa-id={`${pageId}-${agreement.agreement || agreement.type}Link`}
              data-nid-target={`${pageId}-${agreement.agreement || agreement.type}Link`}
            >
              {agreement.text}
            </a>
          </span>
        ))}
        {certifyText
          ? ' I also certify that my personal information listed above is true, accurate, and complete.'
          : ''}
      </Checkbox>
      {showErrors && (
        <ErrorMessage
          validationErrors={validationErrors.agreements}
          dataQaIdPrefix="agreements"
          className="agreements-checkbox__error"
        />
      )}
    </div>
  );
};

AgreementsCheckBox.propTypes = {
  checked: bool.isRequired,
  disabled: bool,
  handleCheckboxChange: func.isRequired,
  isJoint: bool,
  validationErrors: shape({}),
  hidePreText: bool,
  showFullText: bool,
  isJointAppConsent: bool,
  token: string,
  pageId: string,
  agreementLinks: shape({}),
  agreements: array,
  individualPreText: string,
  jointPreText: string,
  isInlineText: bool,
  certifyText: bool,
  tracking: string,
};

AgreementsCheckBox.defaultProps = {
  disabled: false,
  validationErrors: {},
  hidePreText: false,
  showFullText: false,
  isJoint: false,
  isJointAppConsent: false,
  token: '',
  pageId: undefined,
  agreements: [],
  agreementLinks: {},
  individualPreText: undefined, // passed by coborrower-consent Identity page (isJoint not passed for that flow)
  jointPreText: undefined, // joint-app-form from ja4d
  isInlineText: false,
  certifyText: false,
};

export default AgreementsCheckBox;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/local-propulsion/components/AgreementsCheckBox/AgreementsCheckBox.scss');