export const SENTILINK_AGREEMENTS = {
  name: 'sentilinkAgreement',
  title: 'SSN Verification Authorization',
  bannerText: {
    title: 'Authorization for the Social Security Administration to Disclose Your Social Security Number Verification',
    contentText:
      'I authorize the Social Security Admnistration (SSA) to verify and disclose to LendingClub Bank through Sentilink Verification Serices Corp., their service provider, for the purpose of this personal loan application whether the name, Social Security Number (SSN) and date of birth I have submitted matches information in SSA records. My consent is for a one-time validation within the next 30 days.',
  },
  checkboxText:
    'By clicking this checkbox, you are providing legal authorization for SSA to disclose your SSN Verification to LendingClub Bank and Sentilink Verification Services Corp. This authorization has the same effectiveness as your handwritten signature.',
  validationErrorText: 'The SSN Verification Authorization is required',
};

