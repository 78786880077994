import { AmountPurposeFieldsTypes } from '../constants';

const validator = require('ui-validator');
const UiMoney = require('ui-money');

const isUiMoney = validator.createValidator((opions, value) => {
  if (!value) {
    return undefined;
  }

  const parsed = new UiMoney(value);

  if (isNaN(parsed.getValue())) {
    return 'Please enter a valid dollar amount';
  }

  return undefined;
});

const minValue = validator.createValidator((options, value, key, attributes) => {
  if (!isNaN(value)) {
    value = value.toString();
  }

  if (!value) return undefined;

  const parsed = new UiMoney(value);

  if (!isNaN(parsed.getValue()) && parsed.getValue() < 1) {
    return 'A valid monthly payment is required';
  }

  return undefined;
});

const maxThanLoanAmountValue = validator.createValidator((options, value, key, attributes) => {
  if (!value) return undefined;

  const parsed = new UiMoney(value);
  const parsedLoanAmount = new UiMoney(attributes.loanAmount);

  if (!isNaN(parsed.getValue()) && parsed.getValue() > parsedLoanAmount.getValue()) {
    return 'Monthly payment cannot exceed loan amount';
  }

  return undefined;
});

const v = validator.validators;
const allValidations = function validations(minAmount, maxAmount, { variant } = {}) {
  const monthlyPayment = {
    isUiMoney: isUiMoney(),
    min: minValue(),
    maxThanLoanAmountValue: maxThanLoanAmountValue(),
  };

  if (variant === AmountPurposeFieldsTypes.MONTHLY_PAYMENT_REQUIRED) {
    monthlyPayment.required = v.required({ message: 'A monthly payment is required' });
  }

  return {
    loanAmount: {
      required: v.required({ message: 'The loan amount is required' }),
      dollarAmountRange: v.dollarAmountRange({
        min: minAmount,
        max: maxAmount,
        message: `Please enter an amount between ${new UiMoney(minAmount).format(0)} and ${new UiMoney(
          maxAmount
        ).format(0)}`,
      }),
      isUiMoney: isUiMoney(),
    },
    loanPurpose: {
      required: v.required({ message: 'The loan purpose is required' }),
    },
    ...([AmountPurposeFieldsTypes.MONTHLY_PAYMENT_REQUIRED, AmountPurposeFieldsTypes.MONTHLY_PAYMENT_OPTIONAL].includes(
      variant
    ) && { monthlyPayment }),
  };
};

const validations = (minAmount, maxAmount, fieldName, { variant } = {}) => {
  if (fieldName) {
    return {
      [fieldName]: allValidations(minAmount, maxAmount, { variant })[fieldName],
    };
  }
  return allValidations(minAmount, maxAmount, { variant });
};

export default validations;

