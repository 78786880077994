import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import watchIcon from '../../images/watch-icon.svg';
import offerIcon from '../../images/offer-icon.svg';
import cashIcon from '../../images/cash-icon.svg';
import Section from '../Section';
import EasyLoanCalloutItem from '../EasyLoanCalloutItem';
import './index.scss';

const steps = [
  {
    icon: watchIcon,
    iconAltText: 'Watch Icon',
    title: 'Apply in minutes',
    text: 'Use your custom, pre-filled application to check your rate.',
  },
  {
    icon: offerIcon,
    iconAltText: 'Offer Icon',
    title: 'Choose a loan option',
    text: 'If approved, select the rate, term, and payment that fit your needs.',
  },
  {
    icon: cashIcon,
    iconAltText: 'Cash Icon',
    title: 'Get your money fast',
    text: (
      <span>
        Borrow up to $40,000 in as little as a few days.
        <sup className="EasyLoanCallout-superscriptText">2</sup>
      </span>
    ),
  },
];

const EasyLoanCallout = ({ classNames, contentClassNames }) => (
  <Section classes={classnames('EasyLoanCallout', classNames)}>
    <div className={classnames('EasyLoanCallout-content u-bgColorWhite u-textAlignCenter', contentClassNames)}>
      <div className="EasyLoanCallout-title u-title1Text u-colorDenim500">Getting a Loan Is Easy</div>
      {steps.map((step, index) => (
        <EasyLoanCalloutItem
          key={index}
          icon={step.icon}
          iconAltText={step.iconAltText}
          stepTitle={index + 1 + '. ' + step.title}
          stepText={step.text}
        />
      ))}
    </div>
  </Section>
);

EasyLoanCallout.propTypes = {
  classNames: PropTypes.string,
  contentClassNames: PropTypes.string,
};

export default EasyLoanCallout;

