import { useEffect, useRef } from 'react';

/**
 * useBeforeUnload Hook
 *
 * Triggers a confirmation prompt when the user tries to leave the page. See MDN for details on beforeunload event.
 *  - https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
 *  - https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
 *
 * @example
 * showUnloadPrompt = true;
 * useBeforeUnload(showUnloadPrompt);
 *
 * @param {boolean} showUnloadPrompt whether or not to show the prompt when page is unloaded
 */
const useBeforeUnload = showUnloadPrompt => {
  if (!showUnloadPrompt) return;

  const eventListenerRef = useRef();

  useEffect(() => {
    eventListenerRef.current = event => {
      event.preventDefault();
      if (event.defaultPrevented) event.returnValue = '';
    };

    const eventListener = e => {
      eventListenerRef.current(e);
    };

    window.addEventListener('beforeunload', eventListener);
    return () => {
      window.removeEventListener('beforeunload', eventListener); // cleanup fn for removing the event handler
    };
  }, []);
};

export default useBeforeUnload;

