import React from 'react';
import usePlusTargetArrow from './hooks/usePlusTargetArrow';

/**
 * This tooltip has targetRef feature.
 * @param {JSX.Element} TooltipComponent tooltip component.
 * @returns {JSX.Element} React element
 * 
 * @example
 * return (
 *   <TooltipComponent 
*       showTooltip
*       targetRef={btnRef}
*       tabIndex={0}
*       placement="bottom"
*       visible
*       overlay={component} 
    />
 * );
 */
const WithTargetRef =
  TooltipComponent =>
  ({ targetRef, showTooltip, children, alignArrowOnVisible = false, ...props }) => {
    const { updateArrowPosition } = usePlusTargetArrow({
      target: targetRef,
      tooltipComponent: showTooltip,
      tooltipOverlayClass: props.overlayClassName,
    });
    if (alignArrowOnVisible) {
      props.alignArrowOnVisible = true;
      props.calculateArrowPositionFn = updateArrowPosition;
    }

    return <TooltipComponent {...props}>{children}</TooltipComponent>;
  };

export default WithTargetRef;

require('/var/lib/jenkins/workspace/borrower-apply-ui_Release_0/src/shared_modules/common-tooltips/CommonTooltips.scss');