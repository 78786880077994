import { OFFER_CARD_LABELS } from './index';

export default class OfferTermsTagStrategy {
  setTagToShortestTerm({ filteredOffers, tags }) {
    tags[filteredOffers[0].duration] = OFFER_CARD_LABELS.FASTEST_PAYOFF;
  }

  setTagToSmallestMonthlyPaymentTerm({ filteredOffers, tags, smallestMonthlyPaymentTerm }) {
    // If the same term is at the same time the "Fastest Pay-Off" and the “Lowest Payment“, we keep "Fastest Pay-Off"
    if (filteredOffers[0].duration !== smallestMonthlyPaymentTerm.duration) {
      tags[smallestMonthlyPaymentTerm.duration] = OFFER_CARD_LABELS.LOWEST_PAYMENT;
    }
  }

  calculateOfferTags({ termWithLowerApr, tags }) {
    if (termWithLowerApr) {
      tags[termWithLowerApr.duration] = OFFER_CARD_LABELS.BEST_RATE;
    }
    return tags;
  }
}

